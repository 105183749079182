import React, {Dispatch, FC, SetStateAction, useEffect, useRef, useState,} from "react";
import {
    ButtonBox,
    ErrorValidationBox,
    ModelDialogBox,
    PaymentTypeDetails,
    TextBox,
    VoucherDetails
} from "../";
import {
    ActionTypeEnum,
    PaymentConfigModel,
    PaymentTypeEnum,
    PaymentTypeItemsModel,
    PaymentValueDetailsModel,
    RequestActionModel,
    ValidationErrorModel,
} from "../../models";
import {getLabelName, updateStateDynamically} from "../../utils";
import {getPaymentConfiguration} from "../../serviceBroker/paymentTypeApiServiceBroker";

export const PaymentDetails: FC<{
    totalPayment: number;
    onActionEvent: (o: RequestActionModel) => void;
    setShowPaymentDetailsModel: Dispatch<SetStateAction<boolean>>;
}> = ({
          totalPayment,
          setShowPaymentDetailsModel,
          onActionEvent = () => {
          },
      }) => {
    //#region variables
    const initialValues: PaymentValueDetailsModel = {
        cash: 0,
        credit: 0,
        delay: 0,
        voucher: 0,
        totalAmount: 0,
        paymentTypeId: 0,
        remaining: totalPayment,
    };
    const defaultBtnValues: number[] = [3, 2, 1, 6, 5, 4, 9, 8, 7, 0];
    const extraBtnValues: number[] = [20, 10, 5, 500, 100, 50];
    const errorsMsg: ValidationErrorModel[] = [
        {
            MessageAr: getLabelName(
                "Sum of cash, credit and delay must be equal to total payment"
            ),
            MessageEn: getLabelName(
                "Sum of cash, credit and delay must be equal to total payment"
            ),
        },
    ];
    //#endregion
    //#region state
    const [showPaymentTypeDetailsModel, setShowPaymentTypeDetailsModel] =
        useState<boolean>(false);
    const firstInputRef = useRef<HTMLInputElement>(null);
    const [paymentValues, setPaymentValues] =
        useState<PaymentValueDetailsModel>(initialValues);
    const [selectedInput, setSelectedInput] = useState({
        name: "cash",
        value: 0,
    });
    const [errorMsg, setErrorMsg] = useState<ValidationErrorModel[]>([]);
    const [paymentTypeItems, setPaymentTypeItems] = useState<PaymentTypeItemsModel[]>(
        []
    );
    const [paymentConfig, setPaymentConfig] = useState<PaymentConfigModel>({
        IsMultipleCash: false,
        IsMultipleDelay: false,
        IsMultipleCard: false,
        IsMultipleOther: false,
        ParentPaymentCashId: 0,
        ParentPaymentCardId: 0,
        ParentPaymentDelayId: 0,
        ParentMultipleId: 0,
        CashPaymentTypeList: null,
        CardPaymentTypeList: null,
        DelayPaymentTypeList: null,
    });
    const [clickedPaymentType, setClickedPaymentType] = useState<string>("");
    //#endregion
    //#region UseEffect
    useEffect(() => {
        if (firstInputRef.current) {
            firstInputRef.current.focus();
        }
        const fillData = async () => {
            await getPaymentConfigurationResult();
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region functions
    //#region textBox Events
    const handleChangeInput = async (e: React.ChangeEvent<any>) => {
        if (e.target.value) {
            e.target.value = e.target.value.replace(/^0/, "");
            setErrorMsg([]);
        }
        const obj: PaymentValueDetailsModel = {
            ...paymentValues,
            [e.target.name]: Number(e.target.value),
        };
        const totalCalculatedValue: number = calculatePaymentTotalValues(obj);
        const inputValueCheck = calculatePaymentTotalValues(obj) <= totalPayment
        await updateStateDynamically(
            setPaymentValues,
            paymentValues,
            null,
            null,
            [
                {
                    key: e.target.name,
                    value: (inputValueCheck || e.target.name === "cash") ? Number(e.target.value) : e.target.name === "cash" ? paymentValues.cash : e.target.name === "credit" ? paymentValues.credit : e.target.name === "delay" ? paymentValues.delay : ""
                },
                {
                    key: "remaining",
                    value: calculateRemainingValue(totalPayment, totalCalculatedValue, e.target.name)
                },
            ]
        );
    };
    const handleBlurInput = async (e: React.ChangeEvent<any>) => {
        setSelectedInput((prev) => ({
            ...prev,
            name: e.target.name,
            value: Number(e.target.value),
        }));
    };
    //#endregion
    //#region button Events
    const handleClickBtn = async (e: React.ChangeEvent<any>) => {
        switch (e.target.name) {
            case "clear":
                const clearedObject: PaymentValueDetailsModel = {
                    ...paymentValues,
                    [selectedInput.name]: 0,
                };
                const totalCalculatedClearedValues: number =
                    calculatePaymentTotalValues(clearedObject);
                await updateStateDynamically(
                    setPaymentValues,
                    paymentValues,
                    null,
                    null,
                    [
                        {key: selectedInput.name, value: 0},
                        {
                            key: "remaining",
                            value: totalPayment - totalCalculatedClearedValues,
                        },
                    ]
                );
                break;
            case "float":
                setPaymentValues((prevState) => ({
                    ...prevState,
                    [selectedInput.name]: Number(selectedInput.value) * 0.1,
                }));
                break;
            default:
                const isDefaultButton: boolean =
                    e.currentTarget.getAttribute("datatype") === "default";
                const currentValue: number = Number(e.target.value);
                let propertyValue: number = 0;
                if (selectedInput.name !== "") {
                    const propertyName: keyof PaymentValueDetailsModel =
                        selectedInput.name as keyof PaymentValueDetailsModel;
                    propertyValue = isDefaultButton
                        ? parseInt(`${paymentValues[propertyName]}${currentValue}`, 10) //paymentValues[propertyName] + currentValue
                        : currentValue; // Number(paymentValues[propertyName]+currentValue);
                } else {
                    propertyValue = currentValue;
                }
                const updatedObject: PaymentValueDetailsModel = {
                    ...paymentValues,
                    [selectedInput.name]: propertyValue,
                };
                const totalCalculatedValue: number =
                    calculatePaymentTotalValues(updatedObject);
                const btnValueCheck = totalCalculatedValue <= totalPayment
                await updateStateDynamically(
                    setPaymentValues,
                    paymentValues,
                    null,
                    null,
                    [
                        {
                            key: selectedInput.name,
                            value: (btnValueCheck || selectedInput.name === "cash") ? propertyValue : selectedInput.name === "cash" ? paymentValues.cash : selectedInput.name === "credit" ? paymentValues.credit : selectedInput.name === "delay" ? paymentValues.delay : ""
                        },
                        {
                            key: "remaining",
                            value: calculateRemainingValue(totalPayment, totalCalculatedValue, selectedInput.name)
                        },
                    ]
                );
                break;
        }
    };
    const handleClickPaymentItemBtn = (
        e: React.MouseEvent<HTMLButtonElement>
    ) => {
        const {name} = e.target as HTMLButtonElement;

        setClickedPaymentType(name);
        const dataType = e.currentTarget.getAttribute("datatype") || "-";

        // @ts-ignore
        const paymentTypeItemsArray: PaymentTypeItemsModel[] = paymentConfig[dataType] ? (paymentConfig[dataType] as PaymentTypeItemsModel[])
            : [];
        setPaymentTypeItems(
            paymentTypeItemsArray !== null && paymentTypeItemsArray.length !== 0
                ? paymentTypeItemsArray
                : []
        );
        setShowPaymentTypeDetailsModel(
            paymentTypeItemsArray !== null && paymentTypeItemsArray.length !== 0
        );
        setPaymentValues(calculateTextboxValueOnButtonClick(paymentValues, name));
    };
    //#endregion
    const calculatePaymentTotalValues = (
        request: PaymentValueDetailsModel
    ): number => {
        return request !== null && request !== undefined
            ? Number(request.cash) +
            Number(request.credit) +
            Number(request.delay) +
            Number(request.voucher)
            : 0;
    };
    const handleSave = async () => {
        const totalCalculatedValue: number =
            calculatePaymentTotalValues(paymentValues);
        if (
            totalCalculatedValue === Number(totalPayment) ||
            paymentValues.cash >= Number(totalPayment)
        ) {
            onActionEvent({
                request: paymentValues,
                action: ActionTypeEnum.AddPaymentValuesSuccess,
            });
        } else {
            setErrorMsg(errorsMsg);
        }
        await updatePaymentValuesV2(totalCalculatedValue);


    };
    const updatePaymentValuesV2 = async (totalCalculatedValue: number) => {
        const paymentTypePriorities: PaymentTypeEnum[] = [
            PaymentTypeEnum.Cash,
            PaymentTypeEnum.Credit,
            PaymentTypeEnum.Delay,
            // Add more payment types as needed
        ];

        let selectedPaymentType: PaymentTypeEnum = PaymentTypeEnum.None
        let countNonZeroProps = 0;
        for (const paymentType of paymentTypePriorities) {
            // @ts-ignore
            if (paymentValues[PaymentTypeEnum[paymentType].toLowerCase()] > 0) {
                countNonZeroProps++;
            }
        }
        if (countNonZeroProps === 1) {
            for (const paymentType of paymentTypePriorities) {
                // @ts-ignore
                if (paymentValues[PaymentTypeEnum[paymentType].toLowerCase()] > 0) {
                    selectedPaymentType = paymentType;
                    break;
                }
            }
        } else if (countNonZeroProps > 1) {
            // More than one payment type has a non-zero value
            selectedPaymentType = PaymentTypeEnum.Multiple;
        }
        if (selectedPaymentType === PaymentTypeEnum.None) {
            // Check if all payment values are zero
            const allZero = Object.values(paymentValues).every((value) => value === 0);
            if (allZero) {
                selectedPaymentType = PaymentTypeEnum.None;
            }
        }
        // @ts-ignore
        const updates = [
            {
                key: "paymentTypeId",
                value: paymentValues.paymentTypeId === 0 ? selectedPaymentType : paymentValues.paymentTypeId
            },
            {key: "totalAmount", value: totalCalculatedValue},
            {
                key: "cash",
                value: selectedPaymentType === PaymentTypeEnum.Cash ? totalPayment : Number(paymentValues["cash"])
            },
        ];
        await updateStateDynamically(setPaymentValues, paymentValues, null, null, updates);
    }
    const getPaymentConfigurationResult = async () => {
        const res = await getPaymentConfiguration();
        if (res.Result) {
            setPaymentConfig(res.Result);
        }
    };
    const calculateRemainingValue = (totalPayment: number, totalCalculatedValues: number, paymentType: string): number => {
        let value: number;
        value = paymentType === "cash" ? totalPayment - totalCalculatedValues < 0 ? 0 : totalPayment - totalCalculatedValues : totalCalculatedValues <= totalPayment ? totalPayment - totalCalculatedValues : paymentValues.remaining;
        return value;
    }
    function calculateTextboxValueOnButtonClick(paymentValues: PaymentValueDetailsModel, name: string): PaymentValueDetailsModel {
        // Create a copy to avoid mutating the original object
        const updatedValues = {...paymentValues};
        switch (name.toLowerCase()) {
            case "credit":
                updatedValues.credit = updatedValues.credit ? updatedValues.credit : paymentValues.remaining;
                break;
            case "cash":
                updatedValues.cash = updatedValues.cash ? updatedValues.cash : paymentValues.remaining;
                break;
            case "delay":
                updatedValues.delay = updatedValues.delay ? updatedValues.delay : paymentValues.remaining;
                break;
        }
        updatedValues.remaining =totalPayment-(updatedValues.credit + updatedValues.cash + updatedValues.delay);
        return updatedValues;
    }
    //#endregion
    //#region style
    const amountStyle = {
        borderRadius: "5px",
        border: "2px solid #ccc",
        padding: "10px 20px",
    };
    const amountVoucherStyle = {
        borderRadius: "5px",
        backgroundColor: "#f8f8f8",
        padding: "7px 20px",
        color: "#000",
        fontWeight: "bold",
    };
    //#endregion
    //#region html
    return (
        <>
            {errorMsg.length > 0 && <ErrorValidationBox errors={errorMsg}/>}
            <div className="row">
                <div className="col-lg-6 mb-sm-4 mb-lg-0">
                    <div className="card h-100 p-3">
                        <div className="btn-group pos-btn-grpup">
                            {defaultBtnValues.map((value, index) => (
                                <span key={`default_btn_${index}`}>
                                    <ButtonBox
                                        className="btn"
                                        variant="outline-primary"
                                        value={value}
                                        type="button"
                                        datatype="default"
                                        onClick={handleClickBtn}
                                    >
                                        {value}
                                    </ButtonBox>
                                </span>
                            ))}
                            {extraBtnValues.map((value, index) => (
                                <span key={`extra_btn_${index}`}>
                                    <ButtonBox
                                        className="btn"
                                        variant="outline-success"
                                        value={value}
                                        type="button"
                                        datatype="extra"
                                        onClick={handleClickBtn}
                                    >
                                        {value}
                                    </ButtonBox>
                                </span>
                            ))}
                            <span>
                                <ButtonBox
                                    className="btn"
                                    name="clear"
                                    size="sm"
                                    variant="outline-danger"
                                    onClick={handleClickBtn}
                                >
                                    C
                                </ButtonBox>
                            </span>
                        </div>  
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="row mb-3">
                        <div className="col-md-6 text-center">
                            <p className="text-center" style={amountStyle}>
                                {getLabelName("Total")} :{" "}
                                <span className="fw-bold">{totalPayment}</span>{" "}
                            </p>
                        </div>
                        <div className="col-md-6 ">
                            <p className="text-center" style={amountStyle}>
                                {getLabelName("Rest")} :{" "}
                                <span className="fw-bold">{paymentValues.remaining}</span>{" "}
                            </p>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <ButtonBox
                            iconType="cash-multiple"
                                variant="info"
                                name="cash"
                                style={{width: "100%"}}
                                onClick={handleClickPaymentItemBtn}
                                datatype="CashPaymentTypeList"
                                disabled={
                                    !paymentConfig.IsMultipleCash || paymentValues.remaining === 0
                                }
                            >
                                {getLabelName("Cash")}
                            </ButtonBox>
                        </div>
                        <div className="col-md-8">
                            <div style={{width: "100%"}}>
                                <TextBox
                                    type="number"
                                    labelName=""
                                    inputValue={paymentValues.cash}
                                    inputName="cash"
                                    onChange={handleChangeInput}
                                    onBlur={handleBlurInput}
                                    inputRef={firstInputRef}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <ButtonBox
                            iconType="credit-card"
                                variant="info"
                                name="credit"
                                style={{width: "100%"}}
                                onClick={handleClickPaymentItemBtn}
                                datatype="CardPaymentTypeList"
                                disabled={
                                    !paymentConfig.IsMultipleCard || paymentValues.remaining === 0
                                }
                            >
                                {getLabelName("Card")}
                            </ButtonBox>
                        </div>
                        <div className="col-md-8">
                            <TextBox
                                type="number"
                                labelName=""
                                inputValue={paymentValues.credit}
                                inputName="credit"
                                onChange={handleChangeInput}
                                onBlur={handleBlurInput}
                            />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-md-4">
                            <ButtonBox
                            iconType="dots-horizontal"
                                variant="info"
                                name="delay"
                                style={{width: "100%"}}
                                onClick={handleClickPaymentItemBtn}
                                datatype="DelayPaymentTypeList"
                                disabled={
                                    !paymentConfig.IsMultipleDelay ||
                                    paymentValues.remaining === 0
                                }
                            >
                                {getLabelName("Delay")}
                            </ButtonBox>
                        </div>
                        <div className="col-md-8">
                            <TextBox
                                type="number"
                                labelName=""
                                inputValue={paymentValues.delay}
                                inputName="delay"
                                onChange={handleChangeInput}
                                onBlur={handleBlurInput}
                            />
                        </div>
                    </div>
                    <div className="row align-items-center my-4">
                        {!paymentValues.voucher ? (
                            <VoucherDetails
                                totalPayment={totalPayment}
                                paymentValues={paymentValues}
                                setPaymentValues={setPaymentValues}
                                calculatePaymentTotalValues={calculatePaymentTotalValues}
                                setErrorMsg={setErrorMsg}
                            />
                        ) : (
                            <>
                                <div className="col-md-2 mb-sm-2 mb-md-0">
                                    <p className="m-0">{getLabelName("Voucher")}</p>
                                </div>
                                <div className="col-md-10">
                                    <p className="m-0 w-100" style={amountVoucherStyle}>
                                        {paymentValues.voucher}
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-2 ms-2 mb-2">
                            <ButtonBox style={{width: "100%"}} onClick={handleSave} iconType="cash-multiple">
                                {getLabelName("Pay")}
                            </ButtonBox>
                            {/* <ButtonBox style={{ width: "100%" }} onClick={()=>{console.log('res_v',paymentValues)}}>
                "test"
              </ButtonBox> */}
                        </div>
                        <div className="col-md-2">
                            <ButtonBox
                            iconType="close-circle"
                                variant="danger"
                                style={{width: "100%"}}
                                onClick={() => {
                                    onActionEvent({
                                        action: ActionTypeEnum.AddPaymentValuesClear,
                                    });
                                    setShowPaymentDetailsModel(false);
                                }}
                            >
                                {getLabelName("Cancel")}
                            </ButtonBox>
                        </div>
                    </div>
                </div>
            </div>
            {
                <ModelDialogBox
                    isXCloseButtonVisible={true}
                    isModelVisible={showPaymentTypeDetailsModel}
                    isCloseButtonVisible={true}
                    isEscapeCloseEnabled={true}
                    size="lg"
                    //title={getLabelName("Payment type details")}
                    onCloseEvent={() => {
                        setShowPaymentTypeDetailsModel(false);
                    }}
                >
                    <PaymentTypeDetails
                        paymentTypeItems={paymentTypeItems}
                        setShowPaymentTypeDetailsModel={setShowPaymentTypeDetailsModel}
                        paymentValues={paymentValues}
                        setPaymentValues={setPaymentValues}
                        clickedPaymentType={clickedPaymentType}
                    />
                </ModelDialogBox>
            }
        </>
    );
    //#endregion
};
