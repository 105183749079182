import React, {FC} from "react";
import {ReportVatSearchParamsModel, SearchPanelPropsModel} from "../../../models";
import {Form,} from "react-bootstrap";
import {getLabelName} from "../../../utils";
import {ButtonBox, PrivilegesChecker, InputDatePicker} from "../..";

export const ReportVatSearchPanel: FC<
    SearchPanelPropsModel<ReportVatSearchParamsModel>
> = ({setSearchParams, handelSearch, searchParams, formId}) => {
    //#region html
    return (
        <>
            <Form>
                <div
                    className="row row-cols-1 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                    <InputDatePicker
                        selectedDate={searchParams.fromDate}
                        isClearable
                        className="form-control"
                        InputLabel={"From Date"}
                        selectsStart
                        startDate={searchParams.fromDate}
                        endDate={searchParams.toDate}
                        onChange={(date) => {
                            const obj = {...searchParams};
                            obj.fromDate = date === null ? undefined : date;
                            setSearchParams(obj);
                        }}
                    />
                    <InputDatePicker
                        selectedDate={searchParams.toDate}
                        isClearable
                        InputLabel={"To Date"}
                        className="form-control"
                        selectsEnd
                        startDate={searchParams.fromDate}
                        endDate={searchParams.toDate}
                        minDate={searchParams.fromDate}
                        onChange={(date) => {
                            const obj = {...searchParams};
                            obj.toDate = date === null ? undefined : date;
                            setSearchParams(obj);
                        }}
                    />
                </div>
                <PrivilegesChecker formId={formId} action="EnableSearch">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                        <ButtonBox
                            iconType="magnify"
                            className="btn-gradient-primary mx-3 btn-fw"
                            variant="outline-primary"
                            onClick={handelSearch}
                        >
                            {getLabelName("Search")}
                        </ButtonBox>
                    </div>
                </PrivilegesChecker>
            </Form>
            {/* </Card.Body>
      </Card> */}
        </>
    );
    //#endregion
};
