import {FC} from "react";
import {LookupItemModel, LookupTypeEnum, SearchPanelPropsModel, IncomeStatementReportSearchParamsModel} from "../../../models";
import {Form} from "antd";
import {getLabelName} from "../../../utils";
import {useLookups} from "../../../hooks";
import {ButtonBox, PrivilegesChecker, InputDatePicker, SelectBox} from "../..";

export const ReportIncomeStatementPageSearchPanel: FC<
    SearchPanelPropsModel<IncomeStatementReportSearchParamsModel>
> = ({
         setLoading,
         handelSearch,
         searchParams,
         setSearchParams, formId,
     }) => {
    //#region state
    const {getLookupItems} = useLookups(setLoading, [
        LookupTypeEnum.Currency,
        LookupTypeEnum.CostCenter,
        LookupTypeEnum.Stores,
    ]);
    //#endregion
    //#region variables
    const costCenterDataSource = getLookupItems(LookupTypeEnum.CostCenter, true);
//#endregion
    //#region html
    return (
        <>
            <Form onFinish={handelSearch}>
                <div
                    className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                    <Form.Item
                        name="fromDate"
                        rules={[{required: true, message: getLabelName("required")}]}
                    >
                    <InputDatePicker
                        selectedDate={searchParams.fromDate}
                        isClearable
                        InputLabel={"From Date"}
                        className="form-control"
                        selectsStart
                        startDate={searchParams.fromDate}
                        endDate={searchParams.toDate}
                        onChange={(date) => {
                            const obj = {...searchParams};
                            obj.fromDate = date === null ? undefined : date;
                            setSearchParams(obj);
                        }}
                    />
                </Form.Item>
                    <Form.Item
                        name="toDate"
                        rules={[{required: true, message: getLabelName("required")}]}
                    >
                    <InputDatePicker
                        selectedDate={searchParams.toDate}
                        isClearable
                        InputLabel={"To Date"}
                        selectsEnd
                        startDate={searchParams.fromDate}
                        endDate={searchParams.toDate}
                        minDate={searchParams.fromDate}
                        className="form-control"
                        onChange={(date) => {
                            const obj = {...searchParams};
                            obj.toDate = date === null ? undefined : date;
                            setSearchParams(obj);
                        }}
                    />
                    </Form.Item>
                    <SelectBox
                        labelName="Currency"
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.currencyId
                                ? [searchParams.currencyId.toString()]
                                : [""]
                        }
                        source={getLookupItems(LookupTypeEnum.Currency, true)}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.currencyId = undefined;
                            } else {
                                obj.currencyId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                    <SelectBox
                        labelName="coasts center"
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.costCenterId
                                ? [searchParams.costCenterId.toString()]
                                : [""]
                        }
                        source={costCenterDataSource}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.costCenterId = undefined;
                            } else {
                                obj.costCenterId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                    <SelectBox
                        labelName="Stores"
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.storeId ? [searchParams.storeId.toString()] : [""]
                        }
                        source={getLookupItems(LookupTypeEnum.Stores, true)}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.storeId = undefined;
                            } else {
                                obj.storeId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                </div>
                <PrivilegesChecker formId={formId} action="EnableSearch">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                        <ButtonBox
                            iconType="magnify"
                            className="btn-gradient-primary mx-3 btn-fw"
                            variant="outline-primary"
                            type="submit"
                           // onClick={handelSearch}
                        >
                            {getLabelName("Search")}
                        </ButtonBox>
                    </div>
                </PrivilegesChecker>
            </Form>
        </>
    );
    //#endregion
};
