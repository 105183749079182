export const RoutePageConfigurations = {
    miscellaneous:{
        defaultPage:"/default",
        homePage:"/",
        notFoundPage:"/404",
        unAuthorizedPage:"/401",
        forbiddenPage:"/403",
        dashboardPage:"/dashboard",
        logoutRedirectPage:"/logoutRedirect",
        userPage:"/users",
        userPermissionsPage:"/user/permissions",
        companySettingPage:"/company/setting",
        itemPage:"/items",
        branchPage:"/branch",
        currentBranchPage:"/branch/currentBranch",
        unitPage:"/units",
        currencyPage:"/currency",
        categoryPage:"/categories",
        cityPage:"/lookup/cities",
        countryPage:"/lookup/countries",
        costCenterPage:"/lookup/costCenters",
        additionAndRecommendationPage:"/lookup/restaurants/additionAndRecommendation",
        gradePage:"/lookup/grades",
        nationalityPage:"/lookup/nationalities",
        religionPage:"/lookup/religions",
        socialStatusPage:"/lookup/socialStatus",
        orderStatusPage:"/lookup/orders/status",
        carTypePage:"/lookup/cars/types",
        carColorPage:"/lookup/cars/colors",
        carServicePage:"/lookup/cars/services",
        costAdditionalPage:"/lookup/costs/additional",
        calcTypePage:"/calcTypes",
        applicationDeliveryPage:"/applicationDelivery",
        dictionaryPage:"/dictionary",
        restaurantTablePage:"/restaurant/tables"
    },
    sales:{
        salesInvoicePage:"/transactions/sales/invoice",
        salesRefundPage:"/transactions/sales/refund"
    },
    purchases:{
        purchaseBillPage:"/transactions/purchases/invoice",
        purchasesRefundPage:"/transactions/purchases/refund"
    },
    pos:{
        posSalesPage:"/transactions/sales/pos",
        posManagementPage:"/transactions/sales/pos/management",
        posPurchasePage:"/transactions/sales/pos/purchase",
        posSalesRefundPage:"/transactions/sales/pos/refund",
        posRestaurantManagementPage:"/transactions/sales/pos/restaurant",
        posRestaurantReservationManagementPage:"/transactions/sales/pos/reserve/restaurant",
        posRestaurantRefundManagementPage:"/transactions/sales/pos/management/refund",
        restaurantPosPage:"/transactions/sales/pos/restaurant"
    },
    transactions:{
        requestOutPage:"/transactions/RequestOut/request",
        showPricePage:"/transactions/ShowPrice/price",
        moneyOutPage:"/transactions/moneyOut",
        moneyInPage:"/transactions/moneyIn",
        updateTaxCategoryPage:"/transactions/taxCategory/update",
        ruleExpenseMoneyPage:"/transactions/rule/expenseMoney",
        ruleReceiveMoneyPage:"/transactions/rule/receiveMoney",
        estihkakSubtractRulePage:"/transaction/estihkak/subtract/rule",
        estihkakSubtractExpenseRulePage:"/transaction/estihkak/expense/rule",
        closeDayPage:"/closeDay",
        acDailyBoxPage:"/transaction/dailyJournalBox",
    },
    store:{
        storeTransferPage:"/transactions/store/transfer",
        storePage:"/store"
    },
    employee:{
        employeeDue:"/employee/due",
        employeeSubtract:"/employee/subtract/add",
        employeePage:"/employees",
        employeeDueRulesPage:"/employee/due/rule",
        employeeSubtractRulesPage:"/employee/subtract/rule",
        employeeStatusPage:"/employee/lookup/status"
    },
    suppliers:{
        supplierPaymentsPage:"/supplier/payment",
        refundSupplierPaymentsPage:"/supplier/payment/refund",
        supplierPage:"/suppliers"
    },
    customers:{
        customerPaymentPage:"/customer/payment",
        refundCustomerPaymentsPage:"/customer/payment/refund",
        customerPage:"/customers"
    },
    inventory:{
        inventoryCheckingPage:"/Inventory/checking"
    },
    finance:{
        journalVoucherPage:"/finance/journalVoucher",
        acBeginBalancePage:"/finance/acBeginBalance",
        accountTreePage:"/finance/accountTree",
        paymentTypePage:"/finance/paymentType",
    },
    reports:{
        reportFollowItemBalancePage: "/report/item/followBalance",
        reportItemsReachedToLimitOrderPage: "/report/item/limitOrderReached",
        reportBestLeastSellingItemsPage: "/report/item/bestLeastSelling",
        reportExpiryDateOfItemsPage: "/report/item/expiry",
        reportSalesAndPurchaseInGraphPage: "/report/sale/purchase/graph",
        reportProfitSalesInGraphPage: "/report/sale/profitGraph",
        reportEmployeeEsthkakSubtractsPage: "/report/employee/esthkakSubtracts",
        reportItemsDifferentInVatCalculationPage: "/report/item/vatDifference",
        reportEmployeeSalesCommissionPage: "/report/employee/salesCommission",
        reportEmployeeSalesPage: "/report/employee/sale",
        reportStationsPage: "/report/station",
        reportOrderStatusLogPage: "/report/order/statusLog",
        reportDebitCustomerPage: "/report/customer/debit",
        reportDebitSupplierPage: "/report/supplier/debit",
        reportCustomersTransactionAndAcTransactionPage: "/report/customer/transactionAndAccount",
        reportSuppliersTransactionAndAcTransactionPage: "/report/supplier/transactionAndAccount",
        reportGeneralLedgerPage: "/report/finance/generalLedger",
        reportBalanceSheetPage: "/report/finance/balanceSheet",
        reportIncomeStatementPage: "/report/finance/incomeStatement",
        reportInventoryCheckingPage: "/report/inventory/checking",
        reportStagnantItemsPage: "/report/item/stagnant",
        reportItemDataPage: "/report/item/data",
        reportItemsDifferenceInCostPage: "/report/item/costDifference",
        reportProductionItemsDataPage: "/report/item/productionData",
        reportEditUserItemDataPage: "/report/item/modifyItemData",
        reportSummeryItemTransactionPage: "/report/item/transactionSummary",
        reportSummeryItemTransactionQuantityPage: "/report/item/transactionQuantitySummary",
        reportSummeryItemTransactionQuantityWithValuePage: "/report/item/transactionQuantityValueSummary",
        reportCustomersDataPage: "/report/customer/data",
        reportCustomersPaymentsPage: "/report/customer/payment",
        reportsCustomersAccountsPage: "/report/customer/account",
        reportAllCustomersTransactionsPage: "/report/customer/allTransaction",
        reportSuppliersDataPage: "/report/supplier/data",
        reportAllSuppliersTransactionsPage: "/report/supplier/allTransaction",
        reportSuppliersPaymentsPage: "/report/supplier/payment",
        reportSuppliersAccountPage: "/report/supplier/account",
        reportSalesTransactionsPage: "/report/sale/transaction",
        reportReturnSalesTransactionsPage: "/report/sale/returnTransactions",
        reportAllSalesAndReturnTransactionsPage: "/report/sale/allSalesAndReturns",
        reportPurchaseTransactionsPage: "/report/purchase/transaction",
        reportReturnPurchaseTransactionsPage: "/report/purchase/returnTransactions",
        reportCashierPage: "/report/cashier",
        reportEmployeeSalesV2Page: "/report/employee/saleV2",
        reportVatPage: "/report/tax/vat",
        reportProfitOfSalePage: "/report/sale/profit",
        reportInMoneyPage: "/report/finance/inMoney",
        reportOutMoneyPage: "/report/finance/outMoney",
        reportInAndOutMoneyPage: "/report/finance/inAndOutMoney",
        reportAllInAndOutMoneyPage: "/report/finance/allInAndOutMoney",
        reportPosManagementPage:"/report/sales/pos/management",
        reportPosSalesRefundPage:"/report/sales/pos/refund",
        reportPosRestaurantManagementPage:"/report/sales/pos/restaurant",
        reportPosRestaurantReturnManagementPage:"/report/sales/pos/return/management",
        reportOrderPage: "/report/order"
    }
}
