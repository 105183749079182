import React, { Dispatch, SetStateAction } from "react";
import { TableColumn } from "react-data-table-component";
import {
  ActionTypeEnum,
  ChangeItemValueTypeEnum,
  CompanySettingModel,
  DailyTransactionTypeEnum,
  RequestActionModel,
  RowStateEnum,
  TransactionDetailResponseModel,
  TransactionItemResponseModel,
  TransactionResponseModel,
  ValidateTransactionModeEnum,
} from "../../../../models";
import {
  checkActionPrivilege,
  formatDate,
  getLabelName,
  getPagePrivileges,
  roundNumber,
} from "../../../../utils";
import {
  handleDeleteItem,
  handleRefundTransactionItemSelectStatus,
  validateTransactionObject,
} from "../businessLogic/transactionBl";
import { ConditionalStyles } from "react-data-table-component/dist/src/DataTable/types";
import {
  handleCalculateExistItemValuesChange,
  UpdateTransactionItemStateOnChange,
} from "../businessLogic/commonBl";
import { ButtonBox } from "../../..";

export const getRefundTransactionItemsColumns = (
  isArabic: boolean,
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel
): TableColumn<TransactionItemResponseModel>[] => {
  return [
    {
      name: "#",
      selector: (_row, index) => Number(index || 0) + 1,
      width: "50px",
    },
    {
      name: (
        <input
          type="checkbox"
          id={`checkbox_refund_all_items}`}
          onChange={(event: any) => {
            setState({
              ...stateValue,
              TransactionDetaillist: handleRefundTransactionItemSelectStatus(
                stateValue.TransactionDetaillist,
                null,
                event.target.checked
              ),
            });
          }}
        />
      ),
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="checkbox"
          id={`checkbox_refund_id${index}`}
          checked={row.rowState === RowStateEnum.Add}
          onChange={(event: any) => {
            setState({
              ...stateValue,
              TransactionDetaillist: handleRefundTransactionItemSelectStatus(
                stateValue.TransactionDetaillist,
                row.rowKey,
                event.target.checked
              ),
            });
          }}
        />
      ),
    },
    {
      name: (
        <label style={{ width: "fit-content" }}>
          {getLabelName("ItemCode")}
        </label>
      ),
      selector: (row) => row.ItemCode || "",
      sortable: true,
    },
    {
      name: (
        // <div style={{ width: "fit-content" }}>{getLabelName("ItemName")}</div>
        <label style={{ width: "fit-content" }}>
          {getLabelName("ItemName")}
        </label>
      ),
      selector: (row) => (isArabic ? row.ItemName : row.ItemName_En),
      sortable: true,
    },
    {
      name: getLabelName("Quantity"),
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`return_quantity_key_${index}`}
          value={row.Quantity}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            let val = e.target.value === null ? 0 : Number(e.target.value);
            val = val > row.defaultQuantity ? row.defaultQuantity : val;
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType: ChangeItemValueTypeEnum.None,
              Quantity: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
  ];
};
export const getTransactionItemsColumns = (
  isArabic: boolean,
  transactionType: DailyTransactionTypeEnum,
  setState: Dispatch<SetStateAction<TransactionDetailResponseModel>>,
  stateValue: TransactionDetailResponseModel,
  companySettings: CompanySettingModel
): TableColumn<TransactionItemResponseModel>[] => {
  const isRefund = isRefundTransaction(transactionType);
  let columns: TableColumn<TransactionItemResponseModel>[] = [
    {
      name: "#",
      selector: (_row, index) => Number(index || 0) + 1,
      width: "50px",
    },
    {
      name: (
        <label style={{ width: "fit-content" }}>
          {getLabelName("ItemCode")}
        </label>
      ),
      selector: (row) => row.ItemCode || "",
      sortable: true,
    },
    {
      name: (
        // <div style={{ width: "fit-content" }}>{getLabelName("ItemName")}</div>
        <label style={{ width: "fit-content" }}>
          {getLabelName("ItemName")}
        </label>
      ),
      selector: (row) => (isArabic ? row.ItemName : row.ItemName_En),
      sortable: true,
    },
    {
      name: getLabelName("Quantity"),
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`quantity_key_${index}`}
          value={row.Quantity}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType: ChangeItemValueTypeEnum.None,
              Quantity: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Price"),
      id: "price_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`price_key_${index}`}
          readOnly={isRefund}
          value={row.Unit_Price}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType: ChangeItemValueTypeEnum.ChangePrice,
              Unit_Price: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Price"),
      id: "price_inCurrency_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`price_in_currency_key_${index}`}
          readOnly={isRefund}
          value={row.Unit_PriceInCurrencyCalculated}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType: ChangeItemValueTypeEnum.ChangePriceInCurrency,
              Unit_PriceInCurrencyCalculated: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Price_With_Tax"),
      id: "price_with_tax_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`price_with_tax_key_${index}`}
          readOnly={isRefund}
          value={row.UnitPriceWithTaxCalculated}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType: ChangeItemValueTypeEnum.ChangePriceWithTax,
              UnitPriceWithTaxCalculated: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Price_With_Tax"),
      id: "price_with_tax_inCurrency_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`price_with_tax_inCurrency_key_${index}`}
          readOnly={isRefund}
          value={row.UnitPriceWithTaxInCurrency}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType:
                ChangeItemValueTypeEnum.ChangePriceWithTaxInCurrency,
              UnitPriceWithTaxInCurrency: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Discount"),
      id: "discount_colum",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`discount_key_${index}`}
          readOnly={isRefund}
          value={row.ItemDiscount}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType: ChangeItemValueTypeEnum.None,
              ItemDiscount: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Discount"),
      id: "discount_inCurrency_colum",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`discount_inCurrency_key_${index}`}
          readOnly={isRefund}
          value={row.ItemDiscountInCurrency}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            await UpdateTransactionItemStateOnChange(setState, stateValue, {
              ...row,
              changeValueType: ChangeItemValueTypeEnum.ChangeDiscountInCurrency,
              ItemDiscountInCurrency: val,
            });
          }}
          onBlur={async () => {
            await handleCalculateExistItemValuesChange(
              setState,
              stateValue,
              row
            );
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Tax"),
      id: "item_tax_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`item_tax_key_${index}`}
          value={row.Tax}
          readOnly={true}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("t_i_c"),
      id: "item_tax_inCurrency_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`item_tax_inCurrency_key_${index}`}
          value={row.TaxInCurrency}
          readOnly={true}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Total_Tax"),
      id: "item_total_tax_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`item_total_tax_key_${index}`}
          value={row.TotalTax}
          readOnly={true}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("t_t_i_c"),
      id: "item_total_tax_inCurrency_column",
      cell: (row: TransactionItemResponseModel, index) => (
        <input
          type="number"
          className="form-control"
          key={`item_total_tax_inCurrency_key_${index}`}
          value={row.TotalTaxInCurrency}
          readOnly={true}
          onWheel={(event) => {
            event.currentTarget.blur();
          }}
        />
      ),
      width: "100px",
    },
    {
      name: getLabelName("Total"),
      id: "total_column",
      selector: (row) => row.Total,
      sortable: true,
      width: "100px",
    },
    {
      name: getLabelName("Total"),
      id: "total_inCurrency_column",
      selector: (row) => roundNumber(row.TotalInCurrency),
      sortable: true,
      width: "100px",
    },
    {
      // eslint-disable-next-line react/button-has-type
      cell: (row: TransactionItemResponseModel) => (
        <ButtonBox
          id="deleteItemBtn"
          variant="danger"
          className="btn-xs"
          onClick={async () => {
            await handleDeleteItem(setState, stateValue, row);
          }}
        >
          {getLabelName("Delete")}
        </ButtonBox>
      ),
    },
  ];
  if (!companySettings.ApplyTax) {
    columns = columns.filter(
      (column) => column.id !== "price_with_tax_inCurrency_column"
    );
    columns = columns.filter(
      (column) => column.id !== "item_tax_inCurrency_column"
    );
    columns = columns.filter(
      (column) => column.id !== "item_total_tax_inCurrency_column"
    );
  }
  if (isRefund) {
    columns = columns.filter(
      (column) => column.id !== "price_with_tax_inCurrency_column"
    );
    columns = columns.filter(
      (column) => column.id !== "item_tax_inCurrency_column"
    );
    columns = columns.filter(
      (column) => column.id !== "item_total_tax_inCurrency_column"
    );
  }
  columns = columns.filter((colum) => colum.id !== "price_column");
  columns = columns.filter((column) => column.id !== "price_with_tax_column");
  columns = columns.filter((column) => column.id !== "discount_colum");
  columns = columns.filter((column) => column.id !== "item_tax_column");
  columns = columns.filter((column) => column.id !== "item_total_tax_column");
  columns = columns.filter((column) => column.id !== "total_column");
  return columns;
};
export const getTransactionListColumns = (
  isArabic: boolean,
  transactionType: DailyTransactionTypeEnum,
  onActionEvent: (o: RequestActionModel) => void,
  formId: number
): TableColumn<TransactionResponseModel>[] => {
  const isRefund = isRefundTransaction(transactionType);
  let columns: TableColumn<TransactionResponseModel>[] = [
    {
      name: "#",
      selector: (_row, index) => Number(index || 0) + 1,
      width: "50px",
    },
    {
      name: (
        // <div style={{ width: "fit-content" }}>{getLabelName("ItemName")}</div>
        <label style={{ width: "fit-content" }}>
          {getLabelName("CustomerName")}
        </label>
      ),
      selector: (row) => (isArabic ? row.customerNameAr : row.customerNameEn),
      sortable: true,
    },
    {
      name: getLabelName("identity"),
      selector: (row) => row.id,
      sortable: true,
      wrap: true,
      allowOverflow: true,
    },
    {
      name: getLabelName("Code"),
      selector: (row) => row.code,
      sortable: true,
      allowOverflow: true,
    },
    {
      name: getLabelName("Date"),
      selector: (row) => formatDate(row.creationDate, "dd/mm/yyyy hh:mm TT"),
      width: "150px",
      sortable: true,
      allowOverflow: true,
    },
    {
      name: getLabelName("Discount"),
      selector: (row) => row.discount || 0,
      sortable: true,
      allowOverflow: true,
    },
    {
      name: getLabelName("NetMoney"),
      selector: (row) => row.netMoney || 0,
      sortable: true,
      allowOverflow: true,
    },
    {
      id:"update_column",
      button:true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: TransactionResponseModel) =>
          getPagePrivileges().EnableUpdate && checkActionPrivilege({action:"EnableUpdate" ,formId:formId}) && (
          // <PrivilegesChecker formId={FormId} action="EnableUpdate">
            <ButtonBox
              onClick={async () => {
                if (
                  await validateTransactionObject(
                    row,
                    ValidateTransactionModeEnum.Modify,
                    onActionEvent
                  )
                ) {
                  onActionEvent({
                    id: row.id,
                    request: row,
                    action: ActionTypeEnum.ModifyStart,
                  });
                } else {
                }
              }}
            >
              {getLabelName("Edit")}
            </ButtonBox>
          // </PrivilegesChecker>
        ),
    },
    {
      id: "delete_column",
      button:true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: TransactionResponseModel) =>
        getPagePrivileges().EnableDelete  && checkActionPrivilege({action:"EnableDelete" ,formId:formId}) &&(
          // <PrivilegesChecker formId={formId} action="EnableDelete">
            <ButtonBox
              variant="danger"
              className="btn-xs"
              onClick={async () => {
                if (
                  await validateTransactionObject(
                    row,
                    ValidateTransactionModeEnum.Delete,
                    onActionEvent
                  )
                ) {
                  onActionEvent({
                    id: row.id,
                    request: row,
                    action: ActionTypeEnum.DeleteStart,
                  });
                }
              }}
            >
              {getLabelName("Delete")}
            </ButtonBox>
          // </PrivilegesChecker>
        ),
    },
    {
      id: "printPdf_column",
      button:true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: TransactionResponseModel) => (
        <ButtonBox
          //variant="danger"
          className="me-2"
          onClick={async () => {
            onActionEvent({
              id: row.id,
              request: row,
              action: ActionTypeEnum.PrintPdf,
            });
          }}
        >
          {getLabelName("Print")}
        </ButtonBox>
      ),
    },
    {
      id: "isValidForReturn_column",
      button:true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: TransactionResponseModel) => (
        <ButtonBox
          //variant="danger"
          className="me-2"
          onClick={async () => {
            onActionEvent({
              id: row.id,
              request: row,
              action: ActionTypeEnum.TransactionRefundIInitialization,
            });
          }}
        >
          {getLabelName("Return")}
        </ButtonBox>
      ),
    },
  ];
  if (isRefund) {
    columns = columns.filter(
      (column) => column.id !== "isValidForReturn_column"
    );
  }
  return columns;
};
export const handleConditionalRowStyles: ConditionalStyles<TransactionItemResponseModel>[] =
  [
    {
      when: (row: TransactionItemResponseModel) => row.Quantity == 2,
      style: {
        backgroundColor: "blue", // Apply your desired highlight style
      },
    },
    {
      when: (row: TransactionItemResponseModel) => row.Quantity == 1,
      style: {
        backgroundColor: "yellow", // Apply your desired highlight style
      },
    },
  ];

export const handleItemRowStyle = (
  request: TransactionItemResponseModel[]
): ConditionalStyles<any>[] => {
  let latestModifiedItem: TransactionItemResponseModel | null = null;
  let latestModifiedDate: Date | null = null;

  // Iterate through the array to find the item with the latest modified date
  for (const row of request) {
    const rowModifiedDate = new Date(row.latestModifiedDate);

    if (!latestModifiedDate || rowModifiedDate > latestModifiedDate) {
      latestModifiedDate = rowModifiedDate;
      latestModifiedItem = row;
    }
  }

  // Apply the highlight style to the row with the latest modified date
  return [
    {
      when: (row: TransactionItemResponseModel) => row === latestModifiedItem,
      style: {
        backgroundColor: "lightblue", // Apply your desired highlight style
      },
    },
  ];
};

export const handleRowStyle = (
  request: TransactionItemResponseModel[]
): ConditionalStyles<any>[] => {
  return [
    {
      when: (row: TransactionItemResponseModel) =>
        row == request[request.length - 1],
      style: {
        backgroundColor: "lightblue", // Apply your desired highlight style
      },
    },
  ];
};

//#region private
const isRefundTransaction = (
  transactionType: DailyTransactionTypeEnum
): boolean => {
  switch (transactionType) {
    case DailyTransactionTypeEnum.SalesRefund:
    case DailyTransactionTypeEnum.PurchasesRefund:
      return true;
    default:
      return false;
  }
};
//#endregion
