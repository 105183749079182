import {
  ActionTypeEnum,
  PaymentTypeEnum,
  DailyTransactionTypeEnum,
  HasFormIdModel,
  LookupItemFilterModel,
  LookupTypeEnum,
  RequestActionModel,
  StoreTransferTransactionDetailResponseModel,
  TransactionItemResponseModel,
  UserTransactionsSettingResponseModel,
  ValidationErrorModel,
  ComponentActionTypeEnum,
} from "../../../models";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ButtonBox,
  StoreTransferRegisterTransactionHeader,
  StoreTransferRegisterTransactionItem,
  GridTable,
  PrivilegesChecker,
} from "../..";
import { getLabelName, isArabicCurrentLanguage } from "../../../utils";
import { useStoreTransferState } from "./helper/state/storeTransferState";
import {
  addStoreTransferItem,
  getLookupFilterItems,
  handleStoreTransferTransactionTotalValues,
  storeTransferTransactionDetailInitialValue,
  UpdateStoreTransferTransactionActionUser,
  validateStoreTransferSubmitRequest,
} from "../common/businessLogic/storeTransferTransactionBl";
import { useFormik } from "formik";
import { Accordion } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";
import { getStoreTransferTransactionItemsColumns } from "../common/uiHelper/storeTransferDataTableColumns";
import { handleRowStyle } from "../common/uiHelper/dataTableColumns";
import {
  handleGroupItemsBasedOnId,
  validateAddItem,
} from "../common/businessLogic/commonBl";
import { saveStoreTransferTransaction } from "../../../serviceBroker/moveItemsTransactionApiServiceBroker";
import { transactionItemInitialValues } from "../common/businessLogic/transactionBl";
interface RegisterTransactionProps extends HasFormIdModel {
  request?: StoreTransferTransactionDetailResponseModel | null;
  lookups: LookupItemFilterModel[];
  setLookups: Dispatch<SetStateAction<LookupItemFilterModel[]>>;
  settings: UserTransactionsSettingResponseModel[];
  transactionType: DailyTransactionTypeEnum;
  setActionType: Dispatch<SetStateAction<ComponentActionTypeEnum>>;
  actionType: ComponentActionTypeEnum;
  onActionEvent: (o: RequestActionModel) => void;
}
export const StoreTransferRegisterTransaction: FC<RegisterTransactionProps> = (
  {
    request,
    lookups,
    setLookups,
    settings,
    transactionType,
    actionType,
    setActionType,
    onActionEvent,
  },
  formId
) => {
  //#region state
  const {
    fromStoreId,
    setFromStoreId,
    toStoreId,
    setToStoreId,
    isResetReference,
    setIsResetReference,
  } = useStoreTransferState();
  const [isArabic] = useState<boolean>(isArabicCurrentLanguage());
  const {
    forceRerender,
    data,
    setData,
    transactionItemObject,
    setTransactionItemObject,
  } = useStoreTransferState();
  const [initialValues] = useState<StoreTransferTransactionDetailResponseModel>(
    request ?? storeTransferTransactionDetailInitialValue
  );
  const columns: TableColumn<TransactionItemResponseModel>[] = useMemo(
    () => [...getStoreTransferTransactionItemsColumns(isArabic, setData, data)],
    [isArabic, data, setData, forceRerender]
  );
  //#endregion
  //#region useEffect
  useEffect(() => {
    //checkUserMultipleBranchesEnabled();
    const fillData = async () => {
      setData(request || initialValues);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    if (actionType !== ComponentActionTypeEnum.None) {
      handleChangeComponentActionType().then(() => {});
    }
  }, [actionType]);
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    //enableReinitialize: true,
    onReset: async () => {
      onActionEvent({
        id: 0,
        action: ActionTypeEnum.Clear,
      });
    },
    onSubmit: async () => {
      await handleSubmitStoreTransfer(data);
    },
  });
  //#endregion
  //#region function
  const handleAddStoreTransferItem = async (
    request: TransactionItemResponseModel,
    transactionType: DailyTransactionTypeEnum,
    itemCategorySelectBoxMultiselectRef: React.MutableRefObject<any>,
    itemSelectBoxMultiselectRef: React.MutableRefObject<any>
  ) => {
    request.Transaction_Type = Number(transactionType);
    request = await addStoreTransferItem(
      request,
      data,
      getLookupFilterItems(lookups, LookupTypeEnum.Items)
    );
    const errorList: ValidationErrorModel[] = await validateAddItem(request);
    if (errorList !== null && errorList.length !== 0) {
      onActionEvent({
        action: ActionTypeEnum.RaiseError,
        request: errorList,
      });
      return;
    } else {
      onActionEvent({
        action: ActionTypeEnum.ClearError,
      });
    }
    data.TransactionDetaillist = handleGroupItemsBasedOnId(
      data.TransactionDetaillist,
      request
    );
    const dataObject = handleStoreTransferTransactionTotalValues(data);
    setData({
      ...dataObject,
      TransactionDetaillist: [...dataObject.TransactionDetaillist],
    });
    itemCategorySelectBoxMultiselectRef.current &&
      itemCategorySelectBoxMultiselectRef.current.clearValue();
    itemSelectBoxMultiselectRef.current &&
      itemSelectBoxMultiselectRef.current.clearValue();
    setTransactionItemObject({
      ...request,
      ItemDiscount: 0,
      ItemDiscountRatio: 0,
      Quantity: 1,
    });
  };
  const handleSubmitStoreTransfer = async (
    request: StoreTransferTransactionDetailResponseModel
  ) => {
    request.TransactionType = transactionType;
    request = UpdateStoreTransferTransactionActionUser(request);
    const errorList: ValidationErrorModel[] =
      await validateStoreTransferSubmitRequest(request);
    if (errorList !== null && errorList.length !== 0) {
      onActionEvent({
        id: 0,
        action: ActionTypeEnum.RaiseError,
        request: errorList,
      });
      return;
    }
    switch (Number(data.PaymentType_ID)) {
      case Number(PaymentTypeEnum.Multiple):
        //setShowPaymentDetailsModel(true);
        break;
      default:
        await handleSaveStoreTransferTransactionDetails(request);
        break;
    }
  };
  const handleClearControls = async (isResetRequestState: boolean = true) => {
    setTransactionItemObject({ ...transactionItemInitialValues });
    if (isResetRequestState) {
      setData({ ...initialValues });
    }
  };
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.Clear:
        setData(storeTransferTransactionDetailInitialValue);
        break;
      case ActionTypeEnum.AddItem:
        await handleAddStoreTransferItem(
          request.request.item,
          transactionType,
          request.request.itemCategorySelectBoxMultiselectRef,
          request.request.itemSelectBoxMultiselectRef
        );
        break;
      case ActionTypeEnum.ChangeFromStoreId:
        setFromStoreId(request.request);
        break;
      case ActionTypeEnum.ChangeToStoreId:
        setToStoreId(request.request);
        break;
      case ActionTypeEnum.ResetReference:
        setIsResetReference(false);
        break;
      default:
        break;
    }
  };
  const handleSaveStoreTransferTransactionDetails = async (
    request: StoreTransferTransactionDetailResponseModel
  ) => {
    onActionEvent({
      action: ActionTypeEnum.EnableLoader,
    });
    const result = await saveStoreTransferTransaction(request);

    if (
      result.Errors !== null &&
      result.Errors !== undefined &&
      result.Errors.length !== 0
    ) {
      onActionEvent({
        id: 0,
        action: ActionTypeEnum.RaiseError,
        request: result.Errors,
      });
    } else {
      onActionEvent({
        action: ActionTypeEnum.AddSuccess,
      });
    }
    onActionEvent({
      action: ActionTypeEnum.DisableLoader,
    });
  };
  const handleChangeComponentActionType = async () => {
    onActionEvent({
      action: ActionTypeEnum.EnableLoader,
    });
    switch (actionType) {
      case ComponentActionTypeEnum.Clear:
        await handleClearControls(true);
        break;
      case ComponentActionTypeEnum.Reset:
        await handleClearControls(true);
        setTransactionItemObject(transactionItemInitialValues);
        setData(request || initialValues);
        break;
      case ComponentActionTypeEnum.Edit:
        await handleClearControls(false);
        setTransactionItemObject(transactionItemInitialValues);
        //@ts-ignore
        setData(request);
        break;
      default:
        break;
    }
    setActionType(ComponentActionTypeEnum.None);
    onActionEvent({
      action: ActionTypeEnum.DisableLoader,
    });
  };
  //#endregion
  //#region html
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="card p-3 btn-fix d-flex align-items-end mb-2">
          {data.TransactionDetaillist.length > 0 && (
            // <PrivilegesChecker
            //   formId={formId}
            //   action={request?.ID ? "EnableSave" : "EnableUpdate"}
            // >
            <ButtonBox
              type="submit"
              id="saveButton"
              iconType="content-save"
              className="btn btn-sm btn-gradient-primary"
            >
              {getLabelName(request?.ID ? "Save" : "Update")}
            </ButtonBox>
            // </PrivilegesChecker>
          )}
          <ButtonBox
            iconType="receipt"
            variant="danger"
            type="button"
            className="btn btn-sm"
            onClick={formik.handleReset}
          >
            {getLabelName("New")}
          </ButtonBox>
        </div>

        {/*transaction header*/}
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header></Accordion.Header>
            <Accordion.Body>
              <StoreTransferRegisterTransactionHeader
                formik={formik}
                data={data}
                setData={setData}
                transactionItemObject={transactionItemObject}
                lookups={lookups}
                isResetReference={isResetReference}
                onActionEvent={(o: RequestActionModel) => {
                  handleAction(o).then(() => {});
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <Accordion defaultActiveKey="1">
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <span>{" ".concat(getLabelName("Add Items To"))}</span>
            </Accordion.Header>
            <Accordion.Body>
              <StoreTransferRegisterTransactionItem
                data={data}
                transactionItemObject={transactionItemObject}
                setTransactionItemObject={setTransactionItemObject}
                settings={settings}
                lookups={lookups}
                setLookups={setLookups}
                transactionType={transactionType}
                fromStoreId={fromStoreId}
                toStoreId={toStoreId}
                onActionEvent={(o: RequestActionModel) => {
                  handleAction(o).then(() => {});
                }}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        {/*transaction items list*/}
        {data.TransactionDetaillist.length > 0 && (
          <Accordion defaultActiveKey="2">
            <Accordion.Item eventKey="2">
              <Accordion.Body>
                <>
                  <div className="item-unit-list">
                    <div className="section-title">
                      <span>{getLabelName("Item Transaction")}</span>
                    </div>
                    <GridTable
                      columns={columns}
                      data={data.TransactionDetaillist}
                      totalRows={1000}
                      currentPage={1}
                      pageSize={10000}
                      paginationType="none"
                      conditionalRowStyles={handleRowStyle(
                        data.TransactionDetaillist || []
                      )}
                      columnsProps={{
                        onActionEvent,
                        isArabic,
                        actions: [],
                      }}
                    />
                  </div>
                </>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </form>
    </>
  );
  //#endregion
};
