import { FC, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {getLabelName, setFormikFieldValueByName} from "../../utils";
import {
  AccountAcBeginBalanceTypeEnum,
  ActionTypeEnum,
  HasFormIdModel,
  LookupFormTypeEnum,
  LookupModel,
  RequestActionModel,
  ResponseBaseModel,
  RowStateEnum,
} from "../../models";
import { ButtonBox, LoadingBox, TextBox, PrivilegesChecker } from "..";
import { saveLookup } from "../../serviceBroker/lookupApiServiceBroker";

interface RegisterLookupProps extends HasFormIdModel {
  request?: LookupModel | null;
  lookupType: LookupFormTypeEnum;
  onActionEvent: (o: RequestActionModel) => void;
}

export const RegisterLookup: FC<RegisterLookupProps> = ({
  request,
  lookupType,
  onActionEvent = () => {},
  formId,
}) => {
  //#region variables
  const initialValues: LookupModel = request ?? {
    Name: "",
    Type_ID: 0,
    NameEn: "",
    IsDefault: false,
    Active: true,
    ID: 0,
    Value: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    rowState: 0,
  };
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationSchema] = useState(
    Yup.object({
      Name: Yup.string().required(
        getLabelName("name ar is missing")
      ),
      NameEn: Yup.string().required(
        getLabelName("name en is missing")
      ),
      //IsDefault: Yup.boolean(),
    })
  );
  const [showValueControl] = useState(() => {
    switch (lookupType) {
      case LookupFormTypeEnum.Tables:
      case LookupFormTypeEnum.Cities:
        return true;
      default:
        return false;
    }
  });
  //#endregion
  //#region function
  const validate = (values: LookupModel): any => {
    // @ts-ignore
    const errors: any = {};
    switch (lookupType) {
      case LookupFormTypeEnum.Tables:
      case LookupFormTypeEnum.Cities:
        if (
          values.Value === null ||
          values.Value === undefined ||
          values.Value === 0
        ) {
          //errors.Value = t("lookup.register.value.missing");
        }
        break;
    }
    return errors;
  };
  const handleSubmit = async (
    request: LookupModel
  ): Promise<ResponseBaseModel<LookupModel>> => {
    setLoading(true);
    request.rowState =
      request.ID === 0 ? Number(RowStateEnum.Add) : Number(RowStateEnum.Update);
    request.Type_ID = Number(lookupType);
    const result = await saveLookup(request);
    setLoading(false);
    return result === null ? {} : result;
  };
  const handleResetForm = async () => {
    console.log('rex_x_1')
    await formik.setValues({ ...initialValues });
    await formik.setTouched({});
    formik.setErrors({});
    onActionEvent({
      action: ActionTypeEnum.Clear,
    });
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validate,
    // onReset: () => {
    //   onActionEvent({
    //     action: ActionTypeEnum.Clear,
    //   });
    // },
    onSubmit: async (values, { resetForm }) => {
      let requestObject = values as LookupModel;
      requestObject.Type_ID = Number(lookupType);
      const res = await handleSubmit(requestObject);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        onActionEvent({
          request: res.Errors,
          action: ActionTypeEnum.RaiseError,
        });
      } else {
        onActionEvent({
          request: requestObject,
          action: ActionTypeEnum.Success,
        });
        resetForm();
      }
    },
  });
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
          <TextBox
            labelName={getLabelName("Name")} //{t("lookup.nameAr")}
            inputName={"Name"}
            errorText={formik.errors.Name}
            inputValue={formik.values.Name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextBox
            labelName={getLabelName("Name English")} //{t("lookup.nameEn")}
            inputName={"NameEn"}
            errorText={formik.errors.NameEn}
            inputValue={formik.values.NameEn}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {showValueControl && (
            <TextBox
              labelName={getLabelName("value")} //{t("lookup.Value")}
              inputName={"Value"}
              type="number"
              errorText={formik.errors.Value}
              inputValue={formik.values.Value}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isMandatory={false}
            />
          )}
          <TextBox
            labelName={getLabelName("default")} //{t(is Default)}
            inputName={"IsDefault"}
            type="checkbox"
            inputValue={formik.values.IsDefault}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <PrivilegesChecker
              formId={formId}
              action={request?.ID ? "EnableUpdate" : "EnableSave"}
            >
              <ButtonBox
                iconType="content-save"
                type="submit"
                className="btn-gradient-primary mx-3 btn-fw"
              >
                {getLabelName(request?.ID ? "Update" : "Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              iconType="receipt"
              variant="danger"
              type="button"
              className="btn-fw"
              onClick={async () => {
                await handleResetForm();
              }}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
