import { FC, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import {
  ButtonBox,
  ErrorValidationBox,
  SelectBox,
  TextBox,
  LoadingBox,
  PrivilegesChecker,
} from "..";
import { addStore } from "../../serviceBroker/storeApiServiceBroker";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
import {
  ActionTypeEnum,
  HasFormIdModel,
  LookupItemModel,
  LookupTypeEnum,
  RequestActionModel,
  RowStateEnum,
  StoreResponseModel,
  ValidationErrorModel,
} from "../../models";

interface AddStoreProps extends HasFormIdModel {
  request: StoreResponseModel;
  onActionEvent: (o: RequestActionModel) => void;
}

export const AddStore: FC<AddStoreProps> = ({
  request,
  onActionEvent,
  formId,
}) => {
  //#region variables
  const initialValues: StoreResponseModel = request;
  const isArabic = isArabicCurrentLanguage();
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [branchList, setBranchList] = useState<LookupItemModel[]>([]);
  const [branchId, setBranchId] = useState<number>(0);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const validationSchema = Yup.object({
    Name: Yup.string().required(getLabelName("required")),
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      const branchList = await getLookupByType(LookupTypeEnum.Branches);
      setBranchList(branchList);
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region function
  const handleSubmit = async (request: StoreResponseModel) => {
    try {
      setLoading(true);
      request.rowState =
        request.ID === 0
          ? Number(RowStateEnum.Add)
          : Number(RowStateEnum.Update);
      request.Branch_ID = branchId;
      const res = await addStore(request);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        setValidationErrors(res.Errors);
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.Failed,
          requestBeforeError: request,
          request: isArabic
            ? res.Errors[0].MessageAr
            : res?.Errors[0].MessageEn,
        });
        setLoading(false);
      } else {
        setValidationErrors([]);
        setLoading(false);
        onActionEvent({ id: 0, action: ActionTypeEnum.AddSuccess });
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      setValidationErrors(errors);
    }
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
  });
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      <form className="forms-sample" onSubmit={formik.handleSubmit}>
        <div className="row row-cols-2 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 g-sm-2 g-md-4 align-items-start">
          <TextBox
            labelName={getLabelName("Name")}
            inputName="Name"
            isMandatory={true}
            inputValue={formik.values.Name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Name}
          />

          <TextBox
            labelName={getLabelName("Eng Name")}
            inputName="Name_En"
            inputValue={formik.values.Name_En}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Name_En}
          />
          <TextBox
            labelName={getLabelName("Address")}
            inputName={"Address"}
            errorText={formik.errors.Address}
            inputValue={formik.values.Address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextBox
            labelName={getLabelName("Phone")}
            inputName={"Phone"}
            errorText={formik.errors.Phone}
            inputValue={formik.values.Phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <SelectBox
            labelName={getLabelName("Branch")}
            source={branchList}
            isSingleSelect={true}
            errorText={formik.errors.Branch_ID}
            onStatusChange={(e: any) => {
              formik.values.Branch_ID = e.value;
              setBranchId(e.value);
            }}
            selectedValues={[request.Branch_ID.toString()]}
            multiselectRef={undefined}
          />
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-end mb-3">
            <PrivilegesChecker
              formId={formId}
              action={request.ID ? "EnableUpdate" : "EnableSave"}
            >
              <ButtonBox type="submit" className="btn-primary btn-sm mx-3">
                {getLabelName(request.ID ? "Update" : "Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              variant="danger"
              iconType="receipt"
              type="button"
              className="btn-sm"
              onClick={() => {
                onActionEvent({ id: 0, action: ActionTypeEnum.Clear });
              }}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
