import {createSlice} from "@reduxjs/toolkit";
import {
    AuthenticateUserRequestModel,
    AuthenticateUserResponseModel, FlattenMenuModel,
    LayoutEnum ,
    MenuItemNameModel,
    MenuModel,
    ValidationErrorModel
} from "../models";
import {CookieSet, getFlattenMenuData, SecureLocalStorageClear, SecureLocalStorageSet} from "../utils";
import {
    authenticateUserLogin,
    getUserBranchPermissions,
    getUserMenu,
    getUserPrivileges,
} from "../serviceBroker/userApiServiceBroker";
import {getAllDictionaries} from "../serviceBroker/dictionaryApiServiceBroker";
import {RoutePageConfigurations, SystemConfiguration} from "../configuration";
import {getCompanySetting} from "../serviceBroker/companySettingApiServiceBroker";

const initialState: AuthenticateUserResponseModel = {
    Result: null,
    userToken: null,
    isLoading: false,
    isAuthenticated: false,
    Errors: [],
};

const slice = createSlice({
    name: "UserAuthenticate",
    initialState: initialState,
    reducers: {
        setLoading: (state, action) => {
            // alert('state ' +JSON.stringify(state));
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setAuthenticateSuccess: (state, action) => {
            const {response, token, remember} = action.payload;
            generateUserDefaultLayoutStorage(response).then(() => {
            });
            if (remember === true) {
                SecureLocalStorageSet(SystemConfiguration.keys.token, token);
                SecureLocalStorageSet(
                    SystemConfiguration.keys.user,
                    JSON.stringify(response)
                );
            }
            return {
                ...state,
                Result: response, //action.payload,
                userToken: token,
                isLoading: false,
                isAuthenticated: true,
                Errors: [],
            };
        },
        setIntegrationAuthenticateSuccess: (state, action) => {
            const {token} = action.payload;
            // @ts-ignore
            //LocalStorageSet(process.env.REACT_APP_authenticatedTokenStorageKey, token);
            CookieSet(SystemConfiguration.keys.token, token);
            return {
                ...state,
                Result: null, //action.payload,
                userToken: token,
                isLoading: false,
                isAuthenticated: true,
                errors: [],
            };
        },
        setAuthenticateFailed: (state, action) => {
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                Errors: action.payload,
            };
        },
        setAuthenticationReset: (state, action) => {
            SecureLocalStorageClear();
            return {
                ...state,
                Result: null,
                isLoading: false,
                isAuthenticated: false,
                userToken: null,
                redirectUrl: RoutePageConfigurations.miscellaneous.homePage,
                Errors: [],
            };
        },
    },
});

export default slice.reducer;
const {
    setLoading,
    setAuthenticateSuccess,
    setAuthenticateFailed,
    setAuthenticationReset,
    setIntegrationAuthenticateSuccess,
} = slice.actions;

const generateUserDefaultLayoutStorage = async (
    user: AuthenticateUserResponseModel
): Promise<string | undefined> => {
    let defaultLayout: number = LayoutEnum.defaultAuthenticatedLayout;
    if (user !== null && user !== undefined) {
        CookieSet(SystemConfiguration.cacheKey.layoutName, defaultLayout.toString());
        return defaultLayout.toString();
    }
};
export const authenticateUser = (obj: AuthenticateUserRequestModel) => {
    return async (dispatch: any, getState: any) => {
        try {
            dispatch(setLoading(true));
            const params = {...obj};
            let apiResponse: AuthenticateUserResponseModel = await authenticateUserLogin(
                params
            );
            if (
                apiResponse != null &&
                apiResponse.Result !== null &&
                apiResponse.Result !== undefined
            ) {
                const dictionariesList = await getAllDictionaries();
                const privileges = await getUserPrivileges(apiResponse.Result.UserID);
                const menu = await getUserMenu(apiResponse.Result.UserID);
                const companySettings = await getCompanySetting();
                const branches = await getUserBranchPermissions(apiResponse.Result.UserID);
                apiResponse.Result.currentBranchId=branches?.length===1?Number(branches[0].BranchId):null;
                apiResponse.Result.isMultipleUserBranchPermissions=!(branches?.length===1);

                SecureLocalStorageSet(
                    SystemConfiguration.keys.dictionary,
                    JSON.stringify(dictionariesList)
                );
                SecureLocalStorageSet(
                    SystemConfiguration.keys.privileges,
                    JSON.stringify(privileges)
                );
                SecureLocalStorageSet(
                    SystemConfiguration.keys.menu,
                    JSON.stringify(menu)
                );
                SecureLocalStorageSet(
                    SystemConfiguration.keys.companySettings,
                    JSON.stringify(companySettings)
                );
                SecureLocalStorageSet(SystemConfiguration.keys.homePageReloaded, "0");
                if (menu !== null && menu !== undefined && menu.length !== 0) {
                    const flattenMenu:FlattenMenuModel[]=getFlattenMenuData(menu);
                    const menuName: MenuItemNameModel[] = generateMenuItemName(menu);
                    SecureLocalStorageSet(
                        SystemConfiguration.keys.menuItemName || "",
                        JSON.stringify(menuName)
                    );
                    SecureLocalStorageSet(
                        SystemConfiguration.keys.flattenMenuItem || "",
                        JSON.stringify(flattenMenu)
                    );
                }
                dispatch(
                    setAuthenticateSuccess({
                        response: apiResponse.Result,
                        remember: obj.remember,
                        token: apiResponse.Result.Token,
                    })
                );
            } else {
                dispatch(setAuthenticateFailed(apiResponse?.Errors));
            }
        } catch (err: any) {
            const errorMessage = `${err.toString()}\n${err.stack}`;
            const errors: ValidationErrorModel[] = [{MessageAr: errorMessage, MessageEn: errorMessage}];
            dispatch(setAuthenticateFailed(errors));
        } finally {
            // dispatch(setLoading(false));
        }
    };
};
export const resetAuthenticateUser =
    () => async (dispatch: any, getstate: any) => {
        dispatch(setAuthenticationReset(null));
    };
export const authenticateIntegrationUser =
    (token: string) => async (dispatch: any, getstate: any) => {
        dispatch(setIntegrationAuthenticateSuccess({token: token}));
    };
export const logoutUser = () => {
    return async (dispatch: any, getstate: any) => {
        dispatch(setAuthenticationReset(null));
    };
};
//#region private functions
const generateMenuItemName = (request: MenuModel[]): MenuItemNameModel[] => {
    let result: MenuItemNameModel[] = [];
    request.forEach((row) => {
        if (row.WebUrl !== null && row.WebUrl !== undefined && row.WebUrl !== "") {
            result.push({
                name: row.Name,
                nameAr: row.ArabicName,
                url: row.WebUrl.toLowerCase(),
            });
        }
        if (
            row.ChildBusinessObject !== null &&
            row.ChildBusinessObject !== undefined &&
            row.ChildBusinessObject.length !== 0
        ) {
            row.ChildBusinessObject.forEach((subRow) => {
                const arr: MenuItemNameModel[] = generateMenuItemName([subRow]);
                result = result.concat(arr);
            });
        }
    });
    return result;
};
//#endregion
