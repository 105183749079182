import {FC, useEffect, useState} from "react";
import {ErrorValidationBox, LoadingBox, ModelDialogBox, ToastBox, UserPermissions, UsersList} from "../../components";
import {ActionTypeEnum, RequestActionModel, ToastModel, UserRegistrationResponseModel, ValidationErrorModel} from "../../models";
import {getUserInformation, getUserPrivileges, getUsers,} from "../../serviceBroker/userApiServiceBroker";
import {Accordion} from "react-bootstrap";
import {getLabelName, getUserId, SecureLocalStorageSet} from "../../utils";
import {SystemConfiguration} from "../../configuration";

export const UserPermissionsPage: FC<{ formId: number }> = ({formId}) => {
    //#region state
    const [loading, setLoading] = useState(false);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>(
        []
    );
    const [users, setUsers] = useState<UserRegistrationResponseModel[]>([]);
    const [user, setUser] = useState<UserRegistrationResponseModel | null>(null);
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false
    });
    const [showUserPermissionModel, setShowUserPermissionModel] = useState(false);
    const [showUserSettingModel, setShowUserSettingModel] = useState(false);
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            await getAllUsers();
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region function
    const handleUserAction = async (request: RequestActionModel) => {
        const user = await getUserInformation(request.id!);
        switch (request.action) {
            case ActionTypeEnum.GrantPermissions:
                setUser(user);
                setShowUserPermissionModel(true);
                break;
            case ActionTypeEnum.UserSetting:
                setUser(user);
                setShowUserSettingModel(true);
                break;
            case ActionTypeEnum.UpdateUserPermissions:
                await handleUpdateUserPermissions(request.id!);
                setShowUserPermissionModel(false);
                setToastModel({ ...toastModel, show: true });
                break;
            case ActionTypeEnum.CancelUpdateUserPermissions:
                setShowUserPermissionModel(false);
                setToastModel({ ...toastModel, show: true });
                break;
        }
    };
    const getAllUsers = async () => {
        setLoading(true);
        const userList = await getUsers();
        setUsers(userList);
        setLoading(false);
    };
    const handleUpdateUserPermissions  = async (userId: number) => {
        if(userId==getUserId()) {
            const privileges = await getUserPrivileges(userId);
            SecureLocalStorageSet(
                SystemConfiguration.keys.privileges,
                JSON.stringify(privileges)
            );
        }
    }
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            {<ErrorValidationBox errors={validationErrors}/>}
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.Header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false});
                    }}
                />
            )}
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>User Permission List</Accordion.Header>
                    <Accordion.Body>
                        <ModelDialogBox
                            isModelVisible={showUserPermissionModel}
                            isCloseButtonVisible={false}
                            size="xl"
                            title={getLabelName("user permissions")}
                        >
                            <UserPermissions
                                userObject={user}
                                onActionEvent={async (o) => {
                                    await handleUserAction(o);
                                }}
                            />
                        </ModelDialogBox>
                        {/* user  setting*/}
                        <ModelDialogBox
                            isModelVisible={showUserSettingModel}
                            isCloseButtonVisible={false}
                            title={getLabelName("user setting")}
                            size="xl"
                        >
                            {/* <UserSettings
                onComplete={(e: any) => {
                  setShowUserSettingModel(false);
                  setShowToastModel(e);
                }}
              /> */}
                        </ModelDialogBox>

                        {/* user list */}
                        {users && users.length !== 0 && (
                            <UsersList
                                isPermissionAble={false}
                                request={users}
                                onActionEvent={async (o: RequestActionModel) => {
                                    await handleUserAction(o);
                                }}
                                onCompleteEvent={getAllUsers}
                                formId={formId}
                            />
                        )}
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
    //#endregion
};
