import {TableColumn} from "react-data-table-component";
import {getStaticColumns} from "../rendering/renderGridStaticColumns";
import {getServerActionsColumns} from "../rendering/renderGridActionsColumns";
import {
    GridColumnsResponseModel,
} from "../../../../../models";
import {getFormPrivileges} from "../../../../../utils";

export const generateGridColumns = async <T>({
                                                 isArabic,
                                                 onActionEvent,
                                                 formId,
                                                 actions,
                                                 actionsColumnGrow,
                                                 data,
                                                 columnsSettings,style
                                             }: any): Promise<{
    gridCols: TableColumn<T>[];
    settingsColumns: GridColumnsResponseModel[];
}> => {
    const gridId = getFormPrivileges(formId).ColumnsSettingId||0;
    const { gridColumns, settingsColumns } = await getStaticColumns({
        gridId,
        data,
        isArabic,
    });
    const actionsColumns = getServerActionsColumns(actions, formId, onActionEvent,style);
    return { gridCols: [...gridColumns, actionsColumns], settingsColumns };
};