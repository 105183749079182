import { FC } from "react";
import { isArabicCurrentLanguage } from "../../utils";
import {GridTable} from "..";
import { ActionTypeEnum, HasFormIdModel, UnitModel } from "../../models";


interface UnitListProps extends HasFormIdModel {
  request: UnitModel[];
  onActionEvent?: any | null;
}

export const UnitList: FC<UnitListProps> = ({
  request,
  onActionEvent,
  formId,
}) => {
  //#region variable
  const isArabic: boolean = isArabicCurrentLanguage();
  const defaultPageSize: number = 50;
  //#endregion
  //#region html
  return (
    <>
      <GridTable
        data={request}
        totalRows={request.length}
        currentPage={1}
        pageSize={defaultPageSize}
        paginationType="client"
        columnsProps={{
          actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
          onActionEvent,
          isArabic,
        }}
        formId= {formId}
      />
    </>
  );
  //#endregion
};
