import React, { FC, useEffect, useRef, useState } from "react";
import {
  ActionTypeEnum,
  RequestActionModel,
  RowStateEnum,
  TransactionDetailResponseModel,
  TransactionItemResponseModel,
  ValidationErrorModel,
} from "../../../models";
import {
  getLabelName,
  getUserId,
  isArabicCurrentLanguage,
} from "../../../utils";
import { TableColumn } from "react-data-table-component";
import { getRefundTransactionItemsColumns } from "../common/uiHelper/dataTableColumns";
import {
  ButtonBox,
  ErrorValidationBox,
  LoadingBox,
  ModelDialogBox,
  GridTable,
  TextBox,
} from "../..";
import {
  clearTransactionDataForRefund,
  transactionDetailInitialValue,
} from "../common/businessLogic/transactionBl";
import { getTransactionDetailForRefund } from "../../../serviceBroker/transactionApiServiceBroker";
import { getPointOfSaleSetting } from "../../../serviceBroker/userApiServiceBroker";

export const PosRefundItemList: FC<{
  onActionEvent: (o: RequestActionModel) => void;
}> = ({ onActionEvent = () => {} }) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  //#endregion
  //#region state
  const searchTextInputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  let [data, setData] = useState<TransactionDetailResponseModel>(
    transactionDetailInitialValue
  );
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const columns: TableColumn<TransactionItemResponseModel>[] = [
    ...getRefundTransactionItemsColumns(isArabic, setData, data),
  ];
  const [_, setShowModal] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (searchTextInputRef.current) {
      searchTextInputRef.current.focus();
    }
    const fillData = async () => {
      const posSetting = await getPointOfSaleSetting(getUserId(), true);
      if (posSetting.AllowReturnWithoutBill) {
        onActionEvent({
          action: ActionTypeEnum.RefundWithoutBill,
        });
      }
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region function
  const validatePosReturn = (): [
    TransactionItemResponseModel[],
    ValidationErrorModel[]
  ] => {
    let items = data.TransactionDetaillist.filter(
      (p) => p.rowState === Number(RowStateEnum.Add)
    );
    let errors: ValidationErrorModel[] = [];
    if (items !== null && items.length !== 0) {
      items.forEach((row) => {
        if (row.Quantity > row.MaxQty) {
          errors.push({
            MessageEn: `${row.ItemName_En} ${getLabelName("refund item qty")} ${
              row.MaxQty
            }`,
            MessageAr: `${row.ItemName} ${getLabelName("refund item qty")} ${
              row.MaxQty
            }`,
          });
        }
      });
    } else {
      errors.push({
        MessageEn: getLabelName("refund.items"),
        MessageAr: getLabelName("refund.items"),
      });
    }
    items = errors.length !== 0 ? [] : items;
    return [items, errors];
  };
  //#endregion
  //#region html
  return (
    <>
      <ModelDialogBox
        isXCloseButtonVisible={true}
        isModelVisible={true}
        isCloseButtonVisible={false}
        isEscapeCloseEnabled={true}
        title={getLabelName("Return")}
        size="lg"
        onCloseEvent={() => {
          setShowModal(false);
          onActionEvent({
            action: ActionTypeEnum.Ignore,
          });
        }}
      >
        {loading && <LoadingBox />}
        {<ErrorValidationBox errors={validationErrors} />}
        <div className="row row-cols-xxl-6 row-cols-xl-6 row-cols-lg-6 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
          <TextBox
            inputName="searcText"
            type="number"
            inputValue={searchText}
            labelName={""}
            placeHolder={getLabelName("Invoice No.")}
            inputRef={searchTextInputRef}
            onChange={(e: any) => {
              setSearchText(e.target.value);
            }}
            onBlur={async () => {
              if (searchText !== null) {
                setValidationErrors([]);
                setLoading(true);
                let recordForReturn = await getTransactionDetailForRefund(
                  searchText
                );
                setData(
                  clearTransactionDataForRefund(
                    recordForReturn || transactionDetailInitialValue
                  )
                );
                if (
                  recordForReturn === null ||
                  recordForReturn === undefined ||
                  recordForReturn.TransactionDetaillist === null ||
                  recordForReturn.TransactionDetaillist === undefined ||
                  recordForReturn.TransactionDetaillist.length === 0
                ) {
                  setValidationErrors([
                    {
                      MessageAr: getLabelName("refund.noItems.ForRefund"),
                      MessageEn: getLabelName("refund.noItems.ForRefund"),
                    },
                  ]);
                }
                setLoading(false);
              }
            }}
          />
          <ButtonBox
            iconType="check-circle"
            type="button"
            size="sm"
            variant=""
            className="btn-gradient-primary mx-1 btn-fw"
            onClick={() => {
              const [items, errors] = validatePosReturn();
              setValidationErrors(errors);
              if (items !== null && items.length !== 0) {
                onActionEvent({
                  request: { ...data, TransactionDetaillist: items },
                  action: ActionTypeEnum.TransactionRefund,
                });
              }
            }}
          >
            {getLabelName("Ok")}
          </ButtonBox>
          <ButtonBox
            iconType="close-circle"
            variant="danger"
            type="button"
            size="sm"
            className="btn-fw"
            onClick={() => {
              onActionEvent({ action: ActionTypeEnum.Ignore });
            }}
          >
            {getLabelName("Cancel")}
          </ButtonBox>
        </div>
        {data?.TransactionDetaillist !== null &&
          data?.TransactionDetaillist.length !== 0 && (
            <div>{getLabelName("select Items")}</div>
          )}
        <GridTable
          columns={columns}
          data={data?.TransactionDetaillist || []}
          totalRows={1000}
          currentPage={1}
          pageSize={10000}
          paginationType="none"
        />
        <div className="row py-2"></div>
      </ModelDialogBox>
    </>
  );
  //#endregion
};
