import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import { AlYuserApiUrls } from "../configuration";
import {
  ItemModel,
  ResponseBaseModel,
  SearchItemResponseModel,
  SearchItemRequestModel,
  SearchItemsTypeEnum,
  SearchItemApiResponseModel,
  deleteItemResponseModel,
  LookupItemModel,
  ItemImageResponseModel,
  ItemConfigurationsResponseModel,
  TransactionItemResponseModel,
  UpdateCategoryTaxRequestModel,
  CategoryDeleteResponseModel,
  SearchStoreInventoryRequestModel,
  SearchStoreInventoryResponseModel,
  SaveInventoryCheckingRequestModel,
  StoreResponseModel,
} from "../models";
import { getLabelName } from "../utils";

//#region get
export const itemSearchItemBalanceInventoryCheck = async (
  request: SearchStoreInventoryRequestModel
): Promise<ResponseBaseModel<SearchStoreInventoryResponseModel[]>> => {
  let response: ResponseBaseModel<SearchStoreInventoryResponseModel[]> = {};
  let url: string = `${AlYuserApiUrls.itemController.searchItemBalanceInventoryCheck}?searchItem.lang=${request.lang}`;
  url = `${url}&searchItem.pageSize=${request.pageSize || 10}`;
  url = `${url}&searchItem.pageNumber=${request.pageNumber || 1}`;
  url = request.categoryId
    ? `${url}&searchItem.categoryId	=${request.categoryId}`
    : url;
  url = request.itemBarcode
    ? `${url}&searchItem.itemBarcode	=${request.itemBarcode}`
    : url;
  url = request.itemName
    ? `${url}&searchItem.itemName	=${request.itemName}`
    : url;
  url = request.userId ? `${url}&searchItem.userId	=${request.userId}` : url;
  const fetchResult: ResponseBaseModel<SearchStoreInventoryResponseModel[]> =
    await AlYusrAxiosApiInstance.get(url);
  response.Errors = fetchResult.Errors;
  response.TotalRecoredCount = fetchResult.TotalRecoredCount;
  if (
    fetchResult.Result !== null &&
    fetchResult.Result !== undefined &&
    fetchResult.Result.length !== 0
  ) {
    response.Result = fetchResult.Result;
  }
  return response;
};
export const getItemsByCategoryId = async (
  categoryId: number | undefined
): Promise<LookupItemModel[]> => {
  let url: string = `${AlYuserApiUrls.itemController.searchItems}?searchItem.lang=1`;
  url =
    categoryId !== null && categoryId !== undefined
      ? `${url}&searchItem.categorey_ID=${categoryId}`
      : url;
  const fetchResult: ResponseBaseModel<SearchItemApiResponseModel[]> =
    await AlYusrAxiosApiInstance.get(url);
  let response: LookupItemModel[] = [];
  response.push({
    nameAr: getLabelName("Select"),
    name: "Select",
    value: null,
  });
  if (
    fetchResult !== null &&
    fetchResult !== undefined &&
    fetchResult.Result !== null &&
    fetchResult.Result != undefined &&
    fetchResult.Result.length !== 0
  ) {
    fetchResult.Result.forEach((row) => {
      response.push({
        name: row.Name_En,
        nameAr: row.Name,
        value: row.ID.toString(),
      });
    });
  }
  return response;
};
export const getPosItemsBySearch = async (
  categoryId: number | null = null,
  nameAndCode: string | null = null,
  barCode: string | null = null,
  storeId: number | null,
  pageNumber: number | null = 1,
  pageSize: number | null = 50
): Promise<ResponseBaseModel<TransactionItemResponseModel[]>> => {
  let url: string = `${
    AlYuserApiUrls.itemController.getItemListToAddInTransaction
  }?searchItem.store_ID=${storeId || 1}`;
  url = `${url}&searchItem.pageSize=${pageSize}`;
  url = `${url}&searchItem.pageNumber=${pageNumber}`;
  url =
    categoryId != null ? `${url}&searchItem.Categorey_ID=${categoryId}` : url;
  url = barCode != null ? `${url}&searchItem.barcode=${barCode}` : url;
  url =
    nameAndCode != null
      ? `${url}&searchItem.mixFilitrationNameAndCode=${encodeURIComponent(
          nameAndCode
        )}`
      : url;
  let response: ResponseBaseModel<TransactionItemResponseModel[]>;
  response = await AlYusrAxiosApiInstance.get(url);
  return response;
};
export const searchItems = async (
  request: SearchItemRequestModel
): Promise<ResponseBaseModel<SearchItemApiResponseModel[]>> => {
  let response: ResponseBaseModel<SearchItemApiResponseModel[]> = {};
  let url: string = `${AlYuserApiUrls.itemController.searchItems}?searchItem.lang=1`;
  url = `${url}&searchItem.pageSize=${request.pageSize || 10}`;
  url = `${url}&searchItem.pageNumber=${request.pageNumber || 1}`;
  url =
    request.categoryId !== null && request.categoryId !== undefined
      ? `${url}&searchItem.categorey_ID=${request.categoryId}`
      : url;

  url =
    request.smallestUnit !== null && request.smallestUnit !== undefined
      ? `${url}&searchItem.smallestUnit=${request.smallestUnit}`
      : url;

  url =
    request.searchType === SearchItemsTypeEnum.ItemBarCode &&
    request.searchValue
      ? `${url}&searchItem.barcode	=${request.searchValue}`
      : url;

  url =
    request.searchType === SearchItemsTypeEnum.ItemCode && request.searchValue
      ? `${url}&searchItem.itemCode	=${request.searchValue}`
      : url;

  url =
    request.searchType === SearchItemsTypeEnum.ItemName && request.searchValue
      ? `${url}&searchItem.itemName	=${request.searchValue}`
      : url;

  const fetchResult: ResponseBaseModel<SearchItemApiResponseModel[]> =
    await AlYusrAxiosApiInstance.get(url);
  response.Errors = fetchResult.Errors;
  response.TotalRecoredCount = fetchResult.TotalRecoredCount;
  if (
    fetchResult.Result !== null &&
    fetchResult.Result !== undefined &&
    fetchResult.Result.length !== 0
  ) {
    response.Result = [];
    fetchResult.Result.forEach((row) => {
      response.Result?.push(row);
    });
  }
  return fetchResult;
};
export const getItemFullDetailsById = async (
  itemId: number
): Promise<ResponseBaseModel<ItemModel>> => {
  let response: ResponseBaseModel<ItemModel>;
  const url: string = `${AlYuserApiUrls.itemController.getItemFullDetailsById}?itemId=${itemId}`;
  response = await AlYusrAxiosApiInstance.get(url);
  return response;
};

export const getItemImageById = async (
  id: number
): Promise<ResponseBaseModel<ItemImageResponseModel>> => {
  let response: ResponseBaseModel<ItemImageResponseModel>;
  response = await AlYusrAxiosApiInstance.get(
    `${AlYuserApiUrls.itemController.getItemImageByItemId}=${id}`
  );
  return response;
};
export const getItemConfigurations = async (): Promise<
  ResponseBaseModel<ItemConfigurationsResponseModel>
> => {
  let response: ResponseBaseModel<ItemConfigurationsResponseModel>;
  response = await AlYusrAxiosApiInstance.get(
    AlYuserApiUrls.itemController.getItemConfigurations
  );
  return response;
};
//#endregion
//#region post
export const saveInventoryChecking = async (
  request: SaveInventoryCheckingRequestModel
): Promise<StoreResponseModel> => {
  let apiResponse: StoreResponseModel = {
    Address: "",
    Name_En: "",
    Name: "",
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    Errors: [],
    rowState: 0,
    Branch_ID: 0,
    Code: "",
    CreationDate: new Date(),
    IsDefault: false,
    Phone: "",
    PrinterName: "",
    VerifyOnUpdate: false,
  };
  try {
    apiResponse = await AlYusrAxiosApiInstance.post(
      AlYuserApiUrls.itemController.saveInventoryChecking,
      { ...request }
    );
    return apiResponse;
  } catch (err) {
    alert(err);
  }
  return apiResponse;
};
export const updateTaxPercentageOfCategory = async (
  request: UpdateCategoryTaxRequestModel
): Promise<CategoryDeleteResponseModel> => {
  let apiResponse: CategoryDeleteResponseModel = {
    Errors: [],
    Result: {
      Result: false,
      Errors: [],
    },
    Status: 0,
  };
  try {
    apiResponse = await AlYusrAxiosApiInstance.post(
      AlYuserApiUrls.itemController.UpdateTaxPercentageOfCategory,
      { ...request }
    );

    return apiResponse;
  } catch (err) {
    alert(err);
  }
  return apiResponse;
};
export const saveItem = async (
  request: ItemModel
): Promise<ResponseBaseModel<SearchItemResponseModel>> => {
  let response: ResponseBaseModel<SearchItemResponseModel> = {};
  const postResult: ResponseBaseModel<SearchItemResponseModel> =
    await AlYusrAxiosApiInstance.post(
      AlYuserApiUrls.itemController.saveItemFull,
      request
    );
  response.Errors = postResult.Errors;
  return response;
};
export const deleteItem = async (
  id: number
): Promise<ResponseBaseModel<deleteItemResponseModel>> => {
  let response: ResponseBaseModel<deleteItemResponseModel>;
  response = await AlYusrAxiosApiInstance.post(
    `${AlYuserApiUrls.itemController.deleteItem}?id=${id}`
  );
  return response;
};
//#endregion
