import React, {FC} from "react";
import {
    LookupTypeEnum,
    SearchPanelPropsModel, SalesInGraphReportSearchParamsModel
} from "../../../models";
import {Form} from "antd";
import {getLabelName} from "../../../utils";
import {useLookups} from "../../../hooks";
import {ButtonBox, PrivilegesChecker, SelectBox, InputDatePicker} from "../..";

export const ReportSalesInGraphSearchPanel: FC<
    SearchPanelPropsModel<SalesInGraphReportSearchParamsModel>
    // SearchPanelProps<ReportSalesInGraphSearchParamsString>
> = ({
         setSearchParams,
         handelSearch,
         searchParams,
         setLoading, formId,
     }) => {
    //#region state
    const {getLookupItems} = useLookups(setLoading, [LookupTypeEnum.Users]);
    //#endregion
    //#region html
    return (
        <>
            <Form onFinish={handelSearch}>
                <div
                    className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                    <Form.Item
                        name="fromDate"
                        rules={[{required: true, message: getLabelName("required")}]}
                    >
                        <InputDatePicker
                            selectedDate={searchParams.fromDate}
                            isClearable
                            InputLabel={"From Date"}
                            className="form-control"
                            selectsStart
                            startDate={searchParams.fromDate}
                            endDate={searchParams.toDate}
                            onChange={(date) => {
                                const obj = {...searchParams};
                                obj.fromDate = date === null ? undefined : date;
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="toDate"
                        rules={[{required: true, message: getLabelName("required")}]}
                    >
                        <InputDatePicker
                            selectedDate={searchParams.toDate}
                            isClearable
                            InputLabel={"To Date"}
                            className="form-control"
                            selectsEnd
                            startDate={searchParams.fromDate}
                            endDate={searchParams.toDate}
                            minDate={searchParams.fromDate}
                            onChange={(date) => {
                                const obj = {...searchParams};
                                obj.toDate = date === null ? undefined : date;
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>

                    <SelectBox
                        labelName="User"
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.userId ? [searchParams.userId.toString()] : [""]
                        }
                        source={getLookupItems(LookupTypeEnum.Users, true)}
                        onStatusChange={(e: any) => {
                            let obj = {...searchParams};
                            if (e && obj) {
                                obj.userId = parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                </div>
                <PrivilegesChecker formId={formId} action="EnableSearch">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                        <ButtonBox
                            iconType="magnify"
                            className="btn-gradient-primary mx-3 btn-fw"
                            variant="outline-primary"
                            type="submit"
                        >
                            {getLabelName("Search")}
                        </ButtonBox>
                    </div>
                </PrivilegesChecker>
            </Form>
            {/* </Card.Body>
      </Card> */}
        </>
    );
    //#endregion
};
