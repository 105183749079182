import React, { FC, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import {
  AcBeginBalanceList,
  AddAcBeginBalance,
  ConfirmModelDialogBox,
  LoadingBox,
  ToastBox,
} from "../../components";
import {
  AcBeginBalanceTransactionDetailModel,
  AcBeginBalanceTransactionDetailRegisterModel,
  AccountAcBeginBalanceTypeEnum,
  ActionButtonsModel,
  ActionTypeEnum,
  PageEnum,
  RequestActionModel,
  ToastModel,
} from "../../models";
import {
  getLabelName,
  getPageNameByPageId,
  getUserId,
  isArabicCurrentLanguage,
} from "../../utils";
import {
  deleteBeginBalanceAcTransaction,
  saveBeginBalanceAcTransaction,
} from "../../serviceBroker/acTransactionsApiServiceBroker";

export const AcBeginBalancePage: FC<{ formId: number }> = ({
  formId = PageEnum.ACBeginBalance,
}) => {
  //#region state
  const [loading, setLoading] = useState(false);
  const [showToastModel, setShowToastModel] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [object, setObject] =
    useState<AcBeginBalanceTransactionDetailModel | null>(null);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [pageName, setPageName] = useState<string | null>(null);
  const [toastModel, setToastModel] = useState<ToastModel>({ show: false });
  //#endregion
  //#region useEffect
  useEffect(() => {
    if (formId !== 0 && formId !== null && formId !== undefined) {
      setPageName(getPageNameByPageId(formId));
    }
  }, [isArabicCurrentLanguage()]);
  //#endregion
  //#region function
  const deleteActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleAction({
          id: object?.ID || 0,
          // request: object,
          action: ActionTypeEnum.DeleteOperationStart,
        });
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setObject(null);
        setShowDeleteModel(false);
      },
    },
  ];
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
      case ActionTypeEnum.Success:
        setLoading(true);
        setObject(null);
        setIsRefresh(true);
        setToastModel({
          ...toastModel,
          variant: "success",
          show: true,
        });
        setShowToastModel(true);
        setLoading(false);
        break;
      case ActionTypeEnum.Clear:
        setLoading(true);
        setObject(null);
        setIsRefresh(true);
        setLoading(false);
        break;
      case ActionTypeEnum.Delete:
        setLoading(true);
        //const deleteObject = objects.filter((p) => p.ID === request.id)[0];
        setObject(request.request);
        setShowDeleteModel(true);
        setLoading(false);
        break;
      case ActionTypeEnum.DeleteOperationStart:
        setLoading(true);
        setShowDeleteModel(false);
        const result = await deleteBeginBalanceAcTransaction(
          Number(request.id)
        );
        if (result.Result?.Result === true) {
          await handleAction({ id: 0, action: ActionTypeEnum.Success });
        } else {
          await handleAction({ id: 0, action: ActionTypeEnum.Failed });
        }
        setLoading(false);
        break;
      case ActionTypeEnum.BulkUpdate:
        const requestList: AcBeginBalanceTransactionDetailModel[] =
          request.request as AcBeginBalanceTransactionDetailModel[];
        if (requestList !== null && requestList.length !== 0) {
          setLoading(true);
          for (const row of requestList) {
            const submitRequestObject: AcBeginBalanceTransactionDetailRegisterModel =
              {
                ID: row.ID,
                Account_ID: row.Account_ID,
                Value:
                  row.accountNatureType ==
                  Number(AccountAcBeginBalanceTypeEnum.CreditAccount)
                    ? row.CreditValue
                    : row.DebitValue,
                UserId: getUserId(),
              };
            await saveBeginBalanceAcTransaction(submitRequestObject);
          }
          setLoading(false);
          setIsRefresh(true);
        }
        break;
      case ActionTypeEnum.Failed:
        setToastModel({
          ...toastModel,
          body: request.request,
          variant: "danger",
          show: true,
        });
        setShowToastModel(true);
        setObject(request.requestBeforeError);
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {showToastModel && (
        <ToastBox
          isShown={showToastModel}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setShowToastModel(false);
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{pageName}</Accordion.Header>
          <Accordion.Body>
            <AddAcBeginBalance
              onActionEvent={async (o: RequestActionModel) => {
                await handleAction(o);
              }}
              request={object}
              formId={formId}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body>
            <Card className="card-custom">
              {/* <Card.Header></Card.Header> */}
              <Card.Body>
                <ConfirmModelDialogBox
                  isModelVisible={showDeleteModel}
                  onCloseEvent={() => {
                    setShowDeleteModel(false);
                  }}
                  actions={deleteActions}
                >
                  <div className="alert alert-warning">
                    {getLabelName("Are You Sure You Want Delete ")}
                  </div>
                </ConfirmModelDialogBox>

                <AcBeginBalanceList
                  isRefresh={isRefresh}
                  setIsRefresh={setIsRefresh}
                  onActionEvent={async (o: RequestActionModel) => {
                    await handleAction(o);
                  }}
                  formId={formId}
                />
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
