import React, { FC, useEffect, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  getLabelName,
  getLookUpItemNumericValue,
  getOriginalValueInCurrency,
  getUserId,
} from "../../utils";
import { useFormik } from "formik";
import {
  ActionTypeEnum,
  AcTransactionModel,
  AcTransactionTypeEnum,
  HasFormIdModel,
  LookupItemModel,
  RequestActionModel,
  ResponseBaseModel,
  RowStateEnum,
  TransactionStatusEnum,
} from "../../models";
import {
  ButtonBox,
  InputDatePicker,
  LabelBox,
  PrivilegesChecker,
  SelectBox,
  TextBox,
} from "..";
import { SaveAcTransaction } from "../../serviceBroker/acTransactionsApiServiceBroker";
import {
  getAccountBalance,
  getPageNameBasedOnTransactionType,
} from "./businessLogic/acTransactionBl";

interface AcTransactionFormProps extends HasFormIdModel {
  acTransactionType: AcTransactionTypeEnum;
  request: AcTransactionModel;
  setLoading: Function;
  accountList: LookupItemModel[];
  currencyList: LookupItemModel[];
  coastCenterList: LookupItemModel[];
  onActionEvent: (o: RequestActionModel) => void;
}

export const AcTransactionForm: FC<AcTransactionFormProps> = ({
  acTransactionType,
  request,
  setLoading,
  accountList,
  currencyList,
  coastCenterList,
  onActionEvent = () => {},
  formId,
}) => {
  //#region variables
  const initialValues: AcTransactionModel = request;
  const fromAccountTypeList: AcTransactionTypeEnum[] = [
    AcTransactionTypeEnum.MoneyIn,
    AcTransactionTypeEnum.CustomerPayment,
    AcTransactionTypeEnum.RefundSupplierPayments,
  ];
  const toAccountTypeList: AcTransactionTypeEnum[] = [
    AcTransactionTypeEnum.MoneyOut,
    AcTransactionTypeEnum.RefundCustomerPayments,
    AcTransactionTypeEnum.SupplierPayments,
  ];
  //#endregion
  //#region state
  const currencySelectBoxMultiselectRef = useRef<any>();
  const costCenterSelectBoxMultiselectRef = useRef<any>();
  const fromAccountSelectBoxMultiselectRef = useRef<any>();
  const toAccountSelectBoxMultiselectRef = useRef<any>();
  const [validationSchema] = useState();
  const [pageName, setPageName] = useState<string | null>(null);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      await formik.setValues({ ...(await initializeValues(request)) });
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      await formik.setValues({ ...(await initializeValues(request)) });
      setLoading(false);
    };
    fillData().then(() => {});
  }, [request]);
  useEffect(() => {
    setPageName(
      getLabelName(getPageNameBasedOnTransactionType(acTransactionType))
    );
  }, [acTransactionType]);
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validate: (values: AcTransactionModel): any => {
      const errors: any = {};
      if (
        values.FromAccount_ID === null ||
        values.FromAccount_ID === undefined ||
        values.FromAccount_ID === 0
      ) {
        errors.FromAccount_ID = getLabelName("required");
      }
      if (
        values.ToAccount_ID === null ||
        values.ToAccount_ID === undefined ||
        values.ToAccount_ID === 0
      ) {
        errors.ToAccount_ID = getLabelName("required");
      }
      if (
        values.CostCenter_ID === null ||
        values.CostCenter_ID === undefined ||
        values.CostCenter_ID === 0
      ) {
        errors.CostCenter_ID = getLabelName("required");
      }
      if (
        values.Currency_ID === null ||
        values.Currency_ID === undefined ||
        values.Currency_ID === 0
      ) {
        errors.Currency_ID = getLabelName("required");
      }
      if (
        values.Value === null ||
        values.Value === undefined ||
        values.Value === 0
      ) {
        errors.Value = getLabelName("required");
      }
      return errors;
    },
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const response: ResponseBaseModel<AcTransactionModel> =
        await handleSubmit(values);
      onActionEvent({
        request: response,
        action:
          response.status === Number(TransactionStatusEnum.Success)
            ? ActionTypeEnum.Success
            : ActionTypeEnum.Failed,
      });
      switch (response.status) {
        case Number(TransactionStatusEnum.Success):
          await handleResetForm();
          break;
      }
    },
  });
  const handleResetForm = async () => {
    currencySelectBoxMultiselectRef.current.clearValue();
    costCenterSelectBoxMultiselectRef.current.clearValue();
    if (
      currencySelectBoxMultiselectRef.current !== null &&
      currencySelectBoxMultiselectRef.current !== undefined
    ) {
      fromAccountSelectBoxMultiselectRef.current.clearValue();
    }
    if (
      toAccountSelectBoxMultiselectRef.current !== null &&
      toAccountSelectBoxMultiselectRef.current !== undefined
    ) {
      toAccountSelectBoxMultiselectRef.current.clearValue();
    }
    await formik.setValues({ ...(await initializeValues(request)) });
    await formik.setTouched({});
    formik.setErrors({});
  };
  //#endregion
  //#region functions
  const initializeValues = async (
    acTransactionRequest: AcTransactionModel
  ): Promise<AcTransactionModel> => {
    if (acTransactionRequest.isNewRequest) {
      const currencyId: number =
        currencyList !== null &&
        currencyList !== undefined &&
        currencyList.length !== 0
          ? Number(currencyList[0].value)
          : 0;
      const costCenterId: number =
        coastCenterList !== null &&
        coastCenterList !== undefined &&
        coastCenterList.length !== 0
          ? Number(coastCenterList[0].value)
          : 0;
      const accountId: number =
        accountList !== null &&
        accountList !== undefined &&
        accountList.length !== 0
          ? Number(accountList[0].value)
          : 0;
      if (fromAccountTypeList.includes(acTransactionType)) {
        acTransactionRequest.FromAccount_ID = accountId;
      }
      if (toAccountTypeList.includes(acTransactionType)) {
        acTransactionRequest.ToAccount_ID = accountId;
      }
      acTransactionRequest.Currency_ID = currencyId;
      acTransactionRequest.CostCenter_ID = costCenterId;
      acTransactionRequest.balance = await getAccountBalance(
        accountId,
        acTransactionRequest.Currency_ID
      );
    }
    return acTransactionRequest;
  };
  const handleSubmit = async (
    request: AcTransactionModel
  ): Promise<ResponseBaseModel<AcTransactionModel>> => {
    let response: ResponseBaseModel<AcTransactionModel> = {
      Result: undefined,
      Errors: [],
      status: Number(TransactionStatusEnum.Failed),
    };
    try {
      setLoading(true);
      request.rowState =
        request.ID === 0 ? Number(RowStateEnum.Add) : request.rowState;
      request.CreatedBy = request.ID === 0 ? getUserId() : request.CreatedBy;
      request.rowState =
        request.ID !== 0 ? Number(RowStateEnum.Update) : request.rowState;
      request.ModifiedBy = request.ID !== 0 ? getUserId() : request.ModifiedBy;
      const currencyObject: LookupItemModel = currencyList.filter(
        (p) => p.value === request.Currency_ID.toString()
      )[0];
      request.Value =
        currencyObject !== null && currencyObject !== undefined
          ? getOriginalValueInCurrency(
              request.Value,
              currencyObject.otherValue.ValueEntity
            )
          : request.Value;
      response = await SaveAcTransaction(request);
      response.status =
        response?.Errors === null || response?.Errors?.length === 0
          ? Number(TransactionStatusEnum.Success)
          : Number(TransactionStatusEnum.Failed);
      setLoading(false);
      return response;
    } catch (err: any) {
      setLoading(false);
      response.Errors = [{ MessageAr: err, MessageEn: err }];
    }
    return response;
  };
  //#endregion
  //#region html
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>{pageName}</Accordion.Header>
            <Accordion.Body>
              <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
                <LabelBox
                  id="codeLabel"
                  labelName={getLabelName("Code")}
                  inputValue={formik.values.ID}
                />
                {fromAccountTypeList.includes(acTransactionType) && (
                  <SelectBox
                    id="fromAccountSelectBox"
                    labelName={getLabelName("Account")}
                    isSingleSelect={true}
                    selectedValues={
                      formik.values.FromAccount_ID !== null &&
                      formik.values.FromAccount_ID !== undefined
                        ? [formik.values.FromAccount_ID.toString()]
                        : []
                    }
                    source={accountList}
                    onStatusChange={async (e: any) => {
                      const value = getLookUpItemNumericValue(e);
                      if (value !== null) {
                        const balance = await getAccountBalance(
                          value,
                          formik.values.Currency_ID
                        );

                        formik.values.FromAccount_ID = value;
                        formik.values.balance = balance;
                        await formik.setFieldValue("FromAccount_ID", value);
                        await formik.setFieldValue("balance", balance);
                      }
                    }}
                    multiselectRef={fromAccountSelectBoxMultiselectRef}
                    errorText={formik.errors.FromAccount_ID}
                  />
                )}
                {toAccountTypeList.includes(acTransactionType) && (
                  <SelectBox
                    id="toAccountSelectBox"
                    labelName={getLabelName("Account")}
                    isSingleSelect={true}
                    selectedValues={
                      formik.values.ToAccount_ID !== null &&
                      formik.values.ToAccount_ID !== undefined
                        ? [formik.values.ToAccount_ID.toString()]
                        : []
                    }
                    source={accountList}
                    onStatusChange={async (e: any) => {
                      const value = getLookUpItemNumericValue(e);
                      if (value !== null) {
                        const balance = await getAccountBalance(
                          value,
                          formik.values.Currency_ID
                        );

                        formik.values.ToAccount_ID = value;
                        formik.values.balance = balance;
                        await formik.setFieldValue("ToAccount_ID", value);
                        await formik.setFieldValue("balance", balance);
                      }
                    }}
                    errorText={formik.errors.ToAccount_ID}
                    multiselectRef={toAccountSelectBoxMultiselectRef}
                  />
                )}
                <LabelBox
                  labelName={getLabelName("Balance")}
                  inputValue={formik.values.balance}
                />
                {currencyList.length > 1 && (
                  <SelectBox
                    id="currencySelectBox"
                    labelName={getLabelName("Currency")}
                    isSingleSelect={true}
                    selectedValues={
                      formik.values.Currency_ID !== null &&
                      formik.values.Currency_ID !== undefined
                        ? [formik.values.Currency_ID.toString()]
                        : []
                    }
                    source={currencyList}
                    onStatusChange={(e: any) => {
                      if (e !== null && e.value !== null) {
                        formik.values.Currency_ID = e.value;
                        formik.setFieldValue("Currency_ID", e.value);
                      }
                    }}
                    multiselectRef={currencySelectBoxMultiselectRef}
                    errorText={formik.errors.Currency_ID}
                  />
                )}
                <SelectBox
                  id="costCenterSelectBox"
                  labelName={getLabelName("cmbxCostCenter")}
                  isSingleSelect={true}
                  selectedValues={
                    formik.values.CostCenter_ID !== null &&
                    formik.values.CostCenter_ID !== undefined
                      ? [formik.values.CostCenter_ID.toString()]
                      : []
                  }
                  source={coastCenterList}
                  onStatusChange={(e: any) => {
                    if (e !== null && e.value !== null) {
                      formik.values.CostCenter_ID = e.value;
                      formik.setFieldValue("CostCenter_ID", e.value);
                    }
                  }}
                  errorText={formik.errors.CostCenter_ID}
                  multiselectRef={costCenterSelectBoxMultiselectRef}
                />
                <TextBox
                  inputName={"Value"}
                  labelName="Value"
                  errorText={formik.errors.Value}
                  inputValue={formik.values.Value}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <InputDatePicker
                  id="datePicker"
                  selectedDate={new Date(formik.values.Date.toString())}
                  className="form-control"
                  InputLabel="Date"
                  name="Date"
                  onChange={(date: Date) => {
                    formik.values.Date = date;
                    formik.setFieldValue("Date", date);
                  }}
                  maxDate={new Date()}
                />
              </div>
              <div className="row row-cols-1 row-cols-xxl-12 row-cols-xl-12 row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-md-12 g-sm-12">
                <TextBox
                  inputName={"Note"}
                  labelName="Note"
                  errorText={formik.errors.Note}
                  type="textarea"
                  inputValue={formik.values.Note}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div className="row mt-3">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                  <PrivilegesChecker
                    formId={formId}
                    action={request.ID ? "EnableUpdate" : "EnableSave"}
                  >
                    <ButtonBox
                      type="submit"
                      iconType="content-save"
                      className="btn-gradient-primary mx-3 btn-fw"
                    >
                      {getLabelName(request.ID ? "Update" : "Save")}
                    </ButtonBox>
                  </PrivilegesChecker>
                  <ButtonBox
                    variant="danger"
                    type="button"
                    iconType="receipt"
                    className="btn-fw"
                    onClick={async () => {
                      onActionEvent({ action: ActionTypeEnum.Clear });
                      await handleResetForm();
                    }}
                  >
                    {getLabelName("New")}
                  </ButtonBox>
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </form>
    </>
  );
  //#endregion
};
