import React, {FC} from "react";
import {
    LookupItemModel,
    LookupTypeEnum,
    SearchPanelPropsModel, DebitCustomerReportSearchParamsModel
} from "../../../models";
import {Form} from "react-bootstrap";
import {getLabelName} from "../../../utils";
import {useLookups} from "../../../hooks";
import {ButtonBox, PrivilegesChecker, SelectBox} from "../..";

export const ReportDebitSupplierSearchPanel: FC<
    SearchPanelPropsModel<DebitCustomerReportSearchParamsModel>
> = ({
         setLoading,
         handelSearch,
         searchParams,
         setSearchParams, formId,
     }) => {
    //#region state
    const {getLookupItems} = useLookups(setLoading, [
        LookupTypeEnum.Supplier,
        LookupTypeEnum.Cities,
    ]);
    const suppliersDataSource = getLookupItems(LookupTypeEnum.Supplier, true);
//#endregion
    //#region html
    return (
        <>
            <Form>
                <div
                    className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                    <SelectBox
                        labelName={getLabelName("Supplier")}
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.customerId
                                ? [searchParams.customerId.toString()]
                                : [""]
                        }
                        source={suppliersDataSource}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.customerId = undefined;
                            } else {
                                obj.customerId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                    <SelectBox
                        labelName={getLabelName("City")}
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.cityId ? [searchParams.cityId.toString()] : [""]
                        }
                        source={getLookupItems(LookupTypeEnum.Cities, true)}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.cityId = undefined;
                            } else {
                                obj.cityId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                </div>
                <PrivilegesChecker formId={formId} action="EnableSearch">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                        <ButtonBox
                            iconType="magnify"
                            className="btn-gradient-primary mx-3 btn-fw"
                            variant="outline-primary"
                            onClick={handelSearch}
                        >
                            {getLabelName("Search")}
                        </ButtonBox>
                    </div>
                </PrivilegesChecker>
            </Form>
        </>
    );
    //#endregion
};
