import {TableColumn} from "react-data-table-component";
import {getLabelName} from "../../../utils";
import {
    AccountAcBeginBalanceTypeEnum,
    TransactionRowDataModel,
} from "../../../models";
import {TextBox, ButtonBox} from "../..";

interface TransactionHandlers {
    handleDeletion: (id: string) => void;
    handleAmountChange: (id: string, enteredValue: number) => void;
}

const setCurrentTransactionColumns: (
    handlers: TransactionHandlers
) => TableColumn<TransactionRowDataModel>[] = ({
                                              handleDeletion,
                                              handleAmountChange,
                                          }) => {
    const handleInputChange = (event: any, id: string) => {
        const enteredValue = event.target.value;
        if (+enteredValue < 1) return;
        handleAmountChange(id, enteredValue);
    };
    const currentTransactionColumns: TableColumn<TransactionRowDataModel>[] = [
        {
            name: getLabelName("To Account"),
            selector: (row) => getLabelName(row.toAccount?.label || ""),
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("value out"),
            id: "total_debit_column",
            width: "100px",
            cell: ({amount, valueType, id}) => (
                <TextBox
                    inputValue={
                        valueType === AccountAcBeginBalanceTypeEnum.DebitAccount
                            ? amount
                            : "0"
                    }
                    labelName=""
                    inputName="creditValue"
                    type="number"
                    isReadOnly={valueType !== AccountAcBeginBalanceTypeEnum.DebitAccount}
                    onChange={(value: any) => handleInputChange(value, id)}
                ></TextBox>
            ),
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("value in"),
            width: "100px",
            id: "total_credit_column",
            cell: ({amount, valueType, id}) => (
                <TextBox
                    inputValue={
                        valueType === AccountAcBeginBalanceTypeEnum.CreditAccount
                            ? amount
                            : "0"
                    }
                    labelName=""
                    inputName="creditValue"
                    type="number"
                    isReadOnly={valueType !== AccountAcBeginBalanceTypeEnum.CreditAccount}
                    onChange={(value: any) => handleInputChange(value, id)}
                ></TextBox>
            ),
            sortable: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("Note"),
            id: "note_column",
            cell: (row) => (
                <TextBox
                    inputName="note"
                    type={"text"}
                    // temp
                    isReadOnly={true}
                    labelName=""
                    inputValue={row.note}
                ></TextBox>
            ),
            sortable: true,
            //sortFunction: caseInsensitiveSort,
            //wrap: true,
            allowOverflow: true,
        },
        {
            button:true,
            // eslint-disable-next-line react/button-has-type
            cell: (row) => (
                <ButtonBox
                iconType="delete"
                    id="deleteBalanceButton"
                    variant="danger"
                    className="btn-xs"
                    onClick={() => handleDeletion(row.id)}
                >
                    {getLabelName("Delete")}
                </ButtonBox>
            ),
        },
    ];

    return currentTransactionColumns;
};

export default setCurrentTransactionColumns;
