import { Checkbox } from "antd";
import React from "react";

interface CheckboxProps {
  checked: boolean;
  onChange: any;
  id: string;
  labelName: string;
  inputName: string;
  onBlur?: any;
}

export const CheckBox: React.FC<CheckboxProps> = ({
  checked,
  id,
  inputName,
  labelName,
  onChange,
}) => {
  return (
    <div className="col">
      <label htmlFor={inputName} className="form-label" style={{ flex: 1 }}>
        {labelName}
      </label>
      <Checkbox
        checked={checked}
        name={inputName}
        onChange={onChange}
        id={id}
        style={{ marginRight: ".5rem", width: "auto" }}
      />
    </div>
  );
};
