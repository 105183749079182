import React, {FC, useState} from "react";
import {ActionTypeEnum, RequestActionModel, TransactionItemResponseModel} from "../../models";
import {getLabelName, isArabicCurrentLanguage} from "../../utils";
import { btnStyle} from "../transactions/common/css/posCss";
import {getPosItemList} from "../transactions/common/businessLogic/posTransactionBl";
import {ButtonBox, LoadingBox, TextBox} from "..";

export const ItemButtonList: FC<{ onActionEvent: (o: RequestActionModel) => void }> = ({
                                                                                      onActionEvent = () => {
                                                                                      }
                                                                                  }) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState<string | null>(null);
    const [items, setItems] = useState<TransactionItemResponseModel[]>([]);
    //#endregion
    //#region functions
    const handleSortAlphabetically = (list: TransactionItemResponseModel[], isArabic: boolean): TransactionItemResponseModel[] => {
        return list.slice().sort((a, b) => {
            const propertyName = isArabic ? "ItemName_En" : "ItemName";
            const nameA = a[propertyName].toUpperCase();
            const nameB = b[propertyName].toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    };
    const handleSearch = async (isClearSearch: boolean = false, searchText: string | null = null) => {
        setLoading(true);
        //searchText = (searchText && searchText.length < 3) ? null : searchText;
        let lowerQuery: string | null = !isClearSearch ? (searchText || filterText || "").toLowerCase() : "";
        lowerQuery = lowerQuery === "" ? null : lowerQuery;
        let responseResult = await getPosItemList(null, null, lowerQuery, null);
        responseResult = handleSortAlphabetically(responseResult, isArabic);
        setItems(responseResult);
        setLoading(false);
    }
    //#endregion
    //#region html
    return (<>
        {loading && <LoadingBox/>}
        <div
            className="col-6 d-flex justify-content-start mb-3"
        >
            <TextBox
                labelName={getLabelName("")} //{t("lookup.nameAr")}
                inputName={"filterTextBox"}
                inputValue={filterText}
                onChange={async (e: any) => {
                    const searchText: string | null = (e.target.value && e.target.value.length < 3) ? null : e.target.value;
                    setFilterText(e.target.value)
                    if (searchText !== null) {
                        await handleSearch(false, searchText);
                    }
                }}
            />
            <ButtonBox
            iconType="magnify"
                type="submit"
                className="btn-gradient-primary mx-3"
                variant=""
                onClick={async () => {
                    await handleSearch();
                }}
            >
                {getLabelName("Search")}
            </ButtonBox>
            <ButtonBox
            iconType="close-circle"
                type="button"
                className="btn-gradient-danger mx-3"
            variant="danger"
                onClick={async () => {
                    setFilterText('')
                    await handleSearch(true);
                }}
            >
                {getLabelName("Cancel")}
            </ButtonBox>
            <ButtonBox
            iconType="close-circle"
                type="button"
                className="btn-gradient-danger mx-3"
                variant=""
                onClick={async () => {
                    onActionEvent({
                        action: ActionTypeEnum.None
                    })
                }}
            >
                {getLabelName("Close")}
            </ButtonBox>
        </div>
        <ul className="list-group pos-list pos-list-item">
            {items !== null &&
                items.length !== 0 &&
                items.map((row, index) => (
                    <li className="list-group-item list-group-item-action p-1" key={index}>
                        <ButtonBox
                            key={`beneficiary_btn_${index}`}
                            variant="outline-success"
                            className="mb-3"
                            style={btnStyle}
                            id={`beneficiary_btn_${index}`}
                            onClick={() => {
                                onActionEvent({
                                    action: ActionTypeEnum.SelectItemResponse,
                                    request: row
                                })
                            }}
                        >
                            {isArabic ? row.ItemName : row.ItemName_En}
                        </ButtonBox>
                    </li>
                ))}
        </ul>
    </>)
    //#endregion
}