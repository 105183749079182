import { MasterLayout } from "../../components";
import { IRouteBase, LayoutEnum, PageEnum } from "../../models";
import { AuthenticatedRoute } from "../pipelines/authenticatedRoute";
import {
  AcBeginBalancePage,
  AccountsTreePage,
  AdditionAndRecommendationRestaurantLookupPage,
  AdditionCostsLookupPage,
  ApplicationDeliveryPage,
  BranchesPage,
  CalcTypePage,
  CarsColorsLookupPage,
  CarsServicesLookupPage,
  CarsTypesLookupPage,
  CategoriesPage,
  CitiesLookupPage,
  CloseDayPage,
  CompanySettingPage,
  CostCenterLookupPage,
  CountriesLookupPage,
  CurrencyPage,
  CurrentBranchPage,
  CustomerPage,
  CustomerPaymentPage,
  DailyBoxPage,
  DashboardPage,
  DictionaryPage,
  EmployeeDueRulesPage,
  EmployeePage,
  EmployeesStatusLookupPage,
  EmployeeSubtractRulesPage,
  EntitlementPage,
  EstihkakSubtractExpenseRulePage,
  EstihkakSubtractRulePage,
  ForbiddenPage,
  GradesLookupPage,
  HomePage,
  ItemsPage,
  JournalVoucherPage,
  MoneyIn,
  MoneyOut,
  NationalitiesLookupPage,
  NotFoundPage,
  OrdersStatusLookupPage,
  PaymentTypePage,
  PosPage,
  PosManagementPage,
  PurchasesInvoicePage,
  PurchasesRefundPage,
  RefundCustomerPaymentPage,
  RefundSupplierPaymentsPage,
  ReligionsLookupPage,
  ReportAllCustomersTransactions,
  ReportAllInAndOutMoneyPage,
  ReportAllSalesAndReturnTransactions,
  ReportAllSuppliersTransactions,
  ReportBalanceSheetPage,
  ReportBestLeastSellingItemsPage,
  ReportCashier,
  ReportCustomersData,
  ReportCustomersTransactionAndAcTransactionPage,
  ReportDebitCustomerPage,
  ReportDebitSupplierPage,
  ReportEditUserItemDataPage,
  ReportEmployeeEsthkakSubtractsPage,
  ReportEmployeeSales,
  ReportEmployeeSalesCommissionPage,
  ReportEmployeeSalesPage,
  ReportExpiryDateOfItemsPage,
  ReportFollowItemBalancePage,
  ReportGeneralLedgerPage,
  ReportInAndOutMoneyPage,
  ReportIncomeStatementPage,
  ReportInMoneyPage,
  ReportInventoryCheckingPage,
  ReportItemDataPage,
  ReportItemsDifferenceInCostPage,
  ReportItemsDifferentInVatCalculationPage,
  ReportItemsReachedToLimitOrderPage,
  ReportOrderStatusLogPage,
  ReportOutMoneyPage,
  ReportProductionItemsDataPage,
  ReportProfitOfSalePage,
  ReportProfitSalesInGraph,
  ReportPurchaseTransactions,
  ReportReturnPurchaseTransactions,
  ReportReturnSalesTransactions,
  ReportSalesAndPurchaseInGraph,
  ReportSalesTransactions,
  ReportsCustomersAccounts,
  ReportsCustomersPayments,
  ReportStagnantItemsPage,
  ReportStationsPage,
  ReportSummeryItemTransactionPage,
  ReportSummeryItemTransactionQuantityPage,
  ReportSummeryItemTransactionQuantityWithValuePage,
  ReportSuppliersAccount,
  ReportSuppliersData,
  ReportSuppliersPayments,
  ReportSuppliersTransactionAndAcTransactionPage,
  ReportVatPage,
  RequestOutPage,
  RestaurantPage,
  RestaurantPosPage,
  RuleExpenseMoneyPage,
  RuleReceiveMoneyPage,
  SalesInvoicePage,
  SalesRefundPage,
  ShowPricePage,
  SocialStatusLookupPage,
  StoreInventoryPage,
  StoresPage,
  StoreTransferPage,
  SupplierPage,
  SupplierPaymentsPage,
  UnAuthorizedPage,
  UnitPage,
  UpdateTaxCategoryPage,
  UserPermissionsPage,
  UsersPage,
  ReportPosManagementPage,
  ReportPosSalesRefundPage,
  ReportPosSalesRestaurantPage,
  ReportPosSalesRestaurantRefundPage, ReportOrderPage,
} from "../../pages";
import { CurrentBranchRoute } from "../pipelines/currentBranchRoute";
import { RoutePageConfigurations } from "../../configuration";
import {
  PosPurchasePage,
  PosRestaurantManagementPage,
  PosRestaurantReservationManagementPage, PosRestaurantReturnManagementPage,
  PosSalesRefundPage
} from "../../pages/transactions/pos/posPage";

export const DefaultRouteItems: IRouteBase[] = [
  //#region common pages
  {
    id: PageEnum.defaultPage,
    key: "defaultPage",
    path: RoutePageConfigurations.miscellaneous.defaultPage,
    content: (
      <MasterLayout>
        <HomePage />
      </MasterLayout>
    ),
  },
  {
    id: PageEnum.homePage,
    key: "homePage",
    path: RoutePageConfigurations.miscellaneous.homePage,
    content: (
      <MasterLayout>
        <HomePage />
      </MasterLayout>
    ),
  },
  {
    id: PageEnum.notFoundPage,
    key: "NotFoundPage",
    path: RoutePageConfigurations.miscellaneous.notFoundPage,
    content: (
      <MasterLayout>
        <NotFoundPage />
      </MasterLayout>
    ),
  },
  {
    id: PageEnum.unAuthorizedPage,
    key: "UnAuthorizedPage",
    path: RoutePageConfigurations.miscellaneous.unAuthorizedPage,
    content: (
      <MasterLayout>
        <UnAuthorizedPage />
      </MasterLayout>
    ),
  },
  {
    id: PageEnum.forbiddenPage,
    key: "forbiddenPage",
    path: RoutePageConfigurations.miscellaneous.forbiddenPage,
    content: (
      <MasterLayout>
        <ForbiddenPage />
      </MasterLayout>
    ),
  },
  {
    id: PageEnum.dashboardPage,
    key: "dashboardPage",
    path: RoutePageConfigurations.miscellaneous.dashboardPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <DashboardPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.logoutRedirectPage,
    key: "logoutRedirectPage",
    path: RoutePageConfigurations.miscellaneous.logoutRedirectPage,
    content: (
      <MasterLayout>
        <HomePage />
      </MasterLayout>
    ),
  },
  {
    id: PageEnum.Units,
    key: "unitsPage",
    path: RoutePageConfigurations.miscellaneous.unitPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <UnitPage formId={PageEnum.Units} />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region users pages
  {
    id: PageEnum.Users,
    key: "usersPage",
    path: RoutePageConfigurations.miscellaneous.userPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <UsersPage formId={PageEnum.Users} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.UsersPermission,
    key: "userPermissions",
    path: RoutePageConfigurations.miscellaneous.userPermissionsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <UserPermissionsPage formId={PageEnum.UsersPermission} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region setting pages
  {
    id: PageEnum.CompanySettings,
    key: "companySettingPage",
    path: RoutePageConfigurations.miscellaneous.companySettingPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CompanySettingPage formId={PageEnum.CompanySettings} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region items pages
  {
    id: PageEnum.Items,
    key: "items",
    path: RoutePageConfigurations.miscellaneous.itemPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ItemsPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region branches page
  {
    id: PageEnum.Branches,
    key: "Branches page",
    path: RoutePageConfigurations.miscellaneous.branchPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <BranchesPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.currentBranchPage,
    key: "currentBranchPage",
    path: RoutePageConfigurations.miscellaneous.currentBranchPage,
    content: (
      <AuthenticatedRoute>
        <MasterLayout layoutEnum={LayoutEnum.authenticatedWithoutMenuLayout}>
          <CurrentBranchPage />
        </MasterLayout>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region transactions pages
  {
    id: PageEnum.SalesInvoice,
    key: "Sales Invoice",
    path: RoutePageConfigurations.sales.salesInvoicePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <SalesInvoicePage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.PurchaseBill,
    key: "Purchases Invoice",
    path: RoutePageConfigurations.purchases.purchaseBillPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <PurchasesInvoicePage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReturnSalesBill,
    key: "Sales Returns",
    path: RoutePageConfigurations.sales.salesRefundPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <SalesRefundPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReturnPurchasesBill,
    key: "Purchases Returns",
    path: RoutePageConfigurations.purchases.purchasesRefundPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <PurchasesRefundPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.Requests,
    key: "Request Out",
    path: RoutePageConfigurations.transactions.requestOutPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <RequestOutPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ShowPrice,
    key: "Show Price",
    path: RoutePageConfigurations.transactions.showPricePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ShowPricePage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POS,
    key: "PosSales",
    path: RoutePageConfigurations.pos.posSalesPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <PosPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POSManagement,
    key: "posManagementPage",
    path: RoutePageConfigurations.pos.posManagementPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <PosManagementPage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.PurchaseViaPOS,
    key: "posPurchasePage",
    path: RoutePageConfigurations.pos.posPurchasePage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <PosPurchasePage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POSReturnManagement,
    key: "posSalesRefundPage",
    path: RoutePageConfigurations.pos.posSalesRefundPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <PosSalesRefundPage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POSRestaurant,
    key: "posRestaurantManagementPage",
    path: RoutePageConfigurations.pos.posRestaurantManagementPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <PosRestaurantManagementPage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POSRestaurantReservationManagement,
    key: "posRestaurantReservationManagementPage",
    path: RoutePageConfigurations.pos.posRestaurantReservationManagementPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <PosRestaurantReservationManagementPage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POSRestaurantReturnManagement,
    key: "posRestaurantReturnManagementPage",
    path: RoutePageConfigurations.pos.posRestaurantRefundManagementPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <PosRestaurantReturnManagementPage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POSRestaurant,
    key: "PosRestaurant",
    path: RoutePageConfigurations.pos.restaurantPosPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <RestaurantPosPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.MoveItemToStore,
    key: "StoreTransfer",
    path: RoutePageConfigurations.store.storeTransferPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <StoreTransferPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region money & payment
  {
    id: PageEnum.moneyOut,
    key: "MoneyOutPage",
    path: RoutePageConfigurations.transactions.moneyOutPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <MoneyOut />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.moneyIn,
    key: "MoneyIn",
    path: RoutePageConfigurations.transactions.moneyInPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <MoneyIn />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.employeeDue,
    key: "EmployeeDue",
    path: RoutePageConfigurations.employee.employeeDue,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <EntitlementPage formId={PageEnum.employeeDue} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.addSubtract,
    key: "EmployeeSubtract",
    path: RoutePageConfigurations.employee.employeeSubtract,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <EntitlementPage formId={PageEnum.addSubtract} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.SuppliersPayments,
    key: "SupplierPaymentsPage",
    path: RoutePageConfigurations.suppliers.supplierPaymentsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <SupplierPaymentsPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.CustomersPayments,
    key: "Customer Payment Page",
    path: RoutePageConfigurations.customers.customerPaymentPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CustomerPaymentPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.RefundSuppliersPayment,
    key: "RefundSupplierPaymentsPage",
    path: RoutePageConfigurations.suppliers.refundSupplierPaymentsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <RefundSupplierPaymentsPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.RefundCustomersPayment,
    key: "RefundCustomerPaymentPage",
    path: RoutePageConfigurations.customers.refundCustomerPaymentsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <RefundCustomerPaymentPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.AddTaxPercentageForItems,
    key: "updateTaxCategoryPage",
    path: RoutePageConfigurations.transactions.updateTaxCategoryPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <UpdateTaxCategoryPage formId={PageEnum.AddTaxPercentageForItems} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.RuleExport,
    key: "ruleExpenseMoneyPage",
    path: RoutePageConfigurations.transactions.ruleExpenseMoneyPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <RuleExpenseMoneyPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.RuleReceive,
    key: "RuleReceiveMoneyPage",
    path: RoutePageConfigurations.transactions.ruleReceiveMoneyPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <RuleReceiveMoneyPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: 0,
    key: "estihkakSubtractRulePage",
    path: RoutePageConfigurations.transactions.estihkakSubtractRulePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <EstihkakSubtractRulePage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: 0,
    key: "estihkakSubtractExpenseRulePage",
    path: RoutePageConfigurations.transactions.estihkakSubtractExpenseRulePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <EstihkakSubtractExpenseRulePage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion//
  //#region employee
  {
    id: PageEnum.Employees,
    key: "employees",
    path: RoutePageConfigurations.employee.employeePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <EmployeePage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.AdditionRules,
    key: "EmployeeDueRules",
    path: RoutePageConfigurations.employee.employeeDueRulesPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <EmployeeDueRulesPage formId={PageEnum.AdditionRules} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.RuleSubtract,
    key: "EmployeeSubtractRules",
    path: RoutePageConfigurations.employee.employeeSubtractRulesPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <EmployeeSubtractRulesPage formId={PageEnum.RuleSubtract} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region currency
  {
    id: PageEnum.Currency,
    key: "currency",
    path: RoutePageConfigurations.miscellaneous.currencyPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CurrencyPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region category
  {
    id: PageEnum.Categories,
    key: "",
    path: RoutePageConfigurations.miscellaneous.categoryPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CategoriesPage formId={PageEnum.Categories} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region customer
  {
    id: PageEnum.Customers,
    key: "customerPage",
    path: RoutePageConfigurations.customers.customerPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CustomerPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region supplier
  {
    id: PageEnum.Suppliers,
    key: "supplierPage",
    path: RoutePageConfigurations.suppliers.supplierPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <SupplierPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region store
  {
    id: PageEnum.Stores,
    key: "storePage",
    path: RoutePageConfigurations.store.storePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <StoresPage formId={PageEnum.Stores} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region inventory
  {
    id: PageEnum.InventoryCheck,
    key: "InventoryChecking",
    path: RoutePageConfigurations.inventory.inventoryCheckingPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <StoreInventoryPage pageId={PageEnum.InventoryCheck} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region lookup
  {
    id: PageEnum.Cities,
    key: "CitiesLookupPage",
    path: RoutePageConfigurations.miscellaneous.cityPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CitiesLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.Countries,
    key: "countriesLookupPage",
    path: RoutePageConfigurations.miscellaneous.countryPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CountriesLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.CostCenters,
    key: "CostCenterLookupPage",
    path: RoutePageConfigurations.miscellaneous.costCenterPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CostCenterLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.AdditionalRecommendOfItemsInRestaurant,
    key: "AdditionAndRecommendationRestaurantLookupPage",
    path: RoutePageConfigurations.miscellaneous.additionAndRecommendationPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <AdditionAndRecommendationRestaurantLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.Grade,
    key: "gradesLookupPage",
    path: RoutePageConfigurations.miscellaneous.gradePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <GradesLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.Nationalities,
    key: "NationalitiesLookupPage",
    path: RoutePageConfigurations.miscellaneous.nationalityPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <NationalitiesLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.Religions,
    key: "ReligionsLookupPage",
    path: RoutePageConfigurations.miscellaneous.religionPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReligionsLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.SocialStatus,
    key: "SocialStatusLookupPage",
    path: RoutePageConfigurations.miscellaneous.socialStatusPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <SocialStatusLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.EmployeeStatus,
    key: "Employee_StatusLookupPage",
    path: RoutePageConfigurations.employee.employeeStatusPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <EmployeesStatusLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ConsignOut,
    key: "OrderStatusLookupPage",
    path: RoutePageConfigurations.miscellaneous.orderStatusPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <OrdersStatusLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.CarsType,
    key: "Cars_TypeLookupPage",
    path: RoutePageConfigurations.miscellaneous.carTypePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CarsTypesLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.CarsColors,
    key: "Cars_ColorLookupPage",
    path: RoutePageConfigurations.miscellaneous.carColorPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CarsColorsLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.CarService,
    key: "Cars_ServiceLookupPage",
    path: RoutePageConfigurations.miscellaneous.carServicePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CarsServicesLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.AdditionCosts,
    key: "AdditionCostsLookupPage",
    path: RoutePageConfigurations.miscellaneous.costAdditionalPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <AdditionCostsLookupPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region finance
  {
    id: PageEnum.JournalVoucher,
    key: "JournalVoucherPage",
    path: RoutePageConfigurations.finance.journalVoucherPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <JournalVoucherPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ACBeginBalance,
    key: "acBeginBalancePage",
    path: RoutePageConfigurations.finance.acBeginBalancePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <AcBeginBalancePage formId={PageEnum.ACBeginBalance} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.AccountsTree,
    key: "AccountTreePage",
    path: RoutePageConfigurations.finance.accountTreePage,
    content: (
      // Temp
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <AccountsTreePage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region others
  {
    id: PageEnum.POSStation,
    key: "CloseDayPage",
    path: RoutePageConfigurations.transactions.closeDayPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CloseDayPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.DailyBox,
    key: "AcDailyBoxPage",
    path: RoutePageConfigurations.transactions.acDailyBoxPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <DailyBoxPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.CalcType,
    key: "calcType",
    path: RoutePageConfigurations.miscellaneous.calcTypePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <CalcTypePage formId={PageEnum.CalcType} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ApplicationDelivery,
    key: "ApplicationDeliveryPage",
    path: RoutePageConfigurations.miscellaneous.applicationDeliveryPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ApplicationDeliveryPage formId={PageEnum.ApplicationDelivery} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.PaymentType,
    key: "paymentType",
    path: RoutePageConfigurations.finance.paymentTypePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <PaymentTypePage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.Dictionary,
    key: "Dictionary",
    path: RoutePageConfigurations.miscellaneous.dictionaryPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <DictionaryPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.Tables,
    key: "Restaurant",
    path: RoutePageConfigurations.miscellaneous.restaurantTablePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <RestaurantPage formId={PageEnum.Tables} />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
  //#region reports
  {
    id: PageEnum.ReportFollowItemsBalance,
    key: "reportFollowItemBalancePage",
    path: RoutePageConfigurations.reports.reportFollowItemBalancePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportFollowItemBalancePage
              formId={PageEnum.ReportFollowItemsBalance}
            />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ItemsReachedToLimitOrder,
    key: "ItemsReachedToLimitOrderPage",
    path: RoutePageConfigurations.reports.reportItemsReachedToLimitOrderPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportItemsReachedToLimitOrderPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportBestAndLeastSellingItems,
    key: "reportBestLeastSellingItemsPage",
    path: RoutePageConfigurations.reports.reportBestLeastSellingItemsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportBestLeastSellingItemsPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportExpiryDateOfItems,
    key: "reportExpiryDateOfItemsPage",
    path: RoutePageConfigurations.reports.reportExpiryDateOfItemsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportExpiryDateOfItemsPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportSalesAndPurchaseGraph,
    key: "ReportSalesAndPurchaseInGraph",
    path: RoutePageConfigurations.reports.reportSalesAndPurchaseInGraphPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportSalesAndPurchaseInGraph />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportProfitOfSalesGraph,
    key: "ReportProfitSalesInGraph",
    path: RoutePageConfigurations.reports.reportProfitSalesInGraphPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportProfitSalesInGraph />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportAdditionAndSubtractsOfEmp,
    key: "reportEmployeeEsthkakSubtractsPage",
    path: RoutePageConfigurations.reports.reportEmployeeEsthkakSubtractsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportEmployeeEsthkakSubtractsPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportItemDifferentInVatCalculation,
    key: "reportItemsDifferentInVatCalculationPage",
    path: RoutePageConfigurations.reports.reportItemsDifferentInVatCalculationPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportItemsDifferentInVatCalculationPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportEmployeeCommissionFromSales,
    key: "ReportEmployeeSalesCommissionPage",
    path: RoutePageConfigurations.reports.reportEmployeeSalesCommissionPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportEmployeeSalesCommissionPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportEmployeeSales,
    key: "reportEmployeeSalesPage",
    path: RoutePageConfigurations.reports.reportEmployeeSalesPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportEmployeeSalesPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportStationOfPOS,
    key: "reportStationsPage",
    path: RoutePageConfigurations.reports.reportStationsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportStationsPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.OrderStatusSummery,
    key: "reportOrderStatusLogPage",
    path: RoutePageConfigurations.reports.reportOrderStatusLogPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportOrderStatusLogPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportCustomerDebits,
    key: "reportDebitCustomerPage",
    path: RoutePageConfigurations.reports.reportDebitCustomerPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportDebitCustomerPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportSupplierDebits,
    key: "reportDebitSupplierPage",
    path: RoutePageConfigurations.reports.reportDebitSupplierPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportDebitSupplierPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportAllCustomerTransaction,
    key: "reportCustomersTransactionAndAcTransactionPage",
    path: RoutePageConfigurations.reports.reportCustomersTransactionAndAcTransactionPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportCustomersTransactionAndAcTransactionPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportAllSuppliersTransactions,
    key: "reportSuppliersTransactionAndAcTransactionPage",
    path: RoutePageConfigurations.reports.reportSuppliersTransactionAndAcTransactionPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportSuppliersTransactionAndAcTransactionPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportAccountsTransaction,
    key: "reportGeneralLedgerPage",
    path: RoutePageConfigurations.reports.reportGeneralLedgerPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportGeneralLedgerPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.BalanceSheet,
    key: "reportBalanceSheetPage",
    path: RoutePageConfigurations.reports.reportBalanceSheetPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportBalanceSheetPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.IncomeStatement,
    key: "reportIncomeStatementPage",
    path: RoutePageConfigurations.reports.reportIncomeStatementPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportIncomeStatementPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportInventoryCheck,
    key: "reportInventoryCheckingPage",
    path: RoutePageConfigurations.reports.reportInventoryCheckingPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportInventoryCheckingPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportOfStagnantItems,
    key: "ReportOfStagnantItemsPage",
    path: RoutePageConfigurations.reports.reportStagnantItemsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportStagnantItemsPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportItemsData,
    key: "reportItemDataPage",
    path: RoutePageConfigurations.reports.reportItemDataPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportItemDataPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportItemsDifferentInCost,
    key: "reportItemsDifferenceInCostPage",
    path: RoutePageConfigurations.reports.reportItemsDifferenceInCostPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportItemsDifferenceInCostPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportProductionItemsData,
    key: "reportProductionItemsDataPage",
    path: RoutePageConfigurations.reports.reportProductionItemsDataPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportProductionItemsDataPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportModifyUsersOnItems,
    key: "reportEditUserItemDataPage",
    path: RoutePageConfigurations.reports.reportEditUserItemDataPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportEditUserItemDataPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportSummeryItemsTransaction,
    key: "reportSummeryItemTransactionPage",
    path: RoutePageConfigurations.reports.reportSummeryItemTransactionPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportSummeryItemTransactionPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportItemWithQuantity,
    key: "reportSummeryItemTransactionQuantityPage",
    path: RoutePageConfigurations.reports.reportSummeryItemTransactionQuantityPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportSummeryItemTransactionQuantityPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.frmReportItemsBalanceWithValue,
    key: "reportSummeryItemTransactionQuantityWithValuePage",
    path: RoutePageConfigurations.reports.reportSummeryItemTransactionQuantityWithValuePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportSummeryItemTransactionQuantityWithValuePage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportCustomersData,
    key: "ReportCustomersData",
    path: RoutePageConfigurations.reports.reportCustomersDataPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportCustomersData />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportCustomersPayments,
    key: "ReportsCustomersPayments",
    path: RoutePageConfigurations.reports.reportCustomersPaymentsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportsCustomersPayments />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportAccountOfCustomer,
    key: "ReportsCustomersAccounts",
    path: RoutePageConfigurations.reports.reportsCustomersAccountsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportsCustomersAccounts />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportFollowCustomersInTransaction,
    key: "ReportAllCustomersTransactions",
    path: RoutePageConfigurations.reports.reportAllCustomersTransactionsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportAllCustomersTransactions />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportSuppliersData,
    key: "ReportSuppliersData",
    path: RoutePageConfigurations.reports.reportSuppliersDataPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportSuppliersData />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportFollowSuppliersInTransaction,
    key: "ReportAllSuppliersTransactions",
    path: RoutePageConfigurations.reports.reportAllSuppliersTransactionsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportAllSuppliersTransactions />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportSuppliersPayment,
    key: "ReportSuppliersPayments",
    path: RoutePageConfigurations.reports.reportSuppliersPaymentsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportSuppliersPayments />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportAccountOfSupplier,
    key: "ReportSuppliersAccount",
    path: RoutePageConfigurations.reports.reportSuppliersAccountPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportSuppliersAccount />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportSales,
    key: "SalesTransactionsReport",
    path: RoutePageConfigurations.reports.reportSalesTransactionsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportSalesTransactions />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POSManagement,
    key: "posManagementReport",
    path: RoutePageConfigurations.reports.reportPosManagementPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <ReportPosManagementPage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POSReturnManagement,
    key: "pOSReturnManagementReport",
    path: RoutePageConfigurations.reports.reportPosSalesRefundPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <ReportPosSalesRefundPage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POSRestaurantManagement,
    key: "pOSRestaurantManagementReport",
    path: RoutePageConfigurations.reports.reportPosRestaurantManagementPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <ReportPosSalesRestaurantPage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.POSRestaurantReturnManagement,
    key: "pOSRestaurantReturnManagementReport",
    path: RoutePageConfigurations.reports.reportPosRestaurantReturnManagementPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <ReportPosSalesRestaurantRefundPage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportOrder,
    key: "orderReport",
    path: RoutePageConfigurations.reports.reportOrderPage,
    content: (
        <AuthenticatedRoute>
          <CurrentBranchRoute>
            <MasterLayout>
              <ReportOrderPage />
            </MasterLayout>
          </CurrentBranchRoute>
        </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportReturnSales,
    key: "ReturnSalesTransactionsReport",
    path: RoutePageConfigurations.reports.reportReturnSalesTransactionsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportReturnSalesTransactions />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportAllPOS,
    key: "AllSalesAndReturnTransactionsReport",
    path: RoutePageConfigurations.reports.reportAllSalesAndReturnTransactionsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportAllSalesAndReturnTransactions />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportPurchases,
    key: "PurchaseTransactionsReport",
    path: RoutePageConfigurations.reports.reportPurchaseTransactionsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportPurchaseTransactions />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportReturnPurchases,
    key: "ReturnPurchaseTransactionsReport",
    path: RoutePageConfigurations.reports.reportReturnPurchaseTransactionsPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportReturnPurchaseTransactions />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.frmReportPosTransaction,
    key: "ReportCashier",
    path: RoutePageConfigurations.reports.reportCashierPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportCashier />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportEmployeeSales,
    key: "ReportEmployeeSales",
    path: RoutePageConfigurations.reports.reportEmployeeSalesV2Page,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportEmployeeSales />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportEndorsementVAT,
    key: "ReportVatPage",
    path: RoutePageConfigurations.reports.reportVatPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportVatPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportProfitOfSales,
    key: "ReportProfitOfSalePage",
    path: RoutePageConfigurations.reports.reportProfitOfSalePage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportProfitOfSalePage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportInMoney,
    key: "ReportInMoneyPage",
    path: RoutePageConfigurations.reports.reportInMoneyPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportInMoneyPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportOutMoney,
    key: "ReportOutMoneyPage",
    path: RoutePageConfigurations.reports.reportOutMoneyPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportOutMoneyPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportInAndOutMoney,
    key: "ReportInAndOutMoneyPage",
    path: RoutePageConfigurations.reports.reportInAndOutMoneyPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportInAndOutMoneyPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  {
    id: PageEnum.ReportAllInAndOutDayMoney,
    key: "ReportAllInAndOutMoneyPage",
    path: RoutePageConfigurations.reports.reportAllInAndOutMoneyPage,
    content: (
      <AuthenticatedRoute>
        <CurrentBranchRoute>
          <MasterLayout>
            <ReportAllInAndOutMoneyPage />
          </MasterLayout>
        </CurrentBranchRoute>
      </AuthenticatedRoute>
    ),
  },
  //#endregion
];
