import React, {useEffect} from "react";
import {LayoutEnum} from "../../../models";
import {AuthenticatedWithoutMenuLayout, DefaultLayout} from "../../";
import {SecureLocalStorageGet, SecureLocalStorageSet} from "../../../utils";
import {SystemConfiguration} from "../../../configuration";
import {logoutUser} from "../../../slice/userAuthincateSlice";
import {useDispatch} from "react-redux";

type ContainerProps = {
    children: React.ReactNode;
    layoutEnum?: LayoutEnum; // Optional layout enum prop
};
export const MasterLayout = ({children, layoutEnum = LayoutEnum.defaultAuthenticatedLayout}: ContainerProps) => {

    //region state
    const dispatch = useDispatch();
    //#endregion
    //#region useEffect
    useEffect(() => {

        const checkForLogout = () => {
            const shouldLogout = SecureLocalStorageGet(SystemConfiguration.keys.forceLogoutAfterPrint);
            if (shouldLogout) {
                dispatch(
                    // @ts-ignore
                    logoutUser()
                );
            }
        };
        checkForLogout();
    },[dispatch ]);
//#endregion
    //#region functions
    const generateLayout = (): any => {
        //#region commented code
        // if (iLayoutConfiguration === null || iLayoutConfiguration === undefined) {
        //     iLayoutConfiguration = {layoutEnum: LayoutEnum.PublicLayout}
        //     const layoutFromStorage = LocalStorageGet(AppConfiguration.Setting().defaultLayoutStorageKey);
        //     iLayoutConfiguration.layoutEnum = layoutFromStorage !== null && layoutFromStorage !== undefined ? Number(layoutFromStorage) : iLayoutConfiguration.layoutEnum;
        // }
        // switch (iLayoutConfiguration.layoutEnum) {
        //case enums.LayoutEnum.None:
        //     case enums.LayoutEnum.PublicLayout:
        //         return <PublicLayout> {props}</PublicLayout>
        //         break;
        //     case enums.LayoutEnum.AdminLayout:
        //         return <AdminLayout> {props}</AdminLayout>
        //         break;
        //     default:
        //         return <Layout1> {props}</Layout1>
        //         break
        //
        // }
        //#endregion
        switch (layoutEnum) {
            case LayoutEnum.authenticatedWithoutMenuLayout:
                return <AuthenticatedWithoutMenuLayout> {children}</AuthenticatedWithoutMenuLayout>;
            default:
                return <DefaultLayout> {children}</DefaultLayout>;
        }
    };
    //#endregion
    //#region html
     return <>{generateLayout()}</>;
     //#endregion
};
