import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {PrivilegeModel} from "../../models";
import {getPagePrivileges, isUserAuthenticated} from "../../utils";
import {RoutePageConfigurations} from "../../configuration";

export const AuthenticatedRoute = ({
                                       children,
                                   }: {
    children: React.ReactNode;
}) => {
    let navigate = useNavigate();
    useEffect(() => {
        if (!isUserAuthenticated()) {
            navigate("/");
        } else {
            const pageName = window.location.pathname.toLowerCase();
            switch (pageName.toLowerCase()) {
                case RoutePageConfigurations.miscellaneous.dashboardPage.toLowerCase():
                case RoutePageConfigurations.miscellaneous.currentBranchPage.toLowerCase():
                    break;
                default:
                    const pagePrivilege: PrivilegeModel = getPagePrivileges();
                    if (!pagePrivilege.view) {
                        navigate(RoutePageConfigurations.miscellaneous.forbiddenPage);
                    }
                    break;
            }
        }
    }, [location.pathname.toLowerCase()]);
    return <>{children}</>;
};
