import React, {
    FC,
    useEffect,
    useState,
} from "react";
import {LookupItemModel, LookupTypeEnum, SearchPanelPropsModel, EmployeeSalesCommissionReportSearchParamsModel} from "../../../models";
import {Form} from "antd";
import {getLabelName} from "../../../utils";
import {InputDatePicker, SelectBox, TextBox, ButtonBox, PrivilegesChecker} from "../..";
import {getItemsByCategoryId} from "../../../serviceBroker/itemApiServiceBroker";
import {useLookups} from "../../../hooks";

export const ReportEmployeeSalesCommissionSearchPanel: FC<
    SearchPanelPropsModel<EmployeeSalesCommissionReportSearchParamsModel>
> = ({
         setLoading,
         searchParams,
         setSearchParams,
         handelSearch, formId,
     }) => {
    //#region state
    const {getLookupItems} = useLookups(setLoading, [
        LookupTypeEnum.ReportTypes,
        LookupTypeEnum.Customers,
        LookupTypeEnum.Users,
        LookupTypeEnum.Employee,
        LookupTypeEnum.CostCenters,
        LookupTypeEnum.Currency,
        LookupTypeEnum.AllCategories,
        LookupTypeEnum.DaysOfWeek,
        LookupTypeEnum.CalcType,
    ]);
    const [itemsLookUps, setItemsLookUps] = useState<LookupItemModel[]>([]);
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            const results = await getItemsByCategoryId(searchParams.categoryId);
            setItemsLookUps(results);
            setLoading(false);
        };
        fillData().then(() => {
        });
    }, [searchParams.categoryId]);
//#endregion
    //#region html
    return (
        <>
            {/* <Card>
        <CardHeader>
          <Row>
            <div className="col-md-6 d-flex ">{getLabelName(searchHeader)}</div>
          </Row>
        </CardHeader>
        <Card.Body> */}
            <Form onFinish={handelSearch}>
                <div
                    className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                    <Form.Item
                        name={""}
                        rules={[{required: true, message: getLabelName("required")}]}
                    >
                        <InputDatePicker
                            className="form-control"
                            selectedDate={searchParams.fromDate}
                            isClearable
                            InputLabel={"From Date"}
                            selectsStart
                            startDate={searchParams.fromDate}
                            endDate={searchParams.toDate}
                            onChange={(date) => {
                                const obj = {...searchParams};
                                obj.fromDate = date === null ? undefined : date;
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={""}
                        rules={[{required: true, message: getLabelName("required")}]}
                    >
                        <InputDatePicker
                            className="form-control"
                            selectedDate={searchParams.toDate}
                            isClearable
                            InputLabel={"To Date"}
                            selectsEnd
                            startDate={searchParams.fromDate}
                            endDate={searchParams.toDate}
                            minDate={searchParams.fromDate}
                            onChange={(date) => {
                                const obj = {...searchParams};
                                obj.toDate = date === null ? undefined : date;
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <SelectBox
                            labelName="Customer"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.custmerId
                                    ? [searchParams.custmerId.toString()]
                                    : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Customers, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = {...searchParams};
                                if (e == null) {
                                    obj.custmerId = undefined;
                                } else {
                                    obj.custmerId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <SelectBox
                            labelName="Cashier"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.userId ? [searchParams.userId.toString()] : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Users, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = {...searchParams};
                                if (e == null) {
                                    obj.userId = undefined;
                                } else {
                                    obj.userId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <SelectBox
                            labelName="Employee"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.employeeId
                                    ? [searchParams.employeeId.toString()]
                                    : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Employee, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = {...searchParams};
                                if (e == null) {
                                    obj.employeeId = undefined;
                                } else {
                                    obj.employeeId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <SelectBox
                            labelName="Calc Type"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.calcTypeId
                                    ? [searchParams.calcTypeId.toString()]
                                    : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.CalcType, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = {...searchParams};
                                if (e == null) {
                                    obj.calcTypeId = undefined;
                                } else {
                                    obj.calcTypeId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <SelectBox
                            labelName="Currency"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.currencyId
                                    ? [searchParams.currencyId.toString()]
                                    : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.Currency, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = {...searchParams};
                                if (e == null) {
                                    obj.currencyId = undefined;
                                } else {
                                    obj.currencyId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <SelectBox
                            labelName="Category"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.categoryId
                                    ? [searchParams.categoryId.toString()]
                                    : [""]
                            }
                            source={getLookupItems(LookupTypeEnum.AllCategories, true)}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = {...searchParams};
                                if (e == null) {
                                    obj.categoryId = undefined;
                                } else {
                                    obj.categoryId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                obj.itemId = undefined;
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <SelectBox
                            labelName="Items"
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.itemId ? [searchParams.itemId.toString()] : [""]
                            }
                            source={itemsLookUps}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = {...searchParams};
                                if (e == null) {
                                    obj.itemId = undefined;
                                } else {
                                    obj.itemId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                    <Form.Item>
                        <TextBox
                            inputName={"ItemCode"}
                            labelName="Item Code"
                            inputValue={searchParams.itemCode}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const obj = {...searchParams};
                                if (
                                    e.target.value === "" ||
                                    e.target.value === null ||
                                    e.target.value === undefined
                                ) {
                                    obj.itemCode = undefined;
                                } else {
                                    obj.itemCode = e.target.value;
                                }
                                setSearchParams(obj);
                            }}
                        />
                    </Form.Item>
                </div>
                <PrivilegesChecker formId={formId} action="EnableSearch">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                        <ButtonBox
                            iconType="magnify"
                            className="btn-gradient-primary mx-3 btn-fw"
                            variant="outline-primary"
                            type="submit"
                        >
                            {getLabelName("Search")}
                        </ButtonBox>
                    </div>
                </PrivilegesChecker>
            </Form>
            {/* </Card.Body> */}
            {/* </Card> */}
        </>
    );
    //#endregion
};
