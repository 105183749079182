import React, {useState} from "react";
import {Accordion} from "react-bootstrap";
import {getLabelName} from "../../utils";
import {BranchList, BranchForm, ToastBox} from "..";
import {ToastModel} from "../../models";
import {useBranchesData} from "../../hooks";

export interface BranchesChildComponent {
    currentBranchID: number | null;
    setCurrentBranchID: React.Dispatch<React.SetStateAction<number | null>>;
    fetchBranches: () => Promise<void>;
}

export const Branches = () => {
    //#region state
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
    });
    const [currentBranchID, setCurrentBranchID] = useState<null | number>(null);
    const {branchesLoading, branches, fetchBranches, setBranches} =
        useBranchesData();
    const [branchesFormLoading, setBranchesFormLoading] = useState(false);
    //#endregion
    //#region html
    return (
        <div>
            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.Header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({...toastModel, show: false});
                    }}
                />
            )}
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
                    <Accordion.Body className="TUEFO-header">
                        <BranchForm
                            setToastModel={setToastModel}
                            currentBranchID={currentBranchID}
                            setCurrentBranchID={setCurrentBranchID}
                            fetchBranches={fetchBranches}
                            branchesFormLoading={branchesFormLoading}
                            setBranchesFormLoading={setBranchesFormLoading}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
                    <Accordion.Body className="TUEFO-header">
                        <BranchList
                            isTableLoading={false}
                            currentBranchID={currentBranchID}
                            setCurrentBranchID={setCurrentBranchID}
                            branches={branches}
                            branchesLoading={branchesLoading}
                            fetchBranches={fetchBranches}
                            setBranchesFormLoading={setBranchesFormLoading}
                            setBranches={setBranches}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
    //#endregion
};
