import { FC, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import {
  ConfirmModelDialogBox,
  ErrorValidationBox,
  LoadingBox,
  LookupList,
  RegisterLookup,
  ToastBox,
} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  LookupFormTypeEnum,
  LookupModel,
  RequestActionModel,
  RowStateEnum,
  ToastModel,
  ValidationErrorModel,
} from "../../models";
import {
  deleteLookup,
  getLookupFromByType,
  saveLookup,
} from "../../serviceBroker/lookupApiServiceBroker";
import {
  getLabelName,
  getPageNameByPageId,
  isArabicCurrentLanguage,
} from "../../utils";

export const LookupPage: FC<{
  lookupType: LookupFormTypeEnum;
  formId?: number | null;
}> = ({ lookupType, formId = 0 }) => {
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [lookupList, setLookupList] = useState<LookupModel[]>([]);
  const [lookupObject, setLookupObject] = useState<LookupModel | null>(null);
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
  });
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [pageName, setPageName] = useState<string | null>(null);
  //#endregion
  //#region variables
  const deleteActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      variant: "danger",
      onClick: async () => {
        await handleDelete();
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setLookupObject(null);
        setShowDeleteModel(false);
      },
    },
  ];
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getAllObjects();
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    if (formId !== 0 && formId !== null && formId !== undefined) {
      setPageName(getPageNameByPageId(formId));
    }
  }, [isArabicCurrentLanguage()]);
  //#endregion
  //#region function
  const handleAction = async (request: RequestActionModel) => {
    let obj: LookupModel | null = null;
    switch (request.action) {
      case ActionTypeEnum.Save:
        obj = request.request as LookupModel;
        obj.rowState =
          obj.ID === 0 ? Number(RowStateEnum.Add) : Number(RowStateEnum.Update);
        obj.Type_ID = Number(lookupType);
        const res = await saveLookup(obj);
        if (res != null && res.Errors != null && res.Errors.length !== 0) {
          setValidationErrors(res.Errors);
          setLoading(false);
        } else {
          setValidationErrors([]);
          await handleRequestComplete(true);
        }
        break;
      case ActionTypeEnum.Clear:
        setValidationErrors([]);
        setLookupObject(null);
        break;
      case ActionTypeEnum.Update:
        obj = lookupList.filter((p) => p.ID === request.id)[0];
        obj.rowState = 2;
        setLookupObject(obj);
        break;
      case ActionTypeEnum.Delete:
        obj = lookupList.filter((p) => p.ID === request.id)[0];
        setLookupObject(obj);
        setShowDeleteModel(true);
        break;
      case ActionTypeEnum.RaiseError:
        setValidationErrors(request.request);
        break;
      case ActionTypeEnum.Success:
        setValidationErrors([]);
        await handleRequestComplete(true);
        break;
    }
  };
  const getAllObjects = async () => {
    setLoading(true);
    const result = await getLookupFromByType(Number(lookupType));
    // @ts-ignore
    setLookupList(result?.Result);
    // @ts-ignore
    setValidationErrors(result?.Errors);
    setLoading(false);
  };
  const handleRequestComplete = async (status: boolean) => {
    switch (status) {
      case true:
        setLoading(true);
        await getAllObjects();
        setLookupObject(null);
        setToastModel({ ...toastModel, show: true, variant: "success" });
        setLoading(false);
        break;
    }
  };
  const handleDelete = async () => {
    setShowDeleteModel(false);
    const deleteObjectResponse = await deleteLookup(
      lookupObject !== null ? lookupObject.ID : 0
    );
    const isSuccess: boolean = !(
      deleteObjectResponse.Errors != null &&
      deleteObjectResponse.Errors.length !== 0
    );
    if (isSuccess) {
      await getAllObjects();
    }
    setLookupObject(null);
    setToastModel({
      ...toastModel,
      show: true,
      variant: isSuccess ? "success" : "danger",
    });
  };
  console.log("lookupdfjklnsdjknsdjknsdjkList", formId);

  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{pageName}</Accordion.Header>
          <Accordion.Body>
            <RegisterLookup
              request={lookupObject}
              lookupType={lookupType}
              onActionEvent={async (o: RequestActionModel) => {
                await handleAction(o);
              }}
              formId={formId || 0}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body>
            <ConfirmModelDialogBox
              isModelVisible={showDeleteModel}
              onCloseEvent={() => {
                setShowDeleteModel(false);
              }}
              actions={deleteActions}
            ></ConfirmModelDialogBox>
            {lookupList && lookupList.length !== 0 && (
              <LookupList
                lookupType={lookupType}
                request={lookupList}
                onActionEvent={async (o: RequestActionModel) => {
                  await handleAction(o);
                }}
                onCompleteEvent={getAllObjects}
                formId={formId || 0}
              />
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
