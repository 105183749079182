import { useFormik } from "formik";
import * as Yup from "yup";
import { FC, useState } from "react";
import {
  RequestActionModel,
  UserRegistrationOptionsRequest,
  UserRegistrationResponseModel,
  ValidationErrorModel,
  ActionTypeEnum,
  RowStateEnum,
  HasFormIdModel,
} from "../../models";
import { TextBox, LoadingBox, ButtonBox, PrivilegesChecker } from "..";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import { registerUser } from "../../serviceBroker/userApiServiceBroker";

interface RegisterUserProps extends HasFormIdModel {
  request?: UserRegistrationResponseModel | null;
  options?: UserRegistrationOptionsRequest | null;
  onActionEvent: (o: RequestActionModel) => void;
}

export const RegisterUser: FC<RegisterUserProps> = ({
  request,
  options,
  formId,
  onActionEvent,
}) => {
  //#region variables
  options = options ?? {
    isUserNameModifiable: true,
    isPasswordModifiable: true,
    isNameArModifiable: true,
    isNameEnModifiable: true,
    isAdminModifiable: true,
  };
  const initialValues: UserRegistrationResponseModel = request ?? {
    User_Name: "",
    Name_EN: "",
    Name: "",
    Password: "",
    IsAdmin: false,
    JWT: undefined,
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    Errors: [],
    rowState: Number(RowStateEnum.Add),
  };
  const isArabic = isArabicCurrentLanguage();

  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationSchema] = useState(
    Yup.object({
      User_Name: options.isUserNameModifiable
        ? Yup.string().required(getLabelName("required"))
        : Yup.string(),
      Name: options.isNameArModifiable
        ? Yup.string().required(getLabelName("required"))
        : Yup.string(),
      Password: options.isPasswordModifiable
        ? Yup.string().required(getLabelName("required"))
        : Yup.string(),
    })
  );
  //#endregion
  //#region function
  const handleSubmit = async (
    request: UserRegistrationResponseModel
  ): Promise<boolean> => {
    let response: boolean = false;
    try {
      setLoading(true);
      request.rowState =
        request.ID === 0
          ? Number(RowStateEnum.Add)
          : Number(RowStateEnum.Update);
      const res = await registerUser(request);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        setLoading(false);
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.Failed,
          request: res.Errors,
          requestBeforeError: request,
        });
      } else {
        response = true;
        setLoading(false);
        onActionEvent({ id: 0, action: ActionTypeEnum.AddSuccess });
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      onActionEvent({
        id: 0,
        action: ActionTypeEnum.Failed,
        request: errors,
      });
    }
    return response;
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onReset: () => {
      //resetForm();
    },
    onSubmit: async (values, { resetForm }) => {
      const result = await handleSubmit(values);
      result && resetForm();
    },
  });
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {/*{<ErrorValidationBox errors={validationErrors} />}*/}
      <form className="forms-sample" onSubmit={formik.handleSubmit}>
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 g-md-4 g-sm-4">
          <TextBox
            key="User_Name"
            labelName="User Name"
            inputName="User_Name"
            placeHolder={getLabelName("user name")}
            isMandatory={true}
            isReadOnly={!options.isUserNameModifiable}
            inputValue={formik.values.User_Name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.User_Name}
          />

          <TextBox
            key="Password"
            labelName="Password"
            placeHolder={getLabelName("Password")}
            inputName="Password"
            type="password"
            isMandatory={true}
            isReadOnly={!options.isUserNameModifiable}
            inputValue={formik.values.Password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Password}
          />

          <TextBox
            key="Name_AR"
            labelName="Arabic Name"
            placeHolder={getLabelName("arabic name")}
            inputName="Name"
            isMandatory={true}
            isReadOnly={!options.isUserNameModifiable}
            inputValue={formik.values.Name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Name}
          />

          <TextBox
            key="Name_EN"
            labelName="English Name"
            placeHolder={getLabelName("english name")}
            inputName="Name_EN"
            isMandatory={false}
            isReadOnly={!options.isUserNameModifiable}
            inputValue={formik.values.Name_EN}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Name_EN}
          />

          <TextBox
            key="IsAdmin"
            labelName={getLabelName("is admin")}
            inputName="IsAdmin"
            type="checkbox"
            isReadOnly={!options.isUserNameModifiable}
            inputValue={formik.values.IsAdmin}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <PrivilegesChecker
              formId={formId}
              action={formik.values.ID === 0 ? "EnableSave" : "EnableUpdate"}
            >
              <ButtonBox
                iconType="content-save"
                type="submit"
                variant=""
                className="btn-gradient-primary mx-3 btn-fw"
              >
                {getLabelName(formik.values.ID === 0 ? "Save" : "Update")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              iconType="receipt"
              variant="danger"
              type="button"
              className="btn-fw"
              onClick={(e) => {
                formik.handleReset(e);
                onActionEvent({ id: 0, action: ActionTypeEnum.Clear });
              }}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
