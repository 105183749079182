import React, { Dispatch, FC, SetStateAction, useState } from "react";
import {
  ActionTypeEnum,
  BeneficiaryResponseModel,
  BeneficiaryTypeEnum,
  CustomerComponentDisplayModeEnum,
  CustomerResponseModel,
  DailyTransactionTypeEnum,
  InvoiceTypeEnum,
  LookupItemModel,
  PageEnum,
  PosTransactionsOptionsModifyTypeEnum,
  PosUserActionsUiRequestModel,
  PosUserPermissionActionTypeEnum,
  PosUserPermissionResponseModel,
  POSUserSettingModel,
  PrintTypeEnum,
  RequestActionModel,
  RowStateEnum,
  SupplierCustomerTypeEnum,
  TransactionDetailResponseModel,
  UserTransactionsSettingResponseModel,
} from "../../../models";
import { Dropdown } from "react-bootstrap";
import {
  getLabelName,
  getUserId,
  getUserStationId,
  isArabicCurrentLanguage,
} from "../../../utils";
import {
  AddCustomer,
  BeneficiaryButtonList,
  ButtonBox,
  CancellationForm,
  CloseDayDetails,
  GenericModelPopupPdfReportViewer,
  ItemButtonList,
  ModelDialogBox,
  PaymentDetails,
  PosRefundItemList,
  PosTransactionsOptions,
  PosUserPermissionLogin,
  TransactionOrders,
} from "../../";
import { customerInitialValue } from "../common/businessLogic/transactionBl";
import { buttonSizeStyle, toggleDropdownItemStyle } from "../common/css/posCss";

const posPartyInfo = (row: LookupItemModel, isArabic: boolean) => {
  return (
    <span style={{ fontSize: "14px" }}>
      {row !== null ? (isArabic ? row.nameAr : row.name) : ""}
    </span>
  );
  // <div className="row">
  //     <div className="col-12">
  //         <table className="table table-striped table-responsive">
  //             <tbody>
  //             <tr>
  //                 <td style={{fontWeight: "bold"}}>
  //                     {getLabelName("Customer")}
  //                 </td>
  //                 <td style={{fontWeight: "bold", fontSize: "17px"}}>
  //                     {isArabic ? beneficiary.nameAr : beneficiary.name}
  //                 </td>
  //             </tr>
  //             </tbody>
  //         </table>
  //     </div>
  // </div>
};
export const PosActionButtons: FC<{
  transactionData: TransactionDetailResponseModel;
  setTransactionData: Dispatch<SetStateAction<TransactionDetailResponseModel>>;
  showPaymentDetailsModel: boolean;
  setShowPaymentDetailsModel: Dispatch<SetStateAction<boolean>>;
  showPrintoutPdfModel: boolean;
  setShowPrintoutPdfModel: Dispatch<SetStateAction<boolean>>;
  beneficiary: BeneficiaryResponseModel;
  employee: BeneficiaryResponseModel;
  posSetting: POSUserSettingModel;
  transactionSetting: UserTransactionsSettingResponseModel;
  printTransactionId: number;
  onActionEvent: (o: RequestActionModel) => void;
  hasOrdersList: boolean;
}> = ({
  transactionData,
  showPaymentDetailsModel,
  setShowPaymentDetailsModel,
  showPrintoutPdfModel,
  setShowPrintoutPdfModel,
  beneficiary,
  employee,
  posSetting,
  transactionSetting,
  printTransactionId = 0,
  onActionEvent = () => {},
  hasOrdersList,
}) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const isReservable =
    transactionData.InvoiceType === InvoiceTypeEnum.DINE_IN ||
    transactionData.InvoiceType === InvoiceTypeEnum.DELIVERY;
  const isReserved = Boolean(transactionData.ID);
  //#endregion
  //#region state
  const [customerObject] =
    useState<CustomerResponseModel>(customerInitialValue);
  const [showSelectEmployee, setShowSelectEmployee] = useState(false);
  const [showSelectItem, setShowSelectItem] = useState(false);
  const [showAddBeneficiary, setShowAddBeneficiary] = useState(false);
  const [showSelectBeneficiary, setShowSelectBeneficiary] = useState(false);
  const [showCloseDay, setShowCloseDay] = useState(false);
  const [userActionUiModel, setUserActionUiModel] =
    useState<PosUserActionsUiRequestModel>({
      showLoginModel: false,
      showInvoiceOptionsModel: false,
      showRefundModel: false,
      userPermissionType: PosUserPermissionActionTypeEnum.None,
      transactionsOptionsModifyType: PosTransactionsOptionsModifyTypeEnum.None,
    });
  const [showCancelModalOpen, setCancelReservationModalOpen] = useState(false);
  const [showDeliveryOrdersModalOpen, setDeliveryOrdersModalOpen] =
    useState(false);
  //#endregion
  //#region functions
  const handleNewAction = async (isApplicable: boolean) => {
    if (isApplicable || isTransactionEligibleForNewButton(transactionData)) {
      onActionEvent({ action: ActionTypeEnum.ResetObject });
      setUserActionUiModel({
        ...userActionUiModel,
        userPermissionType: PosUserPermissionActionTypeEnum.NewAction,
        showLoginModel: false,
      });
    } else {
      setUserActionUiModel({
        ...userActionUiModel,
        userPermissionType: PosUserPermissionActionTypeEnum.NewAction,
        showLoginModel: true,
      });
    }
  };
  const handlePrintAction = async (isApplicable: boolean) => {
    if (isApplicable) {
      onActionEvent({ action: ActionTypeEnum.PrintPdf });
      setUserActionUiModel({
        ...userActionUiModel,
        showLoginModel: false,
      });
    } else {
      setUserActionUiModel({
        ...userActionUiModel,
        userPermissionType: PosUserPermissionActionTypeEnum.PrintAction,
        showLoginModel: true,
      });
    }
  };
  const handelBillOptionsAction = async (
    isApplicable: boolean,
    modificationType: PosTransactionsOptionsModifyTypeEnum
  ) => {
    let permissionType: PosUserPermissionActionTypeEnum =
      PosUserPermissionActionTypeEnum.None;
    switch (modificationType) {
      case PosTransactionsOptionsModifyTypeEnum.Discount:
        permissionType = PosUserPermissionActionTypeEnum.DiscountAction;
        break;
      case PosTransactionsOptionsModifyTypeEnum.DiscountPercentage:
        permissionType =
          PosUserPermissionActionTypeEnum.DiscountPercentageAction;
        break;
    }
    setUserActionUiModel({
      ...userActionUiModel,
      transactionsOptionsModifyType: modificationType,
      userPermissionType: permissionType,
      showInvoiceOptionsModel: isApplicable, // transactionSetting.EnableDiscount,
      showLoginModel: !isApplicable,
    });
  };
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.GetUserPOSPermission:
        const requestObject: PosUserPermissionResponseModel =
          request.request as PosUserPermissionResponseModel;
        switch (requestObject.userPermissionType) {
          case PosUserPermissionActionTypeEnum.RefundAction:
            setUserActionUiModel({
              ...userActionUiModel,
              showLoginModel: false,
              showRefundModel: true,
            });
            break;
          case PosUserPermissionActionTypeEnum.DiscountAction:
          case PosUserPermissionActionTypeEnum.DiscountPercentageAction:
            await handelBillOptionsAction(
              true,
              userActionUiModel.transactionsOptionsModifyType
            );
            break;
          case PosUserPermissionActionTypeEnum.PrintAction:
            await handlePrintAction(true);
            break;
          case PosUserPermissionActionTypeEnum.NewAction:
            await handleNewAction(true);
            break;
        }
        break;
    }
  };
  function isTransactionEligibleForNewButton(
    transaction: TransactionDetailResponseModel | null | undefined
  ): boolean {
    if (!transaction || !transaction.TransactionDetaillist) {
      return false;
    }
    return !transaction.TransactionDetaillist.some(
      (item) => item.rowState !== RowStateEnum.Delete
    );
  }
  //#endregion
  //#region html
  return (
    <>
      <div
        className="card pos-tools"
        style={{
          backgroundColor:
            transactionData.TransactionType ===
            DailyTransactionTypeEnum.POSSalesRefund
              ? "lightblue"
              : "#fff",
        }}
      >
        <div>
          <ButtonBox
            id="saveAndPayButton"
            variant="success"
            //size="sm"
            style={buttonSizeStyle}
            onClick={async () => onActionEvent({ action: ActionTypeEnum.Save })}
          >
            <i className="mdi mdi-credit-card ms-1"></i>
            <span>{getLabelName("Save && Pay")}</span>
          </ButtonBox>

          <ModelDialogBox
            isXCloseButtonVisible={true}
            isModelVisible={showPaymentDetailsModel}
            isCloseButtonVisible={false}
            isEscapeCloseEnabled={true}
            size="lg"
            title={"payment details"}
            onCloseEvent={() => {
              setShowPaymentDetailsModel(false);
            }}
          >
            <PaymentDetails
              setShowPaymentDetailsModel={setShowPaymentDetailsModel}
              totalPayment={transactionData.TotalNet}
              onActionEvent={(o: RequestActionModel) => {
                onActionEvent(o);
              }}
            />
          </ModelDialogBox>
        </div>
        {isReserved && (
          <div>
            <ButtonBox
              id="reserve"
              variant="danger"
              //size="sm"
              style={buttonSizeStyle}
              onClick={() => setCancelReservationModalOpen(true)}
            >
              <i className="mdi mdi-credit-card ms-1"></i>
              <span>{getLabelName("Cancel Reservation")}</span>
            </ButtonBox>
          </div>
        )}
        {hasOrdersList && (
          <div>
            <ButtonBox
              id="delivery-orders"
              variant="primary"
              //size="sm"
              style={buttonSizeStyle}
              onClick={() => setDeliveryOrdersModalOpen(true)}
            >
              <i className="mdi mdi-credit-card ms-1"></i>
              <span>{getLabelName("Delivery Orders")}</span>
            </ButtonBox>
            <ModelDialogBox
              isXCloseButtonVisible={true}
              isModelVisible={showDeliveryOrdersModalOpen}
              isCloseButtonVisible={false}
              isEscapeCloseEnabled={true}
              title={getLabelName("Delivery Orders")}
              size="lg"
              onCloseEvent={() => {
                setDeliveryOrdersModalOpen(false);
              }}
            >
              <TransactionOrders
                transactionData={transactionData}
                beneficiary={beneficiary}
                onActionEvent={onActionEvent}
                closeModal={() => setDeliveryOrdersModalOpen(false)}
              />
            </ModelDialogBox>
          </div>
        )}

        {isReservable && transactionData.TransactionDetaillist.length !== 0 && (
          <div>
            <ButtonBox
              id="reserve"
              variant="warning"
              //size="sm"
              style={buttonSizeStyle}
              onClick={async () =>
                onActionEvent({ action: ActionTypeEnum.ReserveTable })
              }
            >
              <i className="mdi mdi-credit-card ms-1"></i>
              <span>{getLabelName("Reserve")}</span>
            </ButtonBox>
          </div>
        )}
        <div>
          <ButtonBox
            iconType="receipt"
            variant="dark" //size="sm"
            style={buttonSizeStyle}
            onClick={async () => {
              await handleNewAction(!posSetting.AllowClickNew);
            }}
            id="newButton"
          >
            <i className="mdi mdi-plus ms-1"></i>
            <span>{getLabelName("New")}</span>
          </ButtonBox>
        </div>
        <div>
          <Dropdown>
            <Dropdown.Toggle
              variant="info"
              id="beneficiaryActionDropdown" //size="sm"
              style={buttonSizeStyle}
            >
              <i className="mdi mdi-account-plus-search ms-1"></i>
              <span>{getLabelName("Customer")}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                id="addBeneficiary"
                style={toggleDropdownItemStyle}
                onClick={() => {
                  setShowAddBeneficiary(true);
                }}
              >
                <i className="mdi mdi-account-plus"></i> {getLabelName("add")}
              </Dropdown.Item>
              <Dropdown.Item
                id="selectBeneficiary"
                style={toggleDropdownItemStyle}
                onClick={() => {
                  setShowSelectBeneficiary(true);
                }}
              >
                <i className="mdi mdi-account-search"></i>{" "}
                {getLabelName("search")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/*beneficiary info*/}
          {posPartyInfo(beneficiary, isArabic)}
          {/*add customer*/}
          <ModelDialogBox
            isXCloseButtonVisible={true}
            isModelVisible={showAddBeneficiary}
            isCloseButtonVisible={false}
            isEscapeCloseEnabled={true}
            title={getLabelName("Customer")}
            size="lg"
            onCloseEvent={() => {
              setShowAddBeneficiary(false);
            }}
          >
            <AddCustomer
              type={SupplierCustomerTypeEnum.Customer}
              request={customerObject}
              displayMode={
                CustomerComponentDisplayModeEnum.registerTransactionMode
              }
              onActionEvent={async function (
                o: RequestActionModel
              ): Promise<void> {
                setShowAddBeneficiary(false);
                if (o.request !== null && o.request !== undefined) {
                  onActionEvent({
                    action: ActionTypeEnum.AddBeneficiaryResponse,
                    request: o,
                  });
                }
              }}
              formId={PageEnum.Customers}
            />
          </ModelDialogBox>
          {/*search customer*/}
          <ModelDialogBox
            isXCloseButtonVisible={true}
            isModelVisible={showSelectBeneficiary}
            isCloseButtonVisible={false}
            isEscapeCloseEnabled={true}
            title={getLabelName("Customer")}
            size="lg"
            onCloseEvent={() => {
              setShowSelectBeneficiary(false);
            }}
          >
            <BeneficiaryButtonList
              type={BeneficiaryTypeEnum.Customer}
              onActionEvent={async (o: RequestActionModel) => {
                setShowSelectBeneficiary(false);
                onActionEvent(o);
              }}
            />
          </ModelDialogBox>
          <ModelDialogBox
            isXCloseButtonVisible={true}
            isModelVisible={showCancelModalOpen}
            isCloseButtonVisible={false}
            isEscapeCloseEnabled={true}
            title={getLabelName("Cancel Reservation")}
            size="lg"
            onCloseEvent={() => {
              setCancelReservationModalOpen(false);
            }}
          >
            {/* Here goes cancellation form */}
            <CancellationForm
              onActionEvent={onActionEvent}
              handleCancel={() => setCancelReservationModalOpen(false)}
            />
          </ModelDialogBox>
        </div>
        <div>
          <ButtonBox
            variant="info"
            size="sm"
            onClick={async () => {
              setShowSelectItem(true);
            }}
            id="selectItemButton"
          >
            <i className="mdi mdi-account-search ms-1"></i>
            <span>{getLabelName("Item")}</span>
          </ButtonBox>
          <ModelDialogBox
            isXCloseButtonVisible={true}
            isModelVisible={showSelectItem}
            isCloseButtonVisible={false}
            isEscapeCloseEnabled={true}
            title={getLabelName("Item")}
            size="lg"
            onCloseEvent={() => {
              setShowSelectItem(false);
            }}
          >
            <ItemButtonList
              onActionEvent={async (o: RequestActionModel) => {
                setShowSelectItem(false);
                onActionEvent(o);
              }}
            />
          </ModelDialogBox>
        </div>
        <div>
          <ButtonBox
            variant="info"
            size="sm"
            onClick={async () => {
              setShowSelectEmployee(true);
            }}
            id="selectEmployeeButton"
          >
            <i className="mdi mdi-account-search ms-1"></i>
            <span>{getLabelName("Employee")}</span>
          </ButtonBox>
          <div>{posPartyInfo(employee, isArabic)}</div>
          <ModelDialogBox
            isXCloseButtonVisible={true}
            isModelVisible={showSelectEmployee}
            isCloseButtonVisible={false}
            isEscapeCloseEnabled={true}
            title={getLabelName("Employee")}
            size="lg"
            onCloseEvent={() => {
              setShowSelectEmployee(false);
            }}
          >
            <BeneficiaryButtonList
              type={BeneficiaryTypeEnum.Employee}
              onActionEvent={async (o: RequestActionModel) => {
                setShowSelectEmployee(false);
                onActionEvent(o);
              }}
            />
          </ModelDialogBox>
        </div>
        {/*bill options*/}
        <div>
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-dark"
              id="billOptionsActionDropdown" //size="sm"
              style={buttonSizeStyle}
            >
              <i className="mdi mdi-settings ms-1"></i>
              <span>{getLabelName("Bill Options")}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown>
                <Dropdown.Toggle style={toggleDropdownItemStyle} variant="none">
                  <i className="mdi mdi-settings ms-1"></i>
                  <span>{getLabelName("Discount")}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    id="addDiscount"
                    style={toggleDropdownItemStyle}
                    onClick={async () => {
                      await handelBillOptionsAction(
                        transactionSetting.EnableDiscount,
                        PosTransactionsOptionsModifyTypeEnum.Discount
                      );
                    }}
                  >
                    <i className="mdi mdi-settings ms-1"></i>{" "}
                    {getLabelName("money") + " " + getLabelName("Discount")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    id="addDiscountPercentage"
                    style={toggleDropdownItemStyle}
                    onClick={async () => {
                      await handelBillOptionsAction(
                        transactionSetting.EnableDiscount,
                        PosTransactionsOptionsModifyTypeEnum.DiscountPercentage
                      );
                    }}
                  >
                    <i className="mdi mdi-settings ms-1"></i>{" "}
                    {getLabelName("Discount %")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown.Item
                id="addNotes"
                style={toggleDropdownItemStyle}
                onClick={async () => {
                  await handelBillOptionsAction(
                    true,
                    PosTransactionsOptionsModifyTypeEnum.Notes
                  );
                }}
              >
                <i className="mdi mdi-settings ms-1"></i>{" "}
                {getLabelName("Notes")}
              </Dropdown.Item>
              <Dropdown.Item
                id="addCalcType"
                style={toggleDropdownItemStyle}
                onClick={async () => {
                  await handelBillOptionsAction(
                    true,
                    PosTransactionsOptionsModifyTypeEnum.CalculationType
                  );
                }}
              >
                <i className="mdi mdi-settings ms-1"></i>{" "}
                {getLabelName("Calc Type")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <ModelDialogBox
            isXCloseButtonVisible={true}
            isModelVisible={userActionUiModel.showInvoiceOptionsModel}
            isCloseButtonVisible={false}
            isEscapeCloseEnabled={true}
            title={getLabelName("Bill Options")}
            size="lg"
            onCloseEvent={() => {
              setUserActionUiModel({
                ...userActionUiModel,
                showInvoiceOptionsModel: false,
              });
            }}
          >
            <PosTransactionsOptions
              transactionData={transactionData}
              modifyType={userActionUiModel.transactionsOptionsModifyType}
              onActionEvent={async (o) => {
                setUserActionUiModel({
                  ...userActionUiModel,
                  showInvoiceOptionsModel: false,
                });
                switch (o.action) {
                  case ActionTypeEnum.UpdatePosOptions:
                    onActionEvent(o);
                    break;
                }
                //  await handleAction(o)
              }}
            />
          </ModelDialogBox>
        </div>
        {/*print*/}
        <div>
          <ButtonBox
            variant="warning"
            size="sm"
            onClick={async () => {
              await handlePrintAction(transactionSetting.EnablePrint);
            }}
            id="printButton"
          >
            <i className="mdi mdi-printer ms-1"></i>
            <span>{getLabelName("Print")}</span>
          </ButtonBox>
        </div>
        {/*refund*/}
        <div>
          <ButtonBox
            id="refundButton"
            variant="success"
            //size="sm"
            style={buttonSizeStyle}
            onClick={() => {
              if (!posSetting.AllowReturnWithoutBill) {
                setUserActionUiModel({
                  ...userActionUiModel,
                  userPermissionType:
                    PosUserPermissionActionTypeEnum.RefundAction,
                  showLoginModel: !posSetting.AllowReturn,
                  showRefundModel: posSetting.AllowReturn,
                });
              } else {
              }
            }}
          >
            <i className="mdi mdi-replay ms-1"></i>
            <span>{getLabelName("Return")}</span>
          </ButtonBox>
        </div>
        {/*close day*/}
        <div>
          <ButtonBox
            id="closeDayButton"
            variant="outline-dark"
            size="sm"
            onClick={() => setShowCloseDay(true)}
          >
            <i className="mdi mdi-close-circle ms-1"></i>
            <span>{getLabelName("Close Day")}</span>
          </ButtonBox>
        </div>
      </div>
      {showPrintoutPdfModel && (
        <GenericModelPopupPdfReportViewer
          keys={[{ key: "transactionId", value: printTransactionId }]}
          type={PrintTypeEnum.Invoice}
          onCloseEvent={() => {
            setShowPrintoutPdfModel(false);
          }}
        />
      )}
      {showCloseDay && (
        <CloseDayDetails
          userId={getUserId()}
          stationId={getUserStationId()}
          onActionEvent={async (o: RequestActionModel) => {
            setShowCloseDay(false);
            onActionEvent(o);
          }}
        />
      )}
      {userActionUiModel.showRefundModel && (
        <PosRefundItemList
          onActionEvent={(o: RequestActionModel) => {
            setUserActionUiModel({
              ...userActionUiModel,
              showRefundModel: false,
            });
            onActionEvent(o);
          }}
        />
      )}
      {userActionUiModel.showLoginModel && (
        <PosUserPermissionLogin
          userPermissionType={userActionUiModel.userPermissionType}
          onActionEvent={async (o: RequestActionModel) => {
            setUserActionUiModel({
              ...userActionUiModel,
              showLoginModel: false,
            });
            await handleAction(o);
          }}
        />
      )}
    </>
  );
  //#endregion
};
