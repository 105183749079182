import React, { useEffect, useState } from "react";
import {
  BeneficiaryResponseModel,
  BeneficiaryTypeEnum,
  InputDatePickerTimeDisplayModeEnum,
  RequestActionModel,
  SearchTransactionsParams,
  SelectItemModel,
  TransactionDetailModel,
  TransactionDetailResponseModel,
} from "../../../../../models";
import { useFormik } from "formik";
import { InputDatePicker } from "../../../../common/InputDatePicker/InputDatePicker";
import { getLabelName, isArabicCurrentLanguage } from "../../../../../utils";
import { RadioCheckBox } from "../../../../common/radiocheckBox/radioCheckBox";
import { TextBox } from "../../../../common/textBox/textBox";
import { ButtonBox } from "../../../../common/button/button";
import { SelectBox } from "../../../../common/selectBox/selectBox";
import { LoadingBox } from "../../../../common/box/loadingBox";
import { ModelDialogBox } from "../../../../common/box/modelDialogBox";
import { BeneficiaryButtonList } from "../../../../beneficiary/beneficiaryButtonList";
import { useStoredEmployees } from "../../../../../hooks";

interface TransactionOrdersFormProps {
  setSearchParams: React.Dispatch<
    React.SetStateAction<SearchTransactionsParams>
  >;
  beneficiary: BeneficiaryResponseModel;
  initialSearchParams: SearchTransactionsParams;
}

const TransactionOrdersForm: React.FC<TransactionOrdersFormProps> = ({
  setSearchParams,
  beneficiary,
  initialSearchParams,
}) => {
  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: initialSearchParams,
    onSubmit: () => {
      setSearchParams(values);
    },
  });
  const { employeesLoading, storedEmployeeList } = useStoredEmployees();
  const [showSelectBeneficiary, setShowSelectBeneficiary] = useState(false);
  const isFormLoading = employeesLoading;
  const isArabic = isArabicCurrentLanguage();

  useEffect(() => {
    setFieldValue("customerId.value", beneficiary.value);
    setFieldValue(
      "customerId.label",
      isArabic ? beneficiary.nameAr : beneficiary.name
    );
  }, []);

  return (
    <LoadingBox isLoading={isFormLoading}>
      <form onSubmit={handleSubmit}>
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
          <div className="d-flex gap-3 justify-content-center align-items-center">
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <input
                checked={values.customerId.active}
                id="customer-active"
                onChange={(e: any) => {
                  setFieldValue("customerId.active", e.target.checked);
                }}
                type="checkbox"
              />
              <label>{getLabelName("customer")}</label>
            </div>
            <input
              value={values.customerId.label}
              style={{ width: "100%", height: "100%" }}
              type="button"
              placeholder={values.customerId.label}
              onClick={() => setShowSelectBeneficiary(true)}
              disabled={!values.customerId.active}
            />
          </div>
          <div className="d-flex gap-3 justify-content-center align-items-center">
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <input
                checked={values.fromDate.active}
                id="fromdate-active"
                onChange={(e: any) => {
                  setFieldValue("fromDate.active", e.target.checked);
                }}
                type="checkbox"
              />
              <label>{getLabelName("from date")}</label>
            </div>
            <InputDatePicker
              id="datePicker"
              className="form-control"
              name="fromDate"
              timeMode={InputDatePickerTimeDisplayModeEnum.None}
              selectedDate={new Date(values.fromDate.value!.toString())}
              InputLabel=""
              disabled={!values.fromDate.active}
              onChange={(date: Date) => {
                values.fromDate.value = date.toISOString();
                setFieldValue("fromDate", {
                  value: date.toISOString(),
                  active: values.fromDate.active,
                });
              }}
              maxDate={new Date()}
            />
          </div>
          <div className="d-flex gap-3 justify-content-center align-items-center">
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <label>{getLabelName("to date")}</label>
            </div>
            <InputDatePicker
              id="datePicker"
              className="form-control"
              name="toDate"
              timeMode={InputDatePickerTimeDisplayModeEnum.None}
              selectedDate={new Date(values.toDate.value!.toString())}
              InputLabel=""
              onChange={(date: Date) => {
                values.toDate.value = date.toISOString();
                setFieldValue("toDate", {
                  value: date.toISOString(),
                  active: values.toDate.active,
                });
              }}
              maxDate={new Date()}
            />
          </div>
          <div className="d-flex gap-3 justify-content-center align-items-center">
            <div className="d-flex gap-2 justify-content-center align-items-center">
              <input
                checked={values.employeeId.active}
                id="empolyeeId-active"
                onChange={(e: any) => {
                  setFieldValue("employeeId.active", e.target.checked);
                }}
                type="checkbox"
              />
              <label>{getLabelName("cashier")}</label>
            </div>
            <div className="flex-grow-1">
              <SelectBox
                labelName={getLabelName("")}
                inputName="employeeId"
                isDisabled={!values.employeeId.active}
                isDataLoading={employeesLoading}
                // multiselectRef={employee}
                selectedValues={
                  values.employeeId.value === null
                    ? null
                    : ["" + values.employeeId.value]
                }
                source={storedEmployeeList}
                onStatusChange={(selectedValue: SelectItemModel) => {
                  if (selectedValue === null) {
                    setFieldValue("employeeId.value", null);
                    setFieldValue("employeeId.label", "");
                    return;
                  }
                  setFieldValue("employeeId.value", selectedValue.value);
                  setFieldValue("employeeId.label", selectedValue.label);
                }}
                isSingleSelect={true}
              />
            </div>
          </div>
        </div>

        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-2 row-cols-lg-2 row-cols-md-1 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
          <RadioCheckBox
            items={[
              {
                text: getLabelName("Mobile"),
                value: "customerMobile",
                name: "searchBy.value",
              },
              {
                text: getLabelName("number"),
                value: "iD",
                name: "searchBy.value",
              },
              {
                text: getLabelName("table"),
                value: "tableId",
                name: "searchBy.value",
              },
            ]}
            onChange={(e: any) => {
              setFieldValue("searchBy.value", e.target.value);
            }}
            type={"radio"}
            selectedValues={["" + values.searchBy.value]}
            labelName={getLabelName("Type")}
          />
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <TextBox
              inputName="searchBy.value"
              inputValue={values.searchBy.word}
              labelName=""
              onChange={(e: any) => {
                setFieldValue("searchBy.word", e.target.value);
              }}
              placeHolder={getLabelName("Search")}
            />
          </div>
          <div className="col-6 p-0">
            <ButtonBox type="submit">{getLabelName("Search")}</ButtonBox>
          </div>
        </div>
      </form>
      <ModelDialogBox
        isXCloseButtonVisible={true}
        isModelVisible={showSelectBeneficiary}
        isCloseButtonVisible={false}
        isEscapeCloseEnabled={true}
        title={getLabelName("Customer")}
        size="lg"
        onCloseEvent={() => {
          setShowSelectBeneficiary(false);
        }}
      >
        <BeneficiaryButtonList
          type={BeneficiaryTypeEnum.Customer}
          onActionEvent={async (o: RequestActionModel) => {
            setShowSelectBeneficiary(false);
            setFieldValue("customerId.value", o.request.value);
            setFieldValue("customerId.label", o.request.name);
          }}
        />
      </ModelDialogBox>
    </LoadingBox>
  );
};

export default TransactionOrdersForm;
