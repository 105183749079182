import { FC } from "react";
import { TableColumn } from "react-data-table-component";
import { ButtonBox, GridTable, TextBox } from "..";
import {
  ActionTypeEnum,
  RestaurantResponseModel,
  RequestActionModel,
  HasFormIdModel,
} from "../../models";
import {
  checkActionPrivilege,
  getLabelName,
  isArabicCurrentLanguage,
} from "../../utils";

interface RestaurantListProps extends HasFormIdModel {
  request: RestaurantResponseModel[];
  onActionEvent: (o: RequestActionModel) => void;
}

export const RestaurantList: FC<RestaurantListProps> = ({
  request,
  onActionEvent = () => {},
  formId,
}) => {
  const isArabic = isArabicCurrentLanguage();
  //#region variables
  const columns: TableColumn<RestaurantResponseModel>[] = [
    {
      name: "#",
      selector: (_row, index) => Number(index || 0) + 1,
      sortable: true,
    },
    {
      name: getLabelName("Code"),
      selector: (row) => row.Code,
      sortable: true,
    },
    {
      name: getLabelName("Name"),
      selector: (row) => row.Name,
      sortable: true,
      wrap: true,
      allowOverflow: true,
    },

    {
      name: getLabelName("Eng Name"),
      selector: (row) => row.Name_En,
      sortable: true,
      allowOverflow: true,
    },
    {
      name: getLabelName("Is Default"),
      cell: (row) => (
        <TextBox
          inputName="IsDefault"
          inputValue={row.IsDefault}
          labelName=""
          type={"checkbox"}
          onChange={() => {}}
        />
      ),
    },
    {
      name: getLabelName("Is Active"),
      cell: (row) => (
        <TextBox
          inputName="Is Active"
          inputValue={row.Active}
          labelName=""
          type={"checkbox"}
          onChange={() => {}}
        />
      ),
    },
    {
      button: true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: any) =>
        checkActionPrivilege({ action: "EnableUpdate", formId: formId }) && (
          <ButtonBox
            className="btn btn-primary btn-xs"
            onClick={() => {
              onActionEvent({
                id: row.ID,
                request: row,
                action: ActionTypeEnum.Update,
              });
            }}
          >
            {getLabelName("Edit")}
          </ButtonBox>
        ),
    },
    {
      button: true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: any) =>
        checkActionPrivilege({ action: "EnableDelete", formId: formId }) && (
          <ButtonBox
            className="btn btn-danger btn-xs"
            onClick={() => {
              onActionEvent({
                id: row.ID,
                request: row,
                action: ActionTypeEnum.Delete,
              });
            }}
          >
            {getLabelName("Delete")}
          </ButtonBox>
        ),
    },
  ];
  //#endregion
  //#region html
  return (
    <>
      {request !== null && request.length !== 0 && (
        <>
          <GridTable
            //columns={columns}
            data={request}
            totalRows={request.length}
            currentPage={1}
            pageSize={10000}
            paginationType="client"
            formId={formId}
            columnsProps={{
              actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
              isArabic: isArabic,
              onActionEvent,
            }}
          />
        </>
      )}
    </>
  );
  //#endregion
};
