import { useFormik } from "formik";
import * as Yup from "yup";
import { FC, useState } from "react";
import {
  ActionTypeEnum,
  DailyTransactionTypeEnum,
  DeleteTransactionRequestModel,
  RequestActionModel,
  TransactionResponseModel,
} from "../../../models";
import { getLabelName, getUserId } from "../../../utils";
import { TextBox, ButtonBox } from "../..";

export const DeleteTransaction: FC<{
  request: TransactionResponseModel;
  onActionEvent: (o: RequestActionModel) => void;
}> = ({ request, onActionEvent = () => {} }) => {
  //#region variables
  const initialValue: DeleteTransactionRequestModel = {
    TrnsactionId: 0,
    TransactionTypeId: DailyTransactionTypeEnum.SalesInvoice,
    User_ID: getUserId(),
    VoucherSetting_ID: 0,
    DeleteReason: "",
    ValueOfPoint: 0,
    InstallmentPlanId: 0,
    IsContainsItemSerial: false,
    ItemSerialList: [],
  };
  //#endregion
  //#region state
  const [validationSchema] = useState(
    Yup.object({
      DeleteReason: Yup.string().required(
        getLabelName("Delete Reason missing")
      ),
    })
  );
  const [object, setObject] = useState<DeleteTransactionRequestModel>(
    request != null
      ? {
          TrnsactionId: request.id,
          TransactionTypeId: request.transactionType,
          User_ID: getUserId(),
          VoucherSetting_ID: 0,
          DeleteReason: "",
          ValueOfPoint: 0,
          InstallmentPlanId: 0,
          IsContainsItemSerial: false,
          ItemSerialList: [],
        }
      : initialValue
  );
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: object,
    validationSchema: validationSchema,
    // validate,
    // enableReinitialize: true,
    onReset: () => {
      onActionEvent({
        id: 0,
        action: ActionTypeEnum.Clear,
      });
    },
    onSubmit: async (values) => {
      setObject({ ...object, DeleteReason: values.DeleteReason });
      onActionEvent({
        id: object.TrnsactionId,
        request: { ...object, DeleteReason: values.DeleteReason },
        action: ActionTypeEnum.Delete,
      });
    },
  });
  //#endregion
  //#region html
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row row-cols-1 row-cols-xxl-12 row-cols-xl-12 row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-md-12 g-sm-12">
        <TextBox
          labelName={getLabelName("Delete Reason")}
          inputName={"DeleteReason"}
          inputValue={formik.values.DeleteReason}
          errorText={formik.errors.DeleteReason}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="textarea"
        />
      </div>
      <div className="row mt-3">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
          <ButtonBox type="submit">{getLabelName("ok")}</ButtonBox>
          <ButtonBox
            variant="danger"
            iconType="receipt"
            onClick={() => {
              setObject(initialValue);
              onActionEvent({
                id: 0,
                request: {},
                action: ActionTypeEnum.DeleteReset,
              });
            }}
          >
            {getLabelName("New")}
          </ButtonBox>
        </div>
      </div>
    </form>
  );
  //#endregion
};
