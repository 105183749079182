import React, {FC, useEffect, useState} from "react";
import {GeneralPrintResponseModel, KeyValueStateModel, PrintTypeEnum, ResponseBaseModel,} from "../../../models";
import {ErrorValidationBox, LoadingBox, ModelDialogBox, PdfViewer,} from "../../";
import {PrintPdfGenericMethodCaller} from "../../../serviceBroker/printApiServiceBroker";
import {AlYuserApiUrls} from "../../../configuration";

export const GenericModelPopupPdfReportViewer: FC<{
    keys: KeyValueStateModel[];
    type: PrintTypeEnum;
    onCloseEvent: () => void;
}> = ({
          keys, type, onCloseEvent = () => {
    }
      }) => {
    //#region state
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pdfPrintContent, setPdfPrintContent] = useState<
        ResponseBaseModel<GeneralPrintResponseModel>
    >({});
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            setShowModal(true);
            await getPrintPdf();
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region functions
    const getPrintPdf = async () => {
        setLoading(true);
        let response: ResponseBaseModel<GeneralPrintResponseModel>;
        let apiUrl: string;
        switch (type) {
            case PrintTypeEnum.Invoice:
                apiUrl = `${AlYuserApiUrls.transactionController.printTransactionReportPdf}${generateUrl()}`;
                response = await PrintPdfGenericMethodCaller(apiUrl);
                break;
            case PrintTypeEnum.CloseDay:
                apiUrl = `${AlYuserApiUrls.closeDayController.printCloseDay}${generateUrl()}`;
               response = await PrintPdfGenericMethodCaller(apiUrl);
                break;
            case PrintTypeEnum.JournalVoucher:
                apiUrl = `${AlYuserApiUrls.acTransactionController.printAcJurnalVoucher}${generateUrl()}`;
                response = await PrintPdfGenericMethodCaller(apiUrl);
                break;
            case PrintTypeEnum.AcDailyBox:
                apiUrl = `${AlYuserApiUrls.acTransactionController.printAcDailyBox}${generateUrl()}`;
                response = await PrintPdfGenericMethodCaller(apiUrl);
                break;
            case PrintTypeEnum.UserAlertReport:
                apiUrl = `${AlYuserApiUrls.alertReportController.getReportOfUserAlert}${generateUrl()}`;
                response = await PrintPdfGenericMethodCaller(apiUrl);
                break;
            case PrintTypeEnum.MoveItemsBetweenStores:
                apiUrl = `${AlYuserApiUrls.transactionController.printMoveItemsTransaction}${generateUrl()}`;
                response = await PrintPdfGenericMethodCaller(apiUrl);
                break;
            default:
                apiUrl = `${AlYuserApiUrls.acTransactionController.printAcTransactionTreasury}${generateUrl()}`;
                response = await PrintPdfGenericMethodCaller(apiUrl);
                break;
        }
        setPdfPrintContent(response);
        //setPdfPrintContent(response !== null && response !== undefined && response.Result !== null && response.Result !== undefined && response.Result?.BillFilePdf !== null ? response.Result?.BillFilePdf : "");
        setLoading(false);
    };
    const generateUrl = (): string => {
        let params: string = "";
        if (keys !== null && keys !== undefined && keys.length !== 0) {
            keys.forEach((row) => {
                params = `${params}${params.startsWith("?") ? "&" : "?"}${row.key}=${
                    row.value
                }`;
            });
        }
        return params;
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            <ModelDialogBox
                isModelVisible={showModal}
                isCloseButtonVisible={true}
                isEscapeCloseEnabled={true}
                onCloseEvent={() => {
                    setShowModal(false);
                    onCloseEvent();
                }}
                size="xl"
            >
                {<ErrorValidationBox errors={pdfPrintContent.Errors}/>}
                {pdfPrintContent.Result !== null && pdfPrintContent.Result !== undefined && pdfPrintContent.Result?.BillFilePdf !== null && (
                    <PdfViewer content={pdfPrintContent.Result?.BillFilePdf || ""}/>
                )}
            </ModelDialogBox>
        </>
    );
    //#endregion
};
