import { FC, useEffect, useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import {
  AddStore,
  ConfirmModelDialogBox,
  ErrorValidationBox,
  GridTable,
  LoadingBox,
  StoresList,
  ToastBox,
} from "../../components";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  PageEnum,
  RequestActionModel,
  RowStateEnum,
  StoreResponseModel,
  ToastModel,
  ValidationErrorModel,
} from "../../models";
import {
  deleteStore,
  getStores,
} from "../../serviceBroker/storeApiServiceBroker";

import {
  getLabelName,
  getPageNameByPageId,
  isArabicCurrentLanguage,
} from "../../utils";

export const StoresPage: FC<{ formId: number }> = ({
  formId = PageEnum.Stores,
}) => {
  //#region variables
  const initialValues: StoreResponseModel = {
    Address: "",
    Branch_ID: 0,
    Code: "",
    Phone: "",
    CreationDate: new Date(),
    ModificationDate: new Date(),
    PrinterName: "",
    VerifyOnUpdate: false,
    Name_En: "",
    Name: "",
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    Errors: [],
    IsDefault: false,
    rowState: Number(RowStateEnum.Add),
  };
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [stores, setStores] = useState<StoreResponseModel[]>([]);
  const [store, setStore] = useState<StoreResponseModel>(initialValues);
  const [toastModel, setToastModel] = useState<ToastModel>({
    show: false,
  });
  const [pageName, setPageName] = useState<string | null>(null);
  const [showDeleteStoreModel, setShowDeleteStoreModel] = useState(false);
  //#endregion
  //#region variables
  const deleteStoreActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        await handleStoreAction({
          id: store.ID,
          action: ActionTypeEnum.DeleteOperationStart,
        });
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setStore(initialValues);
        setShowDeleteStoreModel(false);
      },
    },
  ];
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getAllStores();
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    if (formId !== 0 && formId !== null && formId !== undefined) {
      setPageName(getPageNameByPageId(formId));
    }
  }, [isArabicCurrentLanguage()]);
  //#endregion
  //#region function
  const handleStoreAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        setLoading(true);
        setStore(initialValues);
        await getAllStores();
        setToastModel({
          ...toastModel,
          variant: "success",
          show: true,
        });
        setLoading(false);
        break;
      case ActionTypeEnum.Clear:
        setLoading(true);
        setStore(initialValues);
        await getAllStores();
        setLoading(false);
        break;
      case ActionTypeEnum.Update:
        window.scrollTo(0, 0);
        setStore(request.request);
        break;
      case ActionTypeEnum.DeleteOperationStart:
        setLoading(true);
        setShowDeleteStoreModel(false);
        const result = await deleteStore(request.id!);
        setLoading(false);
        //@ts-ignore
        await getAllStores();
        await handleStoreAction({
          id: 0,
          action: result.Result?.Result
            ? ActionTypeEnum.Success
            : ActionTypeEnum.Failed,
        });
        setStore(initialValues);
        break;
      case ActionTypeEnum.Delete:
        setStore(request.request);
        setShowDeleteStoreModel(true);
        break;
      case ActionTypeEnum.Success:
        setToastModel({ ...toastModel, variant: "success", show: true });
        break;
      case ActionTypeEnum.Failed:
        setToastModel({
          ...toastModel,
          body: request.request,
          variant: "danger",
          show: true,
        });
        setStore(request.requestBeforeError);
        break;
    }
  };
  const getAllStores = async () => {
    setLoading(true);
    const storeList = await getStores();
    setStores(storeList == null ? stores : storeList);
    setLoading(false);
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      {toastModel.show && (
        <ToastBox
          isShown={toastModel.show}
          header={toastModel.Header}
          body={toastModel.body}
          variant={toastModel.variant}
          delayDuration={toastModel.delayDuration}
          onCloseEvent={() => {
            setToastModel({ ...toastModel, show: false });
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{pageName}</Accordion.Header>
          <Accordion.Body>
            <Card>
              <Card.Body>
                <AddStore
                  request={store}
                  onActionEvent={async (o: RequestActionModel) => {
                    await handleStoreAction(o);
                  }}
                  formId={formId}
                />
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body>
            <Card className="card-custom">
              {/* <Card.Header></Card.Header> */}
              <Card.Body>
                <ConfirmModelDialogBox
                  isModelVisible={showDeleteStoreModel}
                  onCloseEvent={() => {
                    setShowDeleteStoreModel(false);
                  }}
                  actions={deleteStoreActions}
                ></ConfirmModelDialogBox>
                {/* Store list */}
                {stores && (
                  //&& stores.length !== 0
                  // <StoresList
                  //   request={stores}
                  //   onActionEvent={async (o: RequestActionModel) => {
                  //     await handleStoreAction(o);
                  //   }}
                  //   formId={formId}
                  // />
                  <GridTable
                    data={stores}
                    paginationType={"client"}
                    currentPage={1}
                    pageSize={50}
                    totalRows={stores.length}
                    columnsProps={{
                      actions: [
                        ActionTypeEnum.Update,
                        ActionTypeEnum.Delete,
                        ActionTypeEnum.Print,
                      ],

                      isArabic: isArabicCurrentLanguage(),
                      onActionEvent: handleStoreAction,
                    }}
                    formId={formId}
                  />
                )}
              </Card.Body>
            </Card>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
