import {CachingData, CompanySettingMemoryModel, CompanySettingModel, FormatValueType} from "../../models";
import {fetchLurCachingByKey, registerLurCaching} from "../caching/lruCachingRegistryManager";
import {SecureLocalStorageGet} from "../localStorage/localStorageManager";
import {SystemConfiguration} from "../../configuration";
//#region public
export const formatValue = (value: any, formatType: FormatValueType, locale: string = 'en-US') => {
    switch (formatType) {
        case "decimal":
            return formatNumber(value,locale,getCompanySetting().FormatDecimal);
        case "uppercase":
            return value.toString().toUpperCase();
        case "lowercase":
            return value.toString().toLowerCase();
        case "percentage":
          return   formatPercentage(value,locale);
        case "none":
        default:
            return value;
    }
};
//#edregion
//#region private
const memoizedCompanySettingData: CachingData<CompanySettingMemoryModel> = {
    data: null,
    results: {}
};
const parseFormatString = (format: string): Intl.NumberFormatOptions => {
    const options: Intl.NumberFormatOptions = {};

    if (format.includes('.')) {
        const decimalPart = format.split('.')[1];
        options.minimumFractionDigits = decimalPart.length;
        options.maximumFractionDigits = decimalPart.length;
    }

    options.useGrouping = format.includes(',');

    return options;
};
const formatNumber = (value: number | string, locale: string = 'en-US',format: string|null|undefined) => {
     const numericValue = typeof value === 'string' ? parseFloat(value) : value;
     if (isNaN(numericValue)) return value.toString(); // Return the original value if it's not a number
     //return new Intl.NumberFormat(locale).format(numericValue);
     const options: Intl.NumberFormatOptions = format ? parseFormatString(format) : {};
     return new Intl.NumberFormat(locale, options).format(numericValue);
 };
const formatPercentage=(value: number | string, locale: string = 'en-US') => {
     const percentageValue = typeof value === 'string' ? parseFloat(value) : value;
     if (isNaN(percentageValue)) return value.toString(); // Return the original value if it's not a number
     return new Intl.NumberFormat('en-US', {style: 'percent'}).format(percentageValue / 100);
 }
const getCompanySetting = (): CompanySettingMemoryModel => {
    const cacheRegisterKey="companyMemorySetting";
    const cachedData = fetchLurCachingByKey<CompanySettingMemoryModel>(cacheRegisterKey);

    if (cachedData && cachedData.data !== null &&cachedData.data.length!==0) {
        return cachedData.data[0];
    }
    try {
        // @ts-ignore
        const companySetting:CompanySettingModel =JSON.parse( SecureLocalStorageGet(SystemConfiguration.keys.companySettings || "")) as CompanySettingMemoryModel;
        // @ts-ignore
        memoizedCompanySettingData.data =[{FormatDecimal: companySetting.FormatDecimal}];
        registerLurCaching(cacheRegisterKey, memoizedCompanySettingData);
        return memoizedCompanySettingData.data[0];
    } catch (error) {
        return {FormatDecimal:"0.00"};
    }
};
 //#endregion