import {LRUCache } from 'lru-cache';
import {CachingData} from "../../models";
import {SystemConfiguration} from "../../configuration";



// Create an LRU cache instance
const options = { max: SystemConfiguration.lruCacheConfiguration.maxCount, maxAge: SystemConfiguration.lruCacheConfiguration.maxAge }; // Max 100 items, 1 hour max age
const memoizationCache = new LRUCache <string, CachingData<any>>(options);

export const registerLurCaching = <T>(key: string, data: CachingData<T>): void => {
    if (!memoizationCache.has(key)) {
        memoizationCache.set(key, data);
    }
};

export const fetchLurCachingByKey = <T>(key: string): CachingData<T> | null => {
    return memoizationCache.get(key) || null;
};

export const resetLurCaching = (key?: string): void => {
    if (key) {
        if (memoizationCache.has(key)) {
            const data = memoizationCache.get(key);
            if (data) {
                data.data = null;
                data.results = {};
                memoizationCache.set(key, data);
            }
        }
    } else {
        memoizationCache.clear();
    }
};
