import {searchSalesTransactionsReport} from "../../../serviceBroker/transationReportApiServiceBroker";
import React, {FC} from "react";
import {DailyTransactionTypeEnum, PageEnum, TransactionReportSearchParamsModel} from "../../../models";
import {ReportPage, ReportTransactionSearchPanel} from "../../../components";
import {getPageNameByPageId} from "../../../utils";

export const ReportSalesTransactions: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {lang: 1};//,transactionType:DailyTransactionTypeEnum.SalesInvoice
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchSalesTransactionsReport}
            searchHeader={getPageNameByPageId(PageEnum.ReportSales)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportSales}
        />
    );
};
export const ReportPosManagementPage: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {lang: 1};//,transactionType:DailyTransactionTypeEnum.POSSales
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchSalesTransactionsReport}
            searchHeader={getPageNameByPageId(PageEnum.ReportPOS)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportPOS}
        />
    );
};
export const ReportPosSalesRefundPage: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {lang: 1};//,transactionType:DailyTransactionTypeEnum.POSSalesRefund
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchSalesTransactionsReport}
            searchHeader={getPageNameByPageId(PageEnum.ReportReturnPOS)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportReturnPOS}
        />
    );
};
export const ReportPosSalesRestaurantPage: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {lang: 1};//,transactionType:DailyTransactionTypeEnum.POSRestaurant
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchSalesTransactionsReport}
            searchHeader={getPageNameByPageId(PageEnum.ReportPOSRestaurant)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportPOSRestaurant}
        />
    );
};
export const ReportPosSalesRestaurantRefundPage: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {lang: 1};//,transactionType:DailyTransactionTypeEnum.ReturnPOSRestaurant
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchSalesTransactionsReport}
            searchHeader={getPageNameByPageId(PageEnum.ReportReturnPOSRestaurant)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportReturnPOSRestaurant}
        />
    );
};
export const ReportOrderPage: FC = () => {
    const initialState: TransactionReportSearchParamsModel = {lang: 1,transactionType:DailyTransactionTypeEnum.Order};//
    return (
        <ReportPage
            InitialState={initialState}
            SearchApi={searchSalesTransactionsReport}
            searchHeader={getPageNameByPageId(PageEnum.ReportOrder)}
            SearchPanel={ReportTransactionSearchPanel}
            formId={PageEnum.ReportOrder}
        />
    );
};