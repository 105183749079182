import { FC } from "react";
import {
  checkActionPrivilege,
  getLabelName,
  isArabicCurrentLanguage,
} from "../../utils";
import {
  ActionTypeEnum,
  HasFormIdModel,
  LookupFormTypeEnum,
  LookupModel,
} from "../../models";
import { TableColumn } from "react-data-table-component";
import { TextBox, GridTable, LabelBox, ButtonBox } from "..";

interface LookupListProps extends HasFormIdModel {
  request: LookupModel[];
  lookupType: LookupFormTypeEnum;
  onActionEvent?: any | null;
  onCompleteEvent?: any | null;
}

export const LookupList: FC<LookupListProps> = ({
  request,
  lookupType,
  onActionEvent,
  formId,
}) => {
  //#region variables
  const generateColumnList = (): TableColumn<LookupModel>[] => {
    return [
      {
        name: "#",
        selector: (_row, index) => Number(index || 0) + 1,
        width: "50px",
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>{getLabelName("Name")}</label>
        ),
        selector: (row) => row.Name,
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Name English")}
          </label>
        ),
        selector: (row) => row.NameEn,
        sortable: true,
      },
      {
        name: (lookupType === LookupFormTypeEnum.Tables ||
          lookupType === LookupFormTypeEnum.Cities) && (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Value")}
          </label>
        ),
        cell: (row: LookupModel) =>
          (lookupType === LookupFormTypeEnum.Tables ||
            lookupType === LookupFormTypeEnum.Cities) && (
            <LabelBox inputValue={row.Value} labelName="" />
          ),
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Default")}
          </label>
        ),
        cell: (row: LookupModel) => (
          <TextBox
            inputName="Default"
            inputValue={row.IsDefault}
            labelName=""
            type={"checkbox"}
          />
        ),
      },
      {
        button: true,
        // eslint-disable-next-line react/button-has-type
        cell: (row: LookupModel) =>
          checkActionPrivilege({ action: "EnableUpdate", formId: formId }) && (
            <ButtonBox
              onClick={async () => {
                onActionEvent({
                  id: row.ID,
                  action: ActionTypeEnum.Update,
                });
              }}
            >
              {getLabelName("Edit")}
            </ButtonBox>
          ),
      },
      {
        button: true,
        // eslint-disable-next-line react/button-has-type
        cell: (row: LookupModel) =>
          checkActionPrivilege({ action: "EnableDelete", formId: formId }) && (
            <ButtonBox
              variant="danger"
              className="btn-xs"
              onClick={async () => {
                onActionEvent({
                  id: row.ID,
                  action: ActionTypeEnum.Delete,
                });
              }}
            >
              {getLabelName("Delete")}
            </ButtonBox>
          ),
      },
    ];
  };
  //#endregion
  //#region html
  return (
    <>
      <GridTable
        // columns={generateColumnList()}
        data={request}
        totalRows={10000}
        currentPage={1}
        paginationType="none"
        pageSize={10000}
        columnsProps={{
          actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
          isArabic: isArabicCurrentLanguage(),
          onActionEvent,
        }}
        formId={formId}
      />
    </>
  );
  //#endregion
};
