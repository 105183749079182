import { Table, Form, DatePicker, Space, Divider } from "antd";
import type { DatePickerProps } from "antd";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import {
  ButtonBox,
  LoadingBox,
  SelectBox,
  TextBox,
  PrivilegesChecker,
  InputDatePicker,
  GridTable,
} from "..";
import {
  ActionTypeEnum,
  SearchStoreInventoryResponseModel,
  SearchStoreInventoryRequestModel,
  RequestActionModel,
  LookupTypeEnum,
  LookupItemModel,
  RowStateEnum,
  ResponseBaseModel,
  HasFormIdModel,
  StoreResponseModel,
  TransactionDetailModel,
  InputDatePickerTimeDisplayModeEnum,
} from "../../models";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
import { getLabelName, getUserId, isArabicCurrentLanguage } from "../../utils";
import { saveInventoryChecking } from "../../serviceBroker/itemApiServiceBroker";
import { Accordion } from "react-bootstrap";
import { TableColumn } from "react-data-table-component";

interface StoreInventoryItemListProps extends HasFormIdModel {
  getStoreInventoryItems: (
    searchModel: SearchStoreInventoryRequestModel
  ) => void;
  request: SearchStoreInventoryResponseModel[];
  onActionEvent: (o: RequestActionModel) => void;
  totalRows: number;
}

export const StoreInventoryItemList: FC<StoreInventoryItemListProps> = ({
  request,
  onActionEvent = () => {},
  getStoreInventoryItems = () => {},
  formId,
}) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const columns: TableColumn<SearchStoreInventoryResponseModel>[] = [
    {
      name: getLabelName("Item"),
      selector: (row) => (isArabic ? row.ItemNameAr : row.ItemNameEn),
      sortable: true,
    },
    {
      name: getLabelName("Unit"),
      selector: (row) => (isArabic ? row.UnitNameAr : row.UnitNameEn),
      sortable: true,
    },
    {
      name: getLabelName("PriceQutyBegBal"),
      selector: (row) => row.PriceQutyBegBal,
      sortable: true,
    },
    {
      name: getLabelName("Quantity System"),
      selector: (row) => row.BalanceProgram,
      sortable: true,
    },
    {
      name: getLabelName("Actual Quantity"),
      id: "BalanceInventory",
      cell: (row) => {
        return (
          <>
            {" "}
            <input
              defaultValue={row.BalanceInventory}
              id={row.ItemUnitId.toString()}
              name="BalanceInventory"
              onChange={(e: any) => {
                if (transactionDetails) {
                  let existingItem = transactionDetails.filter(
                    (x) => x.ItemUnit_ID == row.ItemUnitId
                  );
                  if (existingItem.length > 0) {
                    transactionDetails.map((obj: any) => {
                      if (obj.ItemUnit_ID === row.ItemUnitId) {
                        obj.Current_Balance = Number(e.target.value);
                      }
                    });
                  } else {
                    transactionDetails.push({
                      CreatedBy: getUserId(),
                      Current_Balance: Number(e.target.value),
                      ID: 0,
                      ItemUnit_ID: row.ItemUnitId,
                      ModifiedBy: getUserId(),
                      PriceCost: row.CostPrice,
                      Factor: row.Factor,
                      ItemInstore_ID: row.ItemInstoreId,
                      rowState: RowStateEnum.Add,
                      NotesDetails: "",
                      OldQuantity: row.BalanceProgram,
                      PriceSale: row.PriceSale,
                      UnitName: row.UnitNameEn,
                    });
                  }
                  setTransactionDetails(transactionDetails);
                } else {
                  if (transactionDetails == undefined) {
                    transactionDetails = [];
                  }
                  transactionDetails.push({
                    CreatedBy: getUserId(),
                    Current_Balance: Number(e.target.value),
                    ID: 0,
                    ItemUnit_ID: row.ItemUnitId,
                    ModifiedBy: getUserId(),
                    PriceCost: row.CostPrice,
                    Factor: row.Factor,
                    ItemInstore_ID: row.ItemInstoreId,
                    rowState: RowStateEnum.Add,
                    NotesDetails: "",
                    OldQuantity: row.BalanceProgram,
                    PriceSale: row.PriceSale,
                    UnitName: row.UnitNameEn,
                  });
                  setTransactionDetails(transactionDetails);
                }
              }}
              type="text"
            />
          </>
        );
      },
    },

    {
      name: getLabelName("PriceSale"),
      selector: (row) => row.PriceSale,
      sortable: true,
    },
  ];
  //#endregion
  //#region state
  const [emp, setEmp] = useState<number | null>(null);
  const [searchCategory, setSearchCategory] = useState<number | null>(null);
  const [date, setDate] = useState<string>(new Date().toISOString());
  let [transactionDetails, setTransactionDetails] =
    useState<TransactionDetailModel[]>();
  const [_, setEditingRow] = useState<SearchStoreInventoryResponseModel | null>(
    null
  );
  const [searchItemBarcode, setSearchItemBarcode] = useState(null);
  const [searchItemName, setSearchItemName] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [empList, setEmpList] = useState<LookupItemModel[]>([]);
  const [catList, setCatList] = useState<LookupItemModel[]>([]);
  const [searchStoreInventoryRequest, setSearchStoreInventoryRequest] =
    useState<SearchStoreInventoryRequestModel>({
      pageNumber: 1,
      pageSize: 50,
      userId: getUserId(),
      itemBarcode: null,
      categoryId: null,
      itemName: null,
      lang: isArabicCurrentLanguage() ? 1 : 2,
    });
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      const [categoryList, employeeList] = await Promise.all([
        getLookupByType(LookupTypeEnum.CategoryType),
        getLookupByType(LookupTypeEnum.Employee),
      ]);
      setCatList(categoryList !== null ? categoryList : []);
      setEmpList(employeeList !== null ? employeeList : []);
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region functions
  const onFinish = async () => {
    setLoading(true);

    if (transactionDetails && transactionDetails.length > 0) {
      const res: ResponseBaseModel<StoreResponseModel> =
        await saveInventoryChecking({
          Emp_ID: emp,
          Date: date,
          Note: remarks,
          rowState: RowStateEnum.Add,
          ID: 0,
          CreatedBy: getUserId(),
          ModifiedBy: 0,
          Station_ID: 0,
          TotalMony: 0,
          TransactionDetaillist: transactionDetails,
        });
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.Failed,
          request: isArabic
            ? res.Errors[0].MessageAr
            : res?.Errors[0].MessageEn,
        });
        setLoading(false);
      } else {
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.Success,
          request: res?.Result,
        });
        setEditingRow(null);
        getStoreInventoryItems(searchStoreInventoryRequest);
        setLoading(false);
      }
    }
  };
  const handleOnChangeDate: DatePickerProps["onChange"] = (
    date,
    dateString
  ) => {
    setDate(dateString);
  };
  const handleSearch = async () => {
    setLoading(true);
    const obj = { ...searchStoreInventoryRequest };
    obj.categoryId = searchCategory;
    obj.itemBarcode = searchItemBarcode;
    obj.itemName = searchItemName;
    setSearchStoreInventoryRequest(obj);
    getStoreInventoryItems(obj);
    setLoading(false);
  };
  const disabledDate = (current: any) => {
    return current && current.endOf("day") > dayjs().endOf("day");
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{getLabelName("store inventory")}</Accordion.Header>
          <Accordion.Body className="TUEFO-header">
            <div className="row row-cols-3 row-cols-xxl-3 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
              <InputDatePicker
                id="datePicker"
                InputLabel="Date"
                className="form-control"
                name="fromDate"
                timeMode={InputDatePickerTimeDisplayModeEnum.None}
                selectedDate={new Date(date)}
                onChange={(date: Date) => {
                  setDate(date.toISOString());
                }}
                maxDate={new Date()}
              />

              <SelectBox
                labelName={getLabelName("Employee")}
                source={empList}
                isSingleSelect={true}
                onStatusChange={(e: any) => {
                  console.log(e);
                  if (e === null) return setEmp(null);
                  setEmp(e.value);
                }}
                selectedValues={[emp == null ? "" : setEmp.toString()]}
                multiselectRef={undefined}
              />
            </div>
            <div className="row mt-3 align-items-start">
              <TextBox
                labelName={getLabelName("Remarks")}
                inputName={"remarks"}
                inputValue={remarks}
                onChange={(e: any) => {
                  setRemarks(e.target.value);
                }}
                type="textarea"
              />
            </div>
            <div className="mt-4 d-flex justify-content-end">
              <PrivilegesChecker formId={formId} action={"EnableSave"}>
                <ButtonBox
                  iconType="content-save"
                  className="btn btn-primary btn-xs"
                  disabled={
                    !transactionDetails || transactionDetails.length == 0
                  }
                  onClick={onFinish}
                  style={{ width: "10%" }}
                >
                  {getLabelName("Save")}
                </ButtonBox>
              </PrivilegesChecker>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="1">
          <Accordion.Header>{getLabelName("previous data")}</Accordion.Header>
          <Accordion.Body className="TUEFO-header">
            <PrivilegesChecker formId={formId} action="EnableSearch">
              <div className="row  row-cols-xxl-3 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-sm-1 g-sm-2 g-md-4 align-items-center">
                <TextBox
                  labelName={getLabelName("Go To Item")}
                  inputName={"ItemName"}
                  inputValue={searchItemName}
                  onChange={(e: any) => {
                    setSearchItemName(e.target.value);
                  }}
                />
                <TextBox
                  labelName={getLabelName("ItemBarcode")}
                  inputName={"ItemBarcode"}
                  inputValue={searchItemBarcode}
                  onChange={(e: any) => {
                    setSearchItemBarcode(e.target.value);
                  }}
                />
                <SelectBox
                  labelName={getLabelName("Category")}
                  source={catList}
                  isSingleSelect={true}
                  onStatusChange={(e: any) => {
                    if (e === null) return setSearchCategory(null);
                    setSearchCategory(e.value);
                  }}
                  selectedValues={[
                    searchCategory == null ? "" : searchCategory.toString(),
                  ]}
                  multiselectRef={undefined}
                />
              </div>

              <div className="mt-4 mb-4 d-flex justify-content-end">
                <ButtonBox
                  iconType="magnify"
                  style={{ width: "10%" }}
                  onClick={async () => {
                    await handleSearch();
                  }}
                >
                  {getLabelName("Search")}
                </ButtonBox>
              </div>
            </PrivilegesChecker>
            <GridTable
              currentPage={1}
              data={request}
              pageSize={10}
              totalRows={request.length}
              columns={columns}
              paginationType="client"
              columnsProps={{ onActionEvent, isArabic, actions: [] }}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
