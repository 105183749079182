import { useFormik } from "formik";
import * as Yup from "yup";
import { FC, useState } from "react";
import {
  RequestActionModel,
  ValidationErrorModel,
  ActionTypeEnum,
  RowStateEnum,
  EmployeeResponseModel,
  InputDatePickerTimeDisplayModeEnum, PageEnum,
} from "../../models";
import {
  ErrorValidationBox,
  TextBox,
  LoadingBox,
  InputDatePicker,
  ButtonBox,
} from "..";
import { getLabelName } from "../../utils";
import { saveEmployee } from "../../serviceBroker/employeesApiServiceBroker";
import { Checkbox } from "antd";
import { PrivilegesChecker } from "..";

export const EmployeeForm: FC<{
  request: EmployeeResponseModel;
  onActionEvent: (o: RequestActionModel) => void;
}> = ({ request, onActionEvent }) => {
  //#region variables
  const initialValues: EmployeeResponseModel = request;
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const validationSchema = Yup.object({
    Name: Yup.string().required(getLabelName("required")),
    Mail: Yup.string().email(getLabelName("Invalid Email")),
  });
  //#endregion
  //#region function
  const handleSubmit = async (request: EmployeeResponseModel) => {
    try {
      setLoading(true);
      request.rowState =
        request.ID === 0
          ? Number(RowStateEnum.Add)
          : Number(RowStateEnum.Update);
      const res = await saveEmployee(request);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        setValidationErrors(res.Errors);
        setLoading(false);
      } else {
        setValidationErrors([]);
        setLoading(false);
        onActionEvent({ id: 0, action: ActionTypeEnum.AddSuccess });
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      setValidationErrors(errors);
    }
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
    validateOnChange: false,
  });
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      <form className="forms-sample" onSubmit={formik.handleSubmit}>
        <div className=" row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 g-md-4 g-sm-4">
          <TextBox
            key="Name"
            labelName="Name"
            inputName="Name"
            placeHolder="Arbic Name"
            isMandatory={true}
            inputValue={formik.values.Name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Name}
          />

          <TextBox
            key="NameEn"
            labelName="English Name"
            placeHolder="English Name"
            inputName="NameEn"
            isMandatory={true}
            inputValue={formik.values.NameEn}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.NameEn}
          />

          <TextBox
            key="Salary"
            labelName="Salary"
            placeHolder="Salary"
            inputName="Salary"
            inputValue={formik.values.Salary}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Salary}
          />
          <TextBox
            key="Mobile"
            labelName="Mobile"
            inputName="Mobile"
            errorText={formik.errors.Mobile}
            inputValue={formik.values.Mobile}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextBox
            key="Address"
            labelName="Address"
            placeHolder="Address"
            inputName="Address"
            inputValue={formik.values.Address}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Address}
            type={"text"}
          />

          <TextBox
            labelName="Job"
            inputName={"Job"}
            errorText={formik.errors.Job}
            inputValue={formik.values.Job}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <InputDatePicker
            id="datePicker"
            timeMode={InputDatePickerTimeDisplayModeEnum.None}
            selectedDate={new Date(formik.values.BirthDay.toString())}
            className="form-control"
            InputLabel="birthdate"
            name="birthdate"
            onChange={(date: Date) => {
              formik.values.BirthDay = date;
              formik.setFieldValue("BirthDay", date);
            }}
            maxDate={new Date()}
          />

          <TextBox
            labelName="Mail"
            inputName={"Mail"}
            errorText={formik.errors.Mail}
            inputValue={formik.values.Mail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <div className="d-flex  align-items-center">
            <div className="d-flex justify-content-around align-items-center">
              <Checkbox
                checked={formik.values.IsActive}
                name="active"
                onChange={() => {
                  formik.setFieldValue("IsActive", !formik.values.IsActive);
                }}
                id="active-mail"
              />
              <label style={{ marginInlineStart: "8px" }} htmlFor="active-mail">
                {getLabelName("active")}
              </label>
            </div>
            <div
              className="d-flex justify-content-around align-items-center"
              style={{ marginInlineStart: "30px" }}
            >
              <Checkbox
                checked={formik.values.IsDefault}
                name="default"
                onChange={() => {
                  formik.setFieldValue("IsDefault", !formik.values.IsDefault);
                }}
                id="default-mail"
              />
              <label
                style={{ marginInlineStart: "8px" }}
                htmlFor="default-mail"
              >
                {getLabelName("Is Default")}
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 d-flex justify-content-end">
            <PrivilegesChecker
              formId={PageEnum.Employees}
              action={request.ID ? "EnableUpdate" : "EnableSave"}
            >
              <ButtonBox
                iconType="content-save"
                type="submit"
                className="btn-gradient-primary mx-3"
              >
                {getLabelName(request.ID ? "Update" : "Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              iconType="receipt"
              variant="danger"
              type="button"
              onClick={(e) => {
                formik.handleReset(e);
                onActionEvent({ id: 0, action: ActionTypeEnum.Clear });
              }}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
