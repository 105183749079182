import React, { FC, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  ActionTypeEnum,
  LookupItemModel,
  ValidationErrorModel,
  RequestActionModel,
  ResponseBaseModel,
  AcBeginBalanceTransactionDetailModel,
  AccountAcBeginBalanceTypeEnum,
  AcBeginBalanceTransactionDetailRegisterModel,
  HasFormIdModel,
} from "../../models";
import {
  LoadingBox,
  ErrorValidationBox,
  TextBox,
  SelectBox,
  ButtonBox,
} from "..";
import {
  getLabelName,
  getLookUpItemNumericValue,
  getUserId,
  isArabicCurrentLanguage,
  setFormikFieldValueByName,
} from "../../utils";
import { PrivilegesChecker } from "..";
import { saveBeginBalanceAcTransaction } from "../../serviceBroker/acTransactionsApiServiceBroker";
import {
  getAccountsByMainAccountType,
  getAllMainAccountType,
} from "../../serviceBroker/accountApiServiceBroker";

interface AddAcBeginBalanceProps extends HasFormIdModel {
  request?: AcBeginBalanceTransactionDetailModel | null;
  onActionEvent: (o: RequestActionModel) => void;
}

export const AddAcBeginBalance: FC<AddAcBeginBalanceProps> = ({
  request,
  onActionEvent = () => {},
  formId,
}) => {
  //#region Variables
  const initialValues: AcBeginBalanceTransactionDetailModel = {
    rowState: 0,
    ID: 0,
    Account_ID: 0,
    TransactionHeaders_ID: 0,
    DebitValue: 0,
    CreditValue: 0,
    AccountNameAr: "",
    AccountNameEn: "",
    AccountNumber: "",
    Type: 0,
    accountNatureType: 0,
  };
  const isArabic = isArabicCurrentLanguage();
  //#endregion
  //#region State
  const [loading, setLoading] = useState(false);
  const [acBeginBalanceList, setAcBeginBalanceList] = useState<
    LookupItemModel[]
  >([]);
  const [accountsList, setAccountsList] = useState<LookupItemModel[]>([]);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const accountTypeSelectBoxMultiselectRef = useRef<any>();
  const toAccountSelectBoxMultiselectRef = useRef<any>();
  //#endregion
  //#region validation
  const [validationSchema] = useState(
    Yup.object({
      Account_ID: Yup.number()
        .required(getLabelName("required"))
        .notOneOf([0], getLabelName("required")),
      Type: Yup.number()
        .required(getLabelName("required"))
        .notOneOf([0], getLabelName("required")),
      DebitValue: Yup.number().when("accountNatureType", {
        is: Number(AccountAcBeginBalanceTypeEnum.DebitAccount),
        then: Yup.number()
          .required(getLabelName("required"))
          .min(1, getLabelName("required")),
      }),
      CreditValue: Yup.number().when("accountNatureType", {
        is: Number(AccountAcBeginBalanceTypeEnum.CreditAccount),
        then: Yup.number()
          .required(getLabelName("required"))
          .min(1, getLabelName("required")),
      }),
    })
  );
  //#endregion
  //#region functions
  const handleResetForm = async () => {
    //formik.resetForm({ values: initialValues })
    await formik.setValues({ ...initialValues });
    setFormikFieldValueByName(formik, "CreditValue", 0);
    setFormikFieldValueByName(formik, "DebitValue", 0);
    setFormikFieldValueByName(formik, "Type", 0);
    setFormikFieldValueByName(
      formik,
      "accountNatureType",
      Number(AccountAcBeginBalanceTypeEnum.None)
    );
    await formik.setTouched({});
    formik.setErrors({});
    accountTypeSelectBoxMultiselectRef.current.clearValue();
    toAccountSelectBoxMultiselectRef?.current?.clearValue?.();
  };
  const handleSubmit = async (
    request: AcBeginBalanceTransactionDetailModel
  ) => {
    try {
      setLoading(true);
      const submitRequestObject: AcBeginBalanceTransactionDetailRegisterModel =
        {
          ID: 0,
          Account_ID: request.Account_ID,
          Value:
            request.accountNatureType ===
            Number(AccountAcBeginBalanceTypeEnum.CreditAccount)
              ? request.CreditValue
              : request.DebitValue,
          UserId: getUserId(),
        };
      const res: ResponseBaseModel<AcBeginBalanceTransactionDetailRegisterModel> =
        await saveBeginBalanceAcTransaction(submitRequestObject);
      if (
        res !== null &&
        res !== undefined &&
        res.Errors !== null &&
        res.Errors !== undefined &&
        res.Errors.length !== 0
      ) {
        setValidationErrors(res.Errors);
        setLoading(false);
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.Failed,
          request: isArabic
            ? res.Errors[0].MessageAr
            : res?.Errors[0].MessageEn,
          requestBeforeError: request,
        });
      } else {
        setValidationErrors([]);
        await handleResetForm();
        setLoading(false);
        onActionEvent({ action: ActionTypeEnum.AddSuccess });
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      setValidationErrors(errors);
    }
  };
  //#endregion
  //#region  formik
  const formik = useFormik({
    initialValues: request || initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values: AcBeginBalanceTransactionDetailModel) => {
      await handleSubmit(values);
    },
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      const acBeginBalanceList = await getAllMainAccountType();
      setAcBeginBalanceList(acBeginBalanceList);
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async (typeID: any) => {
      if (formik.values.Type !== 0) {
        setLoading(true);
        const accountsList = await getAccountsByMainAccountType(typeID);
        setAccountsList(accountsList);
        setFormikFieldValueByName(
          formik,
          "accountNatureType",
          accountsList[0].otherValue
        );
        setFormikFieldValueByName(formik, "Account_ID", accountsList[0].value);
        setLoading(false);
      } else {
        setFormikFieldValueByName(formik, "accountNatureType", 0);
      }
    };
    fillData(formik.values.Type).then(() => {});
  }, [formik.values.Type]);
  const isCreditValue =
    formik.values.accountNatureType ===
    Number(AccountAcBeginBalanceTypeEnum.CreditAccount);
  //#endregion
  //#region html
  return (
    <LoadingBox isLoading={loading}>
      {<ErrorValidationBox errors={validationErrors} />}
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
          <SelectBox
            labelName={getLabelName("Type")}
            errorText={formik.errors.Type}
            source={acBeginBalanceList}
            isSingleSelect={true}
            onStatusChange={async (e: any) => {
              const val: number | null = getLookUpItemNumericValue(e);
              console.log("val", val);
              setFormikFieldValueByName(formik, "Type", val || 0);
            }}
            selectedValues={[
              formik.values.Type == null ? "" : formik.values.Type.toString(),
            ]}
            multiselectRef={accountTypeSelectBoxMultiselectRef}
          />
          {formik.values.Type !== 0 && (
            <SelectBox
              isMandatory={true}
              labelName={getLabelName("To Account")}
              errorText={formik.errors.Account_ID}
              source={accountsList}
              isSingleSelect={true}
              onStatusChange={async (e: any) => {
                const val: number | null = getLookUpItemNumericValue(e);
                if (val !== null) {
                  setFormikFieldValueByName(formik, "Account_ID", val);
                  setFormikFieldValueByName(formik, "CreditValue", 0);
                  setFormikFieldValueByName(formik, "DebitValue", 0);
                }
              }}
              selectedValues={[
                formik.values.Account_ID == null
                  ? ""
                  : formik.values.Account_ID.toString(),
              ]}
              multiselectRef={toAccountSelectBoxMultiselectRef}
            />
          )}
          {formik.values.accountNatureType ===
            Number(AccountAcBeginBalanceTypeEnum.DebitAccount) && (
            <TextBox
              labelName={getLabelName("Total Debit")}
              inputName={"DebitValue"}
              errorText={formik.errors.DebitValue}
              inputValue={formik.values.DebitValue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isMandatory={!isCreditValue}
              type={"number"}
            />
          )}
          {formik.values.accountNatureType ===
            Number(AccountAcBeginBalanceTypeEnum.CreditAccount) && (
            <TextBox
              labelName={getLabelName("Total Credit")}
              inputName={"CreditValue"}
              errorText={formik.errors.CreditValue}
              inputValue={formik.values.CreditValue}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isMandatory={isCreditValue}
              type={"number"}
            />
          )}
        </div>
        <div className="w-100 mt-3 d-flex justify-content-end gap-3">
          {formik.values.Type !== 0 && (
            <>
              <PrivilegesChecker
                formId={formId}
                action={request?.ID ? "EnableUpdate" : "EnableSave"}
              >
                <ButtonBox
                  iconType="content-save"
                  type="submit"
                  className="btn btn-sm btn-gradient-primary me-2"
                >
                  {getLabelName(request?.ID ? "update" : "save")}
                </ButtonBox>
              </PrivilegesChecker>
              <ButtonBox
                iconType="receipt"
                variant="danger"
                type="button"
                className="btn-fw"
                onClick={async () => {
                  await handleResetForm();
                }}
              >
                {getLabelName("New")}
              </ButtonBox>
            </>
          )}
        </div>
      </form>
    </LoadingBox>
  );
  //#endregion
};
