import { FC, FormEvent, useEffect, useState } from "react";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";

import setCommissionsCategoryColumns, {
  CommissionRowData,
} from "./uiHelper/comissionsColumns";
import {
  getEmployeeCommission,
  updateEmployeeCategory,
} from "../../serviceBroker/employeesApiServiceBroker";
import { RowStateEnum } from "../../models";
import { TextBox, ButtonBox, LoadingBox, GridTable } from "..";

interface CommissionsListProps {
  employeeID: number;
  onCloseHandler: () => void;
  onSuccessHandler: () => void;
}

export const CommissionsList: FC<CommissionsListProps> = ({
  employeeID,
  onCloseHandler,
  onSuccessHandler,
}) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  //#endregion
  //#region state
  const [commissionCategories, setCommissionCategories] = useState<
    CommissionRowData[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [searchedWord, setSearchedWord] = useState("");
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fetchCommissionCategories = async () => {
      setLoading(true);
      const categories = await getEmployeeCommission(employeeID);
      setLoading(false);
      if (!!categories && categories.length !== 0)
        setCommissionCategories(
          categories?.map(
            ({
              ID,
              CategoryNameAr,
              CategoryNameEn,
              ProfitPercentage,
              CategoryId,
              CalcCommissionAfterCost,
            }) => ({
              groupName: isArabic ? CategoryNameAr : CategoryNameEn,
              ID,
              CalcCommissionAfterCost,
              ProfitPercentage,
              CategoryId,
              isChanged: false,
              shown: true,
            })
          )
        );
    };
    fetchCommissionCategories().then(() => {});
  }, []);
  //#endregion
  //#region functions
  const handleCheckChange = (id: number) => {
    setCommissionCategories((prev) =>
      prev.map((category) => {
        if (id === category.ID)
          return {
            ...category,
            CalcCommissionAfterCost: !category.CalcCommissionAfterCost,
            isChanged: true,
          };
        return category;
      })
    );
  };
  const handlePercentChange = (id: number, enteredValue: number) => {
    setCommissionCategories((prev) =>
      prev.map((category) => {
        if (id === category.ID)
          return {
            ...category,
            ProfitPercentage: enteredValue,
            isChanged: true,
          };
        return category;
      })
    );
  };
  const onSaveHandler = async () => {
    const categoriesPromiseArray = commissionCategories
      // update only changed categories
      .filter(({ isChanged }) => isChanged)
      .map(
        async ({
          CalcCommissionAfterCost,
          CategoryId,
          ID,
          ProfitPercentage,
        }) => {
          return await updateEmployeeCategory({
            CalcCommissionAfterCost,
            CategoryId,
            EmployeeId: employeeID,
            ID,
            ProfitPercentage,
            rowState: RowStateEnum.Update,
          });
        }
      );
    setLoading(true);
    await Promise.all(categoriesPromiseArray);
    setLoading(false);
    onCloseHandler();
    onSuccessHandler();
  };
  const handleSearchCategoryByName = () => {
    setCommissionCategories((prev) =>
      prev.map((category) => ({
        ...category,
        shown: category.groupName.includes(searchedWord),
      }))
    );
  };
  //#endregion
  //#region html
  return (
    <>
      <div
        className="d-flex mb-4 justify-content-between"
        style={{ width: 290 }}
      >
        <TextBox
          inputName="search"
          inputValue={searchedWord}
          onChange={(e: FormEvent<HTMLInputElement>) =>
            setSearchedWord(e.currentTarget.value)
          }
          labelName=""
          placeHolder={getLabelName("search")}
          controlSize="w-10"
        />
        <ButtonBox onClick={handleSearchCategoryByName} iconType="magnify">
          {getLabelName("search")}
        </ButtonBox>
      </div>
      <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
        {loading && <LoadingBox />}
        <GridTable
          fixedHeader={true}
          columns={setCommissionsCategoryColumns({
            handleCheck: handleCheckChange,
            handlePercentChange,
          })}
          data={commissionCategories.filter(({ shown }) => shown)}
          currentPage={0}
          pageSize={1000}
          totalRows={100000}
        />
      </div>
      <div className="d-flex justify-content-between mt-3">
        <ButtonBox
          iconType="content-save"
          size="sm"
          type="submit"
          variant="primary"
          className="btn btn-orange"
          onClick={onSaveHandler}
        >
          {getLabelName("save")}
        </ButtonBox>
        <ButtonBox
          iconType="receipt"
          size="sm"
          type="button"
          variant="danger"
          className="btn btn-orange"
          onClick={onCloseHandler}
        >
          {getLabelName("New")}
        </ButtonBox>
      </div>
    </>
  );
  //#endregion
};
