import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import {
  DeleteTransactionRequestModel,
  LookupItemModel,
  ResponseBaseModel,
  RestaurantResponseModel,
  SearchReservedTransactionResponseModel,
  SearchTransactionRequestModel,
  SearchTransactionsParams,
  TransactionDetailResponseModel,
  TransactionResponseModel,
  TransactionResultResponseModel,
  TransactionServerResponseModel,
  ValidateTransactionModeEnum,
} from "../models";
import { getUserId } from "../utils";
import { AlYuserApiUrls } from "../configuration";
import { createQueryString, filterSearchParams } from "../utils/querySearch/parseSearchParams";
//#region get
export const validateTransaction = async (
  transactionId: number,
  creationDate: Date,
  totalTax: number,
  mode: ValidateTransactionModeEnum
): Promise<ResponseBaseModel<boolean>> => {
  let response: ResponseBaseModel<boolean>;
  let url: string = "";
  switch (mode) {
    case ValidateTransactionModeEnum.Modify:
      url = `${
        AlYuserApiUrls.transactionController.validateEditTransactionUrl
      }?transactionId=${transactionId}&userId=${getUserId()}&transactionCreatedDate=${creationDate}&totalTax=${totalTax}`;
      break;
    case ValidateTransactionModeEnum.Delete:
      url = `${
        AlYuserApiUrls.transactionController.validateDeleteTransactionUrl
      }?transactionId=${transactionId}&userId=${getUserId()}`;
      break;
    case ValidateTransactionModeEnum.Return:
      url = `${
        AlYuserApiUrls.transactionController.validateReturnTransactionUrl
      }?transactionId=${transactionId}&userId=${getUserId()}`;
      break;
  }
  response = await AlYusrAxiosApiInstance.get(url);
  return response;
};
export const searchTransactions = async (
  request: SearchTransactionRequestModel
): Promise<ResponseBaseModel<TransactionResponseModel[]>> => {
  let response: ResponseBaseModel<TransactionResponseModel[]> = {
    PageCount: 0,
    TotalRecoredCount: 0,
  };
  let url: string = `${
    AlYuserApiUrls.transactionController.searchTransactionUrl
  }?searchparm.pageNumber=${request.pageNumber}&&searchparm.pageSize=${
    request.pageSize
  }&&searchparm.transactionType=${Number(request.transactionType)}`;
  url = request.customerId
    ? `${url}&searchparm.customerId=${request.customerId}`
    : url;
  url = request.searchValue
    ? `${url}&searchparm.iD=${request.searchValue}`
    : url;
  const fetchResult: ResponseBaseModel<TransactionServerResponseModel[]> =
    await AlYusrAxiosApiInstance.get(url);
  response.Errors = fetchResult.Errors;
  response.TotalRecoredCount = fetchResult.TotalRecoredCount;
  if (
    fetchResult.Result !== null &&
    fetchResult.Result !== undefined &&
    fetchResult.Result.length !== 0
  ) {
    response.Result = [];
    fetchResult.Result.forEach((row) => {
      response.Result?.push({
        id: row.ID,
        code: row.Code,
        isValidForReturn: row.IsValidForReturn,
        customerNameAr: row.CustomerNameAr,
        customerNameEn: row.CustomerNameEn,
        employeeNameAr: row.EmployeeNameAr,
        employeeNameEn: row.EmployeeNameEn,
        paymentTypeNameAr: row.PaymenTypeNameAr,
        paymentTypeNameEn: row.PaymenTypeNameEn,
        currencyNameAr: row.CurrencyNameAr,
        currencyNameEn: row.CurrencyNameEn,
        creationDate: row.CreateDate,
        discount: row.Discount,
        netMoney: row.NetMoney,
        transactionType: row.TransactionType,
        totalTax: row.TotalTax,
      });
    });
  }
  return response;
};
export const getTransactionDetailForRefund = async (
  id: number
): Promise<TransactionDetailResponseModel | null> => {
  let response: ResponseBaseModel<TransactionDetailResponseModel>;
  let url: string = `${
    AlYuserApiUrls.transactionController.getTransactionForReturnByIdUrl
  }?transactionId=${id}&userId=${getUserId()}`;
  response = await AlYusrAxiosApiInstance.get(url);
  return response && response.Result && response.Result.TransactionDetaillist
    ? (response.Result.TransactionDetaillist.forEach((item) => {
        item.defaultQuantity = item.Quantity;
      }),
      response.Result)
    : null;
};
export const getTransactionDetail = async (
  id: number
): Promise<TransactionDetailResponseModel | null> => {
  let response: ResponseBaseModel<TransactionDetailResponseModel>;
  let url: string = `${AlYuserApiUrls.transactionController.getFullTransactionByIdUrl}?id=${id}`;
  response = await AlYusrAxiosApiInstance.get(url);
  return response !== null &&
    response !== undefined &&
    response.Result !== null &&
    response.Result !== undefined
    ? response.Result
    : null;
};
export const getTransactionFullInfoById = async (
  id: number
): Promise<TransactionDetailResponseModel | null> => {
  let response: ResponseBaseModel<TransactionDetailResponseModel>;
  let url: string = `${AlYuserApiUrls.transactionController.getTransactionFullInfoByIdUrl}?transactionHeaderID=${id}`;
  response = await AlYusrAxiosApiInstance.get(url);
  return response !== null &&
    response !== undefined &&
    response.Result !== null &&
    response.Result !== undefined
    ? response.Result
    : null;
};
//#endregion
//#region post
export const deleteTransaction = async (
  request: DeleteTransactionRequestModel
): Promise<ResponseBaseModel<boolean>> => {
  let response: ResponseBaseModel<boolean> = {};
  let url: string = `${AlYuserApiUrls.transactionController.deleteTransactionUrl}`;
  try {
    response = await AlYusrAxiosApiInstance.post(url, request);
  } catch (err) {
    alert(err);
  }
  return response;
};
export const saveTransaction = async (
  request: TransactionDetailResponseModel
): Promise<ResponseBaseModel<TransactionResultResponseModel>> => {
  let response: ResponseBaseModel<TransactionResultResponseModel> = {};
  try {
    response = await AlYusrAxiosApiInstance.post(
      AlYuserApiUrls.transactionController.saveTransactionUrl,
      request
    );
  } catch (err) {
    alert(err);
  }
  return response;
};

export const cancelReservation = async (transactionId: number, userId: number, reason?: string) => {
   let response: ResponseBaseModel<TransactionResultResponseModel> = {};
  try {
    response = await AlYusrAxiosApiInstance.post(
      `${AlYuserApiUrls.transactionController.cancelReservation}?transactionId=${transactionId}&userId=${userId}&reason=${reason}`
    );
  } catch (err) {
    alert(err);
  }
  return response;
}

export const searchReservedTransactions = async (searchParams: SearchTransactionsParams) => {
   let response: ResponseBaseModel<SearchReservedTransactionResponseModel[]> = {};
  try {
    const filteredSearchParams = filterSearchParams(searchParams);
    const searchQuery = createQueryString(filteredSearchParams);
    response = await AlYusrAxiosApiInstance.get(
      `${AlYuserApiUrls.transactionController.searchReservedTransactions}?${searchQuery}`
    );
  } catch (err) {
    alert(err);
  }
  return {result:  response.Result as SearchReservedTransactionResponseModel[], totalCount: response.TotalRecoredCount};
}

export const getreservedTransactionByID = async (reservedTransactionId: number) => {
   let response: ResponseBaseModel<SearchReservedTransactionResponseModel[]> = {};
  try {
    response = await AlYusrAxiosApiInstance.get(
      `${AlYuserApiUrls.transactionController.getReservedTransactionByID}?reservedTransactionId=${reservedTransactionId}`
    );
  } catch (err) {
    alert(err);
  }
  return response.Result;
}

export const getChildrenTables = async (parentID: number): Promise<LookupItemModel[]> => {
    let categoryResult: ResponseBaseModel<RestaurantResponseModel[]>;
    let response: LookupItemModel[] = [];
    const url: string = `${AlYuserApiUrls.restaurantTableController.getAllRestuarntTableByParentId}?parentId=${parentID}`;
    categoryResult = await AlYusrAxiosApiInstance.get(url);
    if (
        categoryResult !== null &&
        categoryResult.Result !== null &&
        categoryResult.Result !== undefined &&
        categoryResult.Result.length !== 0
    ) {
        categoryResult.Result.forEach((row) => {
            response.push({
                nameAr: row.Name,
                name: row.Name_En,
                value: row.ID.toString(),
                otherValue: {
                    Active: row.Active,
                    IsPrinted: row.IsPrinted
                }
            });
        });
    }
    return response;
};
//#endregion
