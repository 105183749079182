import React, { FC, useEffect, useState } from "react";
import {
  ActionTypeEnum,
  BeneficiaryResponseModel,
  BeneficiaryTypeEnum,
  LookupKeyValueModel,
  RequestActionModel,
} from "../../models";
import { ButtonBox, LoadingBox, TextBox } from "..";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import _, { filter } from "lodash";
import { getBeneficiaryByType } from "../../serviceBroker/beneficiaryApiServiceBroker";

export const BeneficiaryButtonList: FC<{
  type: BeneficiaryTypeEnum;
  onActionEvent: (o: RequestActionModel) => void;
}> = ({ type, onActionEvent = () => {} }) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  //#endregion
  //#region state
  const [filterText, setFilterText] = useState<string | null>(null);
  const [beneficiaryList, setBeneficiaryList] = useState<
    BeneficiaryResponseModel[]
  >([]);
  const [filteredBeneficiaryList, setFilteredBeneficiaryList] = useState<
    BeneficiaryResponseModel[]
  >([]);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      switch (type) {
        case BeneficiaryTypeEnum.Employee:
          setLoading(true);
          await handleSearchBeneficiary();
          setLoading(false);
          break;
      }
    };
    fillData().then(() => {});
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      handleSearchBeneficiary();
    }, 550);

    return () => clearTimeout(handler);
  }, [filterText]);
  //#endregion
  //#region functions
  const getActionType = (): ActionTypeEnum => {
    switch (type) {
      case BeneficiaryTypeEnum.Customer:
        return ActionTypeEnum.SelectCustomerResponse;
      case BeneficiaryTypeEnum.Employee:
        return ActionTypeEnum.SelectEmployeeResponse;
    }
    return ActionTypeEnum.None;
  };
  const handleSearchBeneficiary = async (
    isClearSearch: boolean = false,
    searchText: string | null = null
  ) => {
    setLoading(true);
    let beneficiaries: BeneficiaryResponseModel[] =
      type === BeneficiaryTypeEnum.Employee ? beneficiaryList : [];
    let lowerQuery: string | null = !isClearSearch
      ? (searchText || filterText || "").toLowerCase()
      : "";
    lowerQuery = lowerQuery === "" ? null : lowerQuery;
    if (beneficiaries === null || beneficiaries.length === 0) {
      beneficiaries = handleSortAlphabetically(
        await getBeneficiaryByType(
          type,
          true,
          generateSearchRequest(lowerQuery)
        ),
        isArabic
      );
      setBeneficiaryList(beneficiaries);
      setFilteredBeneficiaryList(beneficiaries);
    } else {
      if (lowerQuery !== null) {
        const beneficiaries = beneficiaryList.filter((beneficiary) => {
          const searchInName = beneficiary.name
            .toLowerCase()
            .includes(lowerQuery || "");
          const searchInNameAr = beneficiary.nameAr
            .toLowerCase()
            .includes(lowerQuery || "");
          const searchInMobile =
            beneficiary.mobile &&
            /^\d+$/.test(beneficiary.mobile) &&
            beneficiary.mobile.includes(lowerQuery || "");
          return _.some([searchInName, searchInNameAr, searchInMobile]);
        });
        setFilteredBeneficiaryList(beneficiaries);
      } else {
        setFilteredBeneficiaryList(beneficiaryList);
      }
    }
    setLoading(false);
  };
  const handleSortAlphabetically = (
    list: BeneficiaryResponseModel[],
    isArabic: boolean
  ) => {
    return list.slice().sort((a, b) => {
      const propertyName = isArabic ? "nameAr" : "name";
      const nameA = a[propertyName].toUpperCase();
      const nameB = b[propertyName].toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  };
  const generateSearchRequest = (
    filterText: string | null = null
  ): LookupKeyValueModel[] => {
    let searchValues: LookupKeyValueModel[] = [];
    if (filterText !== null && filterText !== "") {
      switch (type) {
        case BeneficiaryTypeEnum.Customer:
          searchValues.push({ key: "serachParmeter.name", value: filterText });
          searchValues.push({
            key: "serachParmeter.mobile",
            value: filterText,
          });
      }
    }
    return searchValues;
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <div className="col-6 d-flex justify-content-start mb-3">
        <TextBox
          labelName={getLabelName("")} //{t("lookup.nameAr")}
          inputName={"filterTextBox"}
          inputValue={filterText}
          onChange={(e: any) => {
            setFilterText(e.target.value);
          }}
        />
        <ButtonBox
          iconType="magnify"
          type="submit"
          className="btn-gradient-primary mx-3"
          variant=""
          onClick={async () => {
            await handleSearchBeneficiary();
          }}
        >
          {getLabelName("Search")}
        </ButtonBox>
        <ButtonBox
          iconType="close-circle"
          type="button"
          className="btn-gradient-danger mx-3"
          variant=""
          onClick={async () => {
            //setFilteredBeneficiaryList(beneficiaryList);
            setFilterText("");
            await handleSearchBeneficiary(true);
          }}
        >
          {getLabelName("Cancel")}
        </ButtonBox>
        <ButtonBox
          iconType="close-circle"
          type="button"
          className="btn-gradient-danger mx-3"
          variant=""
          onClick={async () => {
            onActionEvent({
              action: ActionTypeEnum.None,
            });
          }}
        >
          {getLabelName("Close")}
        </ButtonBox>
      </div>
      <ul className="align-items-start align-content-start d-flex flex-wrap pos-list  p-2">
        {filteredBeneficiaryList !== null &&
          filteredBeneficiaryList.length !== 0 &&
          filteredBeneficiaryList.map((row, index) => (
            <li className="list-group-item p-1 w-auto border-0" key={index}>
              <ButtonBox
                key={`beneficiary_btn_${index}`}
                variant="outline-success"
                className="shadow-none"
                // style={btnStyle}
                id={`beneficiary_btn_${index}`}
                onClick={() => {
                  onActionEvent({
                    action: getActionType(),
                    request: row,
                  });
                }}
              >
                {isArabic ? row.nameAr : row.name}
                {row.mobile ? `-${row.mobile}` : ""}
                {row.address ? `-${row.address}` : ""}
              </ButtonBox>
            </li>
          ))}
      </ul>
    </>
  );
  //#endregion
};
