import {PosTransactionsMainComponent, POSInvoiceType, TransactionMainComponent} from "../../../components";
import {
    DailyTransactionTypeEnum, PageEnum,
} from "../../../models";

export const PosPage = () => {
  //#region html
  return (
    <>
      <PosTransactionsMainComponent
          transactionTypeId={DailyTransactionTypeEnum.POSSales}
      />
    </>
  );
  //#endregion
};

export const RestaurantPosPage = () => {
  //#region html
  return (
    <>
      <PosTransactionsMainComponent
        InvoiceTypeComponent={POSInvoiceType}
        transactionTypeId={DailyTransactionTypeEnum.POSRestaurant}
      />
    </>
  );
  //#endregion
};

export const PosManagementPage = () => {
  //#region html
  return (
    <>
      <TransactionMainComponent
          formId={PageEnum.POSManagement}
          transactionType={DailyTransactionTypeEnum.POSSales}
      />
    </>
  );
  //#endregion
}
export const PosPurchasePage = () => {
    //#region html
    return (
        <>
            <PosTransactionsMainComponent
                transactionTypeId={DailyTransactionTypeEnum.POSRestaurant}
            />
        </>
    );
    //#endregion
}
export const PosSalesRefundPage = () => {
    //#region html
    return (
        <>
            <TransactionMainComponent
                formId={PageEnum.POSReturnManagement}
                transactionType={DailyTransactionTypeEnum.POSSalesRefund}
            />
        </>
    );
    //#endregion
}
export const PosRestaurantManagementPage = () => {
    //#region html
    return (
        <>
            <TransactionMainComponent
                formId={PageEnum.POSRestaurant}
                transactionType={DailyTransactionTypeEnum.POSRestaurant}
            />
        </>
    );
    //#endregion
}
export const PosRestaurantReservationManagementPage = () => {
    //#region html
    return (
        <>
            <TransactionMainComponent
                formId={PageEnum.POSRestaurantReservationManagement}
                transactionType={DailyTransactionTypeEnum.Reserve_Restaurant_Bill}
            />
        </>
    );
    //#endregion
}
export const PosRestaurantReturnManagementPage = () => {
    //#region html
    return (
        <>
            <TransactionMainComponent
                formId={PageEnum.POSRestaurantReturnManagement}
                transactionType={DailyTransactionTypeEnum.ReturnPOSRestaurant}
            />
        </>
    );
    //#endregion
}