import React from "react";
import {TableColumn} from "react-data-table-component";
import {
    ActionTypeEnum,
    AcTransactionSearchModel,
    RequestActionModel,
} from "../../../models";
import {checkActionPrivilege, formatDate, getLabelName, getPagePrivileges} from "../../../utils";
import { ButtonBox} from "../../";

export const getTransactionListColumns = (
    isArabic: boolean,
    onActionEvent: (o: RequestActionModel) => void,
    formId: number
): TableColumn<AcTransactionSearchModel>[] => {
    return [
        {
            name: "#",
            selector: (_row, index) => Number(index || 0) + 1,
            sortable: true,
            width: "80px",
        },
        {
            name: getLabelName("Number"),
            selector: (row) => row.ID,
            sortable: true,
            width: "100px",
            wrap: true,
        },
        {
            name: getLabelName("Account"),
            selector: (row) =>
                isArabic ? row.AccountsArabicName : row.AccountsEnglishName,
            sortable: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("Currency"),
            selector: (row) => (isArabic ? row.CurrencyNameAr : row.CurrencyNameEn),
            sortable: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("cmbxCostCenter"),
            selector: (row) =>
                isArabic ? row.CostCenterNameAr : row.CostCenterNameEn,
            sortable: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("Value"),
            selector: (row) => row.Value,
            sortable: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("Date"),
            selector: (row) => formatDate(row.Date),
            sortable: true,
            allowOverflow: true,
        },
        {
            button: true,
            // eslint-disable-next-line react/button-has-type
            cell: (row: AcTransactionSearchModel) =>
                (getPagePrivileges().EnableUpdate && checkActionPrivilege({action: "EnableUpdate", formId: formId}) && (
                        <ButtonBox
                            iconType="pencil-box"
                            id="modifyItemBtn"
                            onClick={() => {
                                onActionEvent({
                                    id: row.ID,
                                    request: row,
                                    action: ActionTypeEnum.Update,
                                });
                            }}
                        >
                            {getLabelName("Edit")}
                        </ButtonBox>
                    )
                ),
        },
        {
            button:true,
            // eslint-disable-next-line react/button-has-type
            cell: (row: AcTransactionSearchModel) =>
                (getPagePrivileges().EnableDelete && checkActionPrivilege({action:"EnableDelete" ,formId:formId}) && (
                        <ButtonBox
                        iconType="delete"
                            id="deleteItemBtn"
                            variant="danger"
                            className="btn-xs"
                            onClick={() => {
                                onActionEvent({
                                    id: row.ID,
                                    request: row,
                                    action: ActionTypeEnum.Delete,
                                });
                            }}
                        >
                            {getLabelName("Delete")}
                        </ButtonBox>
                )),
        },
        {
            button:true,
            // eslint-disable-next-line react/button-has-type
            cell: (row: AcTransactionSearchModel) => (
                <ButtonBox
                iconType="printer"
                    id="deleteItemBtn"
                    variant="danger"
                    className="btn-xs"
                    onClick={() => {
                        onActionEvent({
                            id: row.ID,
                            request: row,
                            action: ActionTypeEnum.PrintPdf,
                        });
                    }}
                >
                    {getLabelName("Print")}
                </ButtonBox>
            ),
        },
    ];
};

// <Space size={20}>
//     <ActionButton
//         commandType="Edit"
//         onClick={() => {
//             onActionEvent({
//                 id: row.ID,
//                 request: row,
//                 action: ActionTypeEnum.Update,
//             });
//         }}
//     />
//     <ActionButton
//
//         commandType="Print"
//         onClick={() => {
//             onActionEvent({
//                 id: row.ID,
//                 request: row,
//                 action: ActionTypeEnum.Print,
//             });
//         }}
//     />
//     <ActionButton
//         commandType="Delete"
//         onClick={() => {
//             onActionEvent({
//                 id: row.ID,
//                 request: row,
//                 action: ActionTypeEnum.Delete,
//             });
//         }}
//     />
// </Space>
