import React, { useState } from "react";
import {
  LoadingBox,
  SearchBox,
  ConfirmModelDialogBox,
  PrivilegesChecker,
  GridTable,
} from "..";
import { BranchesChildComponent } from "./branches";
import { BranchResponseModel, PageEnum } from "../../models";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import {
  ActionButtonsModel,
  ActionTypeEnum,
  RequestActionModel,
} from "../../models";
import { deleteBranch } from "../../serviceBroker/branchesServiceBroker";
import { useSearchBox } from "../../hooks";

export interface BranchesListProps extends BranchesChildComponent {
  isTableLoading: boolean;
  branchesLoading: boolean;
  branches: BranchResponseModel[];
  setBranchesFormLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setBranches: React.Dispatch<React.SetStateAction<BranchResponseModel[]>>;
}

export const BranchList: React.FC<BranchesListProps> = ({
  isTableLoading,
  setCurrentBranchID,
  branches,
  branchesLoading,
  fetchBranches,
}) => {
  //#region state
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [deletedID, setDeletedID] = useState<null | number>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { onChange, searchedWord, onSearch, value } = useSearchBox();
  const handleRowSelect = (id: number) => {
    setCurrentBranchID(id);
  };
  const handleRowDelete = (id: number) => {
    setDeletedID(id);
    setShowDeleteModel(true);
  };
  const isArabic = isArabicCurrentLanguage();
  //#endregion
  //#region functions
  const onActionEvent = (o: RequestActionModel) => {
    switch (o.action) {
      case ActionTypeEnum.Update:
        handleRowSelect(o.request.ID);
        break;
      case ActionTypeEnum.Delete:
        handleRowDelete(o.request.ID);
        break;
    }
  };
  //#endregion
  //#region variables
  const deleteActions: ActionButtonsModel[] = [
    {
      text: getLabelName("yes"),
      onClick: async () => {
        setIsDeleting(true);
        await deleteBranch(deletedID as number);
        setIsDeleting(false);
        setShowDeleteModel(false);
        await fetchBranches();
      },
    },
    {
      text: getLabelName("no"),
      onClick: () => {
        setShowDeleteModel(false);
      },
    },
  ];
  //#endregion
  //#region html
  return (
    <>
      <ConfirmModelDialogBox
        isModelVisible={showDeleteModel}
        onCloseEvent={() => {
          setShowDeleteModel(false);
          setDeletedID(null);
        }}
        actions={deleteActions}
        isLoading={isDeleting}
      >
        {" "}
        <div className="alert alert-warning">
          {getLabelName("Are You Sure You Want Delete")}
        </div>
      </ConfirmModelDialogBox>
      <LoadingBox isLoading={branchesLoading || isTableLoading}>
        <PrivilegesChecker formId={PageEnum.Branches} action="EnableSearch">
          <SearchBox
            onChangeHandler={onChange}
            onSearchHandler={onSearch}
            searchedWord={value}
            name="branch"
          />
        </PrivilegesChecker>
        <GridTable
          columnsProps={{
            actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
            onActionEvent,
            isArabic,
          }}
          data={branches.filter(
            ({ ArabicName, EnglishName }) =>
              ArabicName.includes(searchedWord) ||
              EnglishName?.includes(searchedWord)
          )}
          formId={PageEnum.Branches}
          currentPage={1}
          pageSize={10}
          paginationType="client"
          totalRows={branches.length}
        />
      </LoadingBox>
    </>
  );
  //#endregion
};
