import {TableColumn} from "react-data-table-component";
import {SearchTransactionResultModel} from "../../../models";
import {checkActionPrivilege, getLabelName} from "../../../utils";
import {ButtonBox} from "../..";

interface VoucherHandler {
    handleDeletion: (id: number) => void;
    handleModification: (id: number) => void;
    handlePrint: (id: number) => void;
    formId: number;
}

const setVoucherColumns: (
    handlers: VoucherHandler
) => TableColumn<SearchTransactionResultModel>[] = ({
                                                        handleDeletion,
                                                        handleModification,
                                                        handlePrint, formId,
                                                    }) => {
    const voucherColumns: TableColumn<SearchTransactionResultModel>[] = [
        {
            name: getLabelName("code"),
            selector: (row) => row.Code,
            sortable: true,
        },
        {
            name: getLabelName("date"),
            selector: (row) => row.Date,
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("money"),
            selector: (row) => row.Value,
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },

        {
            name: getLabelName("cost center"),
            selector: (row) => row.CostCenterNameAr || "",
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("currency"),
            selector: (row) => row.CurrencyNameAr,
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },

        {
            button:true,
            // eslint-disable-next-line react/button-has-type
            cell: (row) => (
                checkActionPrivilege({action:"EnableUpdate" ,formId:formId}) && (
                    <ButtonBox
                    iconType="pencil-box"
                        id="editVoucher"
                        className="btn btn-primary"
                        onClick={() => handleModification(row.ID)}
                    >
                        {getLabelName("edit")}
                    </ButtonBox>
                )
            ),
        },
        {
            button:true,
            // eslint-disable-next-line react/button-has-type
            cell: (row) => (
                <ButtonBox
                iconType="printer"
                    id="printVoucher"
                    className="btn btn-primary"
                    onClick={() => handlePrint(row.ID)}
                >
                    {getLabelName("print")}
                </ButtonBox>
            ),
        },
        {
            button:true,
            // eslint-disable-next-line react/button-has-type
            cell: (row) => (
                checkActionPrivilege({action:"EnableDelete" ,formId:formId}) && (
                    <ButtonBox
                    iconType="delete"
                        id="deleteVoucher"
                        variant="danger"
                        className="btn btn-danger"
                        onClick={() => handleDeletion(row.ID)}
                    >
                        {getLabelName("Delete")}
                    </ButtonBox>
                )
            ),
        },
    ];

    return voucherColumns;
};

export default setVoucherColumns;
