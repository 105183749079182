import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TableColumn } from "react-data-table-component";
import {
  ButtonBox,
  LoadingBox,
  SelectBox,
    GridTable,
  PrivilegesChecker,
} from "..";
import {
  ActionTypeEnum,
  EstehekakModel,
  LookupItemModel,
  RequestActionModel,
  LookupTypeEnum,
  AccrualSubtractRuleTypeEnum,
  HasFormIdModel,
} from "../../models";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
import {checkActionPrivilege, getLabelName, isArabicCurrentLanguage} from "../../utils";
import dayjs from "dayjs";

interface EstehkakListProps extends HasFormIdModel {
  getDueList: (
    searchEmp: string | null,
    searchRule: string | null,
    typeId: number,
    pageSize: number,
    pageNumber: number
  ) => void;
  onActionEvent: (o: RequestActionModel) => void;
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
  totalRows: number;
  request: EstehekakModel[];
}

export const EstehkakList: FC<EstehkakListProps> = ({
  onActionEvent = () => {},
  getDueList = () => {},
  request,
  totalRows,
  formId,
}) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    const defaultPageSize: number = 50;
    const columns: TableColumn<EstehekakModel>[] = useMemo(
        () => [
            {
                name: "#",
                selector: (_row, index) => Number(index || 0) + 1,
                sortable: true,
                width: "100px",
            },
            {
                name: getLabelName("Employee"),
                selector: (row) => (isArabic ? row.EmployeeNameAr : row.EmployeeNameEn),
                sortable: true,
                allowOverflow: true,
            },
            {
                name: getLabelName("Rule"),
                selector: (row) =>
                    isArabic ? row.EsthkakSubtractRuleAr : row.EsthkakSubtractRuleEn,
                sortable: true,
                allowOverflow: true,
            },
            {
                name: getLabelName("Date"),
                selector: (row) => dayjs(row.Date).format("DD/MM/YYYY"),
                sortable: true,
                wrap: true,
            },
            {
                name: getLabelName("Value"),
                selector: (row) => row.Value,
                sortable: true,
                wrap: true,
                allowOverflow: true,
            },

            {
                button: true,
                // eslint-disable-next-line react/button-has-type
                name: getLabelName("Edit"),
                cell: (row: EstehekakModel) => (
                    checkActionPrivilege({action: "EnableUpdate", formId: formId}) && (
                        <ButtonBox
                            className="btn btn-primary btn-xs"
                            onClick={() => {
                                onActionEvent({
                                    id: row.ID,
                                    request: row,
                                    action: ActionTypeEnum.Update,
                                });
                            }}
                        >
                            {getLabelName("Edit")}
                        </ButtonBox>
                    )
                ),
            },
            {
                button: true,
// eslint-disable-next-line react/button-has-type
                name: getLabelName("Delete"),
                cell: (row: EstehekakModel) => (
                    checkActionPrivilege({action: "EnableDelete", formId: formId}) && (
                        <ButtonBox
                            className="btn btn-danger btn-xs"
                            onClick={() => {
                                onActionEvent({
                                    id: row.ID,
                                    request: row,
                                    action: ActionTypeEnum.Delete,
                                });
                            }}
                        >
                            {getLabelName("Delete")}
                        </ButtonBox>
                    )
                ),
            },
        ],
        []
    );
    //#endregion
    //#region state
    const [searchEmp, setSearchEmp] = useState<string | null>(null);
    const [searchRule, setSearchRule] = useState<string | null>(null);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(50);
    const [ruleList, setRuleList] = useState<LookupItemModel[]>([]);
    const [empList, setEmpList] = useState<LookupItemModel[]>([]);
    const [loading, setLoading] = useState(false);
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            const [estList, empList] = await Promise.all([getLookupByType(LookupTypeEnum.Estehkak), getLookupByType(LookupTypeEnum.Employee)]);
            setRuleList(estList || []);
            setEmpList(empList || []);
            setLoading(false);
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region function
    const onCurrentPageChange = async (number: number) => {
        if (number !== pageNumber) {
            setPageNumber(number);
        }
        getDueList(
            searchEmp,
            searchRule,
            Number(AccrualSubtractRuleTypeEnum.Accrual),
            pageSize,
            number
        );
    };
    const onPageSizeChange = async (size: number) => {
        if (size !== pageSize) {
            setPageSize(size);
        }
        getDueList(
            searchEmp,
            searchRule,
            Number(AccrualSubtractRuleTypeEnum.Accrual),
            size,
            pageNumber
        );
    };
    const handleSearch = async () => {
        setLoading(true);
        getDueList(
            searchEmp,
            searchRule,
            Number(AccrualSubtractRuleTypeEnum.Accrual),
            pageSize,
            pageNumber
        );
        setLoading(false);
    };
    //#endregion
    //#region html
    return (
        <>
            <PrivilegesChecker formId={formId} action="EnableSearch">
                <div
                    className="row row-cols-3 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 g-sm-3 g-md-4 align-items-start">
                    <SelectBox
                        labelName={getLabelName("Employee")}
                        source={empList}
                        isSingleSelect={true}
                        onStatusChange={(e: any) => {
                            setSearchEmp(e.value);
                        }}
                        selectedValues={[searchEmp!]}
                        multiselectRef={undefined}
                    />
                    <SelectBox
                        labelName={getLabelName("Rule")}
                        source={ruleList}
                        isSingleSelect={true}
                        onStatusChange={(e: any) => {
                            setSearchRule(e.value);
                        }}
                        selectedValues={[searchRule!]}
                        multiselectRef={undefined}
                    />

                    <ButtonBox
                        style={{width: "10%"}}
                        onClick={async () => {
                            await handleSearch();
                        }}
                    >
                        {getLabelName("Search")}
                    </ButtonBox>
                </div>
            </PrivilegesChecker>

            {loading && <LoadingBox/>}
            <GridTable
                columns={columns}
                data={request}
                totalRows={totalRows}
                currentPage={pageNumber || 1}
                pageSize={pageSize || defaultPageSize}
                onCurrentPageChange={onCurrentPageChange}
                onPageSizeChange={onPageSizeChange}
                paginationType="server"
                //subHeaderComponent={subHeaderComponent}
            />
        </>
    );
    //#endregion
};
