import React, { FC, useEffect, useState } from "react";
import { Card, Row } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { getLabelName } from "../../utils";
import { Form, Formik, FormikProps, useFormik } from "formik";
import {
  LookupItemModel,
  ValidationErrorModel,
  RuleExpRecipeAccountsEnum,
  ruleExpenseMoneySearchModel,
  PageEnum,
  AcBeginBalanceTransactionDetailModel,
  RequestActionModel,
  ActionTypeEnum,
  RowStateEnum,
} from "../../models";
import {
  TextBox,
  ButtonBox,
  ErrorValidationBox,
  PrivilegesChecker,
  LoadingBox,
} from "..";
import {
  saveRuleExpenseMoney,
  saveRuleReceiveMoney,
} from "../../serviceBroker/accountApiServiceBroker";
const acTransactionInitValue: ruleExpenseMoneySearchModel = {
  Code: "",
  AccountType_ID: 0,
  Orders: 0,
  ArabicName: "",
  EnglishName: "",
  Parent_ID: 0,
  AllParent: "",
  RegisterInTrans: false,
  Active: true,
  Notes: "",
  BeginBalance: 0,
  Total_Money: 0,
  Money_Pay: 0,
  Balance: 0,
  ShowToUser: true,
  DateCreate: new Date(),
  BalanceInCurrency: 0,
  Account_UniqueID: "",
  TotalDebit: 0,
  TotalCredit: 0,
  NatureType: 0,
  IsParent: true,
  IsShowInCostCenter: false,
  AccountTypeId: 0,
  BranchID: 0,
  ID: 0,
  CreatedBy: 0,
  ModifiedBy: 0,
  Name: "",
  CreationDate: new Date(),
  ModificationDate: new Date(),
  VerifyOnUpdate: false,
  rowState: 1,
};
export const RegisterRuleExpenseMoney: FC<{
  searchPanelHeader: string;
  acTransactionType: RuleExpRecipeAccountsEnum;
  acTransaction: ruleExpenseMoneySearchModel;
  setLoading: Function;
  customerAccountLookups: LookupItemModel[];
  currencyLookUps: LookupItemModel[];
  coastCenterLookUps: LookupItemModel[];
  suppliersAccountLookups: LookupItemModel[];
  handleSaveEmployeeComplete: Function;
  onActionEvent: (request: RequestActionModel) => Promise<void>;
  formID: PageEnum;
  setIsFormLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isFormLoading: boolean;
}> = (props) => {
  //#region variables
  const {
    searchPanelHeader,
    acTransactionType,
    acTransaction,
    setLoading,
    handleSaveEmployeeComplete,
    formID,
    setIsFormLoading,
    isFormLoading,
    onActionEvent,
  } = props;
  //#endregion
  //#region state
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  useEffect(() => {
    if (acTransaction) {
      setValidationErrors([]);
      formik.setValues({ ...acTransaction, rowState: RowStateEnum.Update });
    }
  }, [acTransaction]);
  const [validationSchema] = useState();
  const formik = useFormik({
    initialValues: acTransactionInitValue,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values: ruleExpenseMoneySearchModel) => {
      setIsFormLoading(true);
      await handleSubmit(values);
      setIsFormLoading(false);
    },
    onReset: () => {
      onActionEvent({ action: ActionTypeEnum.Clear });
    },
  });
  //#endregion
  //#region functions
  const handleSubmit = async (request: ruleExpenseMoneySearchModel) => {
    try {
      setIsFormLoading(true);
      if (request.ID === 0) request.rowState = 1;
      else {
        request.rowState = 2;
      }
      let res;
      if (acTransactionType == RuleExpRecipeAccountsEnum.Expense)
        res = await saveRuleExpenseMoney(request);
      else res = await saveRuleReceiveMoney(request);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        setValidationErrors(res.Errors);
      } else {
        setValidationErrors([]);
        handleSaveEmployeeComplete(true);
        formik.resetForm();
      }
      setIsFormLoading(false);
    } catch (err: any) {
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      setValidationErrors(errors);
    }
  };
  //#endregion
  //#region html

  return (
    <LoadingBox isLoading={isFormLoading}>
      {<ErrorValidationBox errors={validationErrors} />}
      <form onSubmit={formik.handleSubmit} className="gap-4 row">
        <div className="col-md-4">
          <TextBox
            inputName={"ArabicName"}
            labelName="ArabicName"
            errorText={formik.errors.ArabicName}
            inputValue={formik.values.ArabicName}
            onChange={formik.handleChange}
            isMandatory={true}
            onBlur={formik.handleBlur}
          />
        </div>

        <div className="col-md-4">
          <TextBox
            inputName={"EnglishName"}
            labelName={getLabelName("English Name")}
            errorText={formik.errors.EnglishName}
            inputValue={formik.values.EnglishName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="col-md-12">
          <TextBox
            inputName={"Notes"}
            labelName="Notes"
            errorText={formik.errors.Notes}
            type="textarea"
            inputValue={formik.values.Notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>

        <div className="accordion-footer">
          <div className="col-md-12 d-flex justify-content-end gap-3 mt-3">
            <PrivilegesChecker
              formId={formID}
              action={acTransaction.ID ? "EnableUpdate" : "EnableSave"}
            >
              <ButtonBox type="submit" className="btn btn-orange">
                {getLabelName(acTransaction.ID ? "Update" : "Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              variant="secondary"
              type="button"
              className="btn btn-orange"
              onClick={formik.handleReset}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </LoadingBox>
  );

  //#endregion
};
