import React, { FC } from "react";
import { RuleExpenseCompo } from "../../components";
import {
  PageEnum,
  RuleExpRecipeAccountsEnum,
} from "../../models";

export const RuleExpenseMoneyPage: FC<{}> = () => {
  return (
    <>
      <RuleExpenseCompo
        formId={PageEnum.RuleExport}
        acTransactionType={RuleExpRecipeAccountsEnum.Expense}
      />
    </>
  );
};
export const RuleReceiveMoneyPage: FC<{}> = () => {
  return (
    <>
      <RuleExpenseCompo
        formId={PageEnum.RuleReceive}
        acTransactionType={RuleExpRecipeAccountsEnum.Receive}
      />
    </>
  );
};
