import React from "react";
import { Accordion } from "react-bootstrap";
import { getLabelName, isArabicCurrentLanguage } from "../../../../../utils";
import { GridTable } from "../../../../common/dataTable/grid/gridTable";
import {
  PageEnum,
  SearchReservedTransactionResponseModel,
  TransactionDetailResponseModel,
} from "../../../../../models";
import { LoadingBox } from "../../../../common/box/loadingBox";
import { TableColumn } from "react-data-table-component";

interface TransactionOrdersListProps {
  searchedTransactions: SearchReservedTransactionResponseModel[];
  isLoading: boolean;
  pageNumber: number;
  pageSize: number;
  setPageNumber: (pageNumber: number) => void;
  setPageSize: (pageSize: number) => void;
  totalCount: number;
  handleSelectTransaction: (transaction: number) => Promise<void>;
}

const TransactionOrdersList: React.FC<TransactionOrdersListProps> = ({
  searchedTransactions,
  isLoading,
  pageNumber,
  pageSize,
  setPageSize,
  setPageNumber,
  totalCount,
  handleSelectTransaction,
}) => {
  const columns = generateColumns();
  const isArabic = isArabicCurrentLanguage();
  return (
    <Accordion defaultActiveKey="0">
      <LoadingBox isLoading={isLoading}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{getLabelName("Previous Data")}</Accordion.Header>
          <Accordion.Body>
            <GridTable
              currentPage={pageNumber}
              data={searchedTransactions}
              pageSize={pageSize}
              totalRows={totalCount}
              onRowClicked={(row: any) => {
                console.log(row);
                handleSelectTransaction(row.ID);
              }}
              onCurrentPageChange={setPageNumber}
              onPageSizeChange={setPageSize}
              paginationType={"server"}
              columnsProps={{
                actions: [],
                isArabic,
                onActionEvent: () => {},
              }}
              formId={PageEnum.POSRestaurant}
              // temporary code to be changes when gridId is added to api resposne
            />
          </Accordion.Body>
        </Accordion.Item>
      </LoadingBox>
    </Accordion>
  );
};

// Dummy tables will be removed when GridTable render it dynamically
const generateColumns =
  (): TableColumn<SearchReservedTransactionResponseModel>[] => {
    const isArabic = isArabicCurrentLanguage();

    return [
      {
        name: getLabelName("Order Number"),
        selector: (row, index) => row.Code,
        width: "50px",
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("item count")}
          </label>
        ),
        selector: (row) => row.Number,
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>{getLabelName("Type")}</label>
        ),
        selector: (row) => row.TransactionTypeName,
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Employee")}
          </label>
        ),
        selector: (row) => row.EmpName,
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Transport Cost")}
          </label>
        ),
        selector: (row) => row.TransportCost,
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Total")}
          </label>
        ),
        selector: (row) => row.TotalMony,
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Total Tax")}
          </label>
        ),
        selector: (row) => row.TotalTax,
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("create date")}
          </label>
        ),
        selector: (row) => row.CreateDate,
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Order Status")}
          </label>
        ),
        selector: (row) => row[isArabic ? "OrderStatusAr" : "OrderStatusEn"],
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Time lapsed")}
          </label>
        ),
        selector: (row) => row.TimeElapsed,
        sortable: true,
      },
      {
        name: (
          <label style={{ width: "fit-content" }}>
            {getLabelName("Customer Address")}
          </label>
        ),
        selector: (row) => row.Customer_Address,
        sortable: true,
      },
    ];
  };

export default TransactionOrdersList;
