import { useFormik } from "formik";
import * as Yup from "yup";
import { FC, useEffect, useState } from "react";
import {
  ActionTypeEnum,
  calcTypeApiResponseModel,
  CalcTypeEnum,
  HasFormIdModel,
  LookupItemModel,
  LookupTypeEnum,
  RequestActionModel,
  ResponseBaseModel,
  RowStateEnum,
  ValidationErrorModel,
} from "../../models";
import {
  ButtonBox,
  LoadingBox,
  SelectBox,
  TextBox,
  PrivilegesChecker,
} from "..";
import { getLabelName, getLookUpItemNumericValue } from "../../utils";
import { addCalcType } from "../../serviceBroker/calcTypeServiceBroker";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";

interface AddCalcTypeProps extends HasFormIdModel {
  calcType: CalcTypeEnum;
  request: calcTypeApiResponseModel | null;
  onActionEvent: (o: RequestActionModel) => void;
}

export const AddCalcType: FC<AddCalcTypeProps> = ({
  calcType,
  request,
  onActionEvent,
  formId,
}) => {
  //#region variables
  const initialValues: calcTypeApiResponseModel = {
    ID: 0,
    Name: "",
    EnglishName: "",
    Percentage: 0,
    PriceType: 0,
    DefaultFixedValue: 0,
    IsApplicationDelivery: false,
    CreatedBy: 0,
    ModifiedBy: 0,
    CreationDate: new Date(),
    ModificationDate: new Date(),
    VerifyOnUpdate: false,
    Row_Status: Number(RowStateEnum.Add),
    rowState: Number(RowStateEnum.Add),
  };
  //#endregion
  //#region state
  const [validationSchema] = useState(
    Yup.object({
      Name: Yup.string().required(getLabelName("required")),
    })
  );
  const [loading, setLoading] = useState(false);
  const [calcTypeList, setCalcTypeList] = useState<LookupItemModel[]>([]);
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    // enableReinitialize: true,
    onReset: () => {
      //resetForm();
    },
    onSubmit: async (values) => {
      await handleSubmit(values);
      // resetForm();
    },
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      const calcType = await getLookupByType(LookupTypeEnum.PriceType);
      const formikValues: calcTypeApiResponseModel = request || initialValues;
      formikValues.PriceType =
        formikValues.PriceType === 0
          ? Number(calcType[0].value)
          : formikValues.PriceType;
      await formik.setValues(formikValues);
      setCalcTypeList(calcType);
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      if (request != null) {
        setLoading(true);
        await formik.setValues(request);
        setLoading(false);
      }
    };
    fillData().then(() => {});
  }, [request]);
  //#endregion
  //#region function
  const handleSubmit = async (request: calcTypeApiResponseModel) => {
    try {
      setLoading(true);
      //@ts-ignore
      //request.PriceType = defaultCalcTypeId;
      request.IsApplicationDelivery = isApplicationDelivery();
      request.Row_Status =
        request.ID === 0
          ? Number(RowStateEnum.Add)
          : Number(RowStateEnum.Update);
      request.rowState =
        request.ID === 0
          ? Number(RowStateEnum.Add)
          : Number(RowStateEnum.Update);
      const res: ResponseBaseModel<calcTypeApiResponseModel> =
        await addCalcType(request);
      if (res != null && res.Errors != null && res.Errors.length > 0) {
        setLoading(false);
        onActionEvent({
          request: res.Errors,
          action: ActionTypeEnum.Failed,
        });
      } else {
        setLoading(false);
        formik.resetForm();
        onActionEvent({
          action: ActionTypeEnum.AddSuccess,
        });
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      onActionEvent({
        request: errors,
        action: ActionTypeEnum.Failed,
      });
    }
  };
  const isApplicationDelivery = (): boolean => {
    switch (calcType) {
      case CalcTypeEnum.ApplicationDelivery:
        return true;
      default:
        return false;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <form className="forms-sample" onSubmit={formik.handleSubmit}>
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
          <TextBox
            labelName={getLabelName("Arabic Name")}
            inputName={"Name"}
            errorText={formik.errors.Name}
            inputValue={formik.values.Name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextBox
            labelName={getLabelName("English Name")}
            inputName={"EnglishName"}
            errorText={formik.errors.EnglishName}
            inputValue={formik.values.EnglishName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <SelectBox
            labelName={getLabelName("Price")}
            source={calcTypeList}
            isSingleSelect={true}
            errorText={formik.errors.PriceType}
            onStatusChange={async (e: any) => {
              const value = getLookUpItemNumericValue(e);
              if (value !== null) {
                formik.values.PriceType = value;
                await formik.setFieldValue("PriceType", value);
              }
            }}
            selectedValues={[formik.values.PriceType.toString()]}
            multiselectRef={undefined}
          />
          <TextBox
            labelName={getLabelName("Increment Ratio")}
            inputName={"Percentage"}
            errorText={formik.errors.Percentage}
            inputValue={formik.values.Percentage}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="row py-2">
          <div className="col-12 d-flex justify-content-end">
            <PrivilegesChecker
              formId={formId}
              action={request?.ID ? "EnableUpdate" : "EnableSave"}
            >
              <ButtonBox
                iconType="content-save"
                type="submit"
                variant=""
                className="btn-gradient-primary mx-3 btn-fw"
              >
                {getLabelName(request?.ID ? "Update" : "Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              iconType="receipt"
              variant="danger"
              type="button"
              className="btn-fw"
              onClick={(e) => {
                formik.handleReset(e);
                onActionEvent({ id: 0, action: ActionTypeEnum.Clear });
              }}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
