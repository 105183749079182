import React, {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useState,
} from "react";
import { TextBox, SelectBox } from "../..";
import {
  getLabelName,
  getLookUpItemValue,
  getOriginalValueInCurrency,
  updateStateDynamically,
} from "../../../utils";
import {
  CompanySettingModel,
  CustomerComponentDisplayModeEnum,
  CustomerResponseModel,
  DailyTransactionTypeEnum,
  InputDatePickerTimeDisplayModeEnum,
  KeyValueStateModel,
  LookupItemModel, PageEnum,
  RequestActionModel,
  SupplierCustomerTypeEnum,
  TransactionDetailResponseModel,
  TransactionItemResponseModel,
  TransactionTotalValuesModel,
  UserTransactionsSettingResponseModel,
} from "../../../models";
import {
  customerInitialValue,
  getAccountBalance,
  getCurrencyValueEntity,
  getItemTaxPercentageValue,
  handleCalculateNewItemDiscountValuesChange,
  handleCalculateNewItemValuesChange,
  handleChangeTransactionDiscount,
  handleChangeTransactionDiscountRatio,
  handleTransactionTotalValuesByTransactionItems,
  updateTransactionItemsCurrencyValueAndReCalculateAllValues,
} from "../common/businessLogic/transactionBl";
import {
  ModelDialogBox,
  AddCustomer,
  LabelBox,
  InputDatePicker,
  ButtonBox,
} from "../..";
import { getItemSalesPrice } from "../common/businessLogic/commonBl";

export const RegisterTransactionHeader: FC<{
  formik: any;
  data: TransactionDetailResponseModel;
  transactionItemObject: TransactionItemResponseModel;
  userTransactionSetting: UserTransactionsSettingResponseModel;
  companySettings: CompanySettingModel;
  transactionType: DailyTransactionTypeEnum;
  isSalesComponent: boolean;
  beneficiaryList: LookupItemModel[];
  itemList: LookupItemModel[];
  calcTypeList: LookupItemModel[];
  currencyList: LookupItemModel[];
  paymentTypeList: LookupItemModel[];
  storeEmployeeList: LookupItemModel[];
  costCenterList: LookupItemModel[];
  setData: Dispatch<SetStateAction<TransactionDetailResponseModel>>;
  setTransactionItemObject: Dispatch<
    SetStateAction<TransactionItemResponseModel>
  >;
  setBeneficiaryList: Dispatch<SetStateAction<LookupItemModel[]>>;
  customerSelectBoxMultiselectRef: React.MutableRefObject<any>;
  storeEmployeeSelectBoxMultiselectRef: React.MutableRefObject<any>;
  calcTypeSelectBoxMultiselectRef: React.MutableRefObject<any>;
  costCenterSelectBoxMultiselectRef: React.MutableRefObject<any>;
  paymentTypeSelectBoxMultiselectRef: React.MutableRefObject<any>;
  currencySelectBoxMultiselectRef: React.MutableRefObject<any>;
}> = ({
  formik,
  data,
  transactionItemObject,
  userTransactionSetting,
  companySettings,
  transactionType,
  isSalesComponent,
  beneficiaryList,
  itemList,
  calcTypeList,
  currencyList,
  paymentTypeList,
  storeEmployeeList,
  costCenterList,
  setData,
  setTransactionItemObject,
  setBeneficiaryList,
  customerSelectBoxMultiselectRef,
  storeEmployeeSelectBoxMultiselectRef,
  calcTypeSelectBoxMultiselectRef,
  costCenterSelectBoxMultiselectRef,
  paymentTypeSelectBoxMultiselectRef,
  currencySelectBoxMultiselectRef,
}) => {
  //#region variables
  const discountPercentageMaxValue: number = 50;
  //#endregion
  //#region state
  const [showAddBeneficiaryModel, setShowAddBeneficiaryModel] = useState(false);
  const [customerObject] =
    useState<CustomerResponseModel>(customerInitialValue);
  //#endregion
  //#region functions
  const updateTransactionItemObjectState = async (
    key?: string | null,
    value?: any | null,
    keyValues?: KeyValueStateModel[] | null
  ) => {
    await updateStateDynamically(
      setTransactionItemObject,
      transactionItemObject,
      key,
      value,
      keyValues
    );
  };
  //#endregion
  //#region html
  return (
    <>
      <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
        <TextBox
          labelName={getLabelName("Code")}
          isReadOnly={true}
          inputName={"Code"}
          errorText={formik.errors.Code}
          inputValue={data.Code}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className="col-xl-8 col-lg-8 flex-form-control">
          <SelectBox
            id="customersSelectBox"
            labelName={
              isSalesComponent
                ? getLabelName("Customer")
                : getLabelName("Supplier")
            }
            isSingleSelect={true}
            selectedValues={
              data.Customer_ID !== null && data.Customer_ID !== undefined
                ? [data.Customer_ID.toString()]
                : []
            }
            source={beneficiaryList}
            //multiselectRef={customerSelectBoxMultiselectRef}
            //@ts-ignore
            multiselectRef={(node: MutableRefObject<any>) =>
              node ? (customerSelectBoxMultiselectRef.current = node) : null
            }
            onStatusChange={async (e: LookupItemModel) => {
              const val = getLookUpItemValue(e);
              let balance: number = 0;
              if (val !== null && val !== undefined) {
                balance = await getAccountBalance(
                  Number(val || 0),
                  data.Currency_ID !== null && data.Currency_ID !== undefined
                    ? data.Currency_ID
                    : null,
                  beneficiaryList
                );
              }
              await updateStateDynamically(setData, data, null, null, [
                { key: "Customer_ID", value: val },
                { key: "balance", value: balance },
              ]);
            }}
            errorText={formik.errors.Customer_ID}
            controlSize={"w-100 w-calc"}
          ></SelectBox>
          <ButtonBox
            variant=""
            className="btn-gradient-primary btn-field-group"
            onClick={() => {
              setShowAddBeneficiaryModel(true);
            }}
          >
            <span className="sr-only">Add</span>
            <i className="mdi mdi-plus"></i>
          </ButtonBox>
        </div>
        <ModelDialogBox
          isXCloseButtonVisible={true}
          isModelVisible={showAddBeneficiaryModel}
          isCloseButtonVisible={false}
          isEscapeCloseEnabled={true}
          title={
            isSalesComponent
              ? getLabelName("Customers")
              : getLabelName("Supplier")
          }
          size="lg"
          onCloseEvent={() => {
            setShowAddBeneficiaryModel(false);
          }}
        >
          <AddCustomer
            type={
              isSalesComponent
                ? SupplierCustomerTypeEnum.Customer
                : SupplierCustomerTypeEnum.Supplier
            }
            request={customerObject}
            displayMode={
              CustomerComponentDisplayModeEnum.registerTransactionMode
            }
            onActionEvent={async function (
              o: RequestActionModel
            ): Promise<void> {
              if (o.request !== null && o.request !== undefined) {
                setBeneficiaryList([
                  ...beneficiaryList,
                  {
                    value: o.request.ID,
                    name: o.request.Name_En,
                    nameAr: o.request.Name,
                    otherValue: {
                      accountId: o.request.Account_ID,
                    },
                    isAdded: true,
                  },
                ]);
                setShowAddBeneficiaryModel(false);
              }
            }}
            formId={isSalesComponent ?PageEnum.Customers : PageEnum.Suppliers}
          />
        </ModelDialogBox>
        <TextBox
          labelName={getLabelName("Balance")}
          inputValue={data.balance}
          isReadOnly={true}
          inputName="balance"
        />
        {calcTypeList.length > 1 && (
          <SelectBox
            id="calcTypesSelectBox"
            labelName={getLabelName("Calc Type")}
            isSingleSelect={true}
            selectedValues={
              data.CalcType_ID !== null && data.CalcType_ID !== undefined
                ? [data.CalcType_ID.toString()]
                : []
            }
            source={calcTypeList}
            //multiselectRef={calcTypeSelectBoxMultiselectRef}
            //@ts-ignore
            multiselectRef={(node: MutableRefObject<any>) =>
              node ? (calcTypeSelectBoxMultiselectRef.current = node) : null
            }
            onStatusChange={async (e: LookupItemModel) => {
              const val = getLookUpItemValue(e);
              if (val !== null && val !== undefined) {
                console.log("res_y_2");
                if (
                  transactionItemObject.ItemInstore_ID !== null &&
                  transactionItemObject.ItemInstore_ID !== undefined &&
                  transactionItemObject.ItemInstore_ID > 0
                ) {
                  const itemSalePrice: number =
                    transactionItemObject.ItemInstore_ID !== null
                      ? getItemSalesPrice(
                          transactionItemObject.ItemUnit_ID || 0,
                          transactionType,
                          val !== null ? Number(val) : null,
                          itemList,
                          calcTypeList,
                          userTransactionSetting
                        )
                      : 0;
                  const itemPriceCalculatedValues =
                    handleCalculateNewItemValuesChange(
                      itemList,
                      transactionItemObject.ItemUnit_ID || 0,
                      itemSalePrice,
                      data.ValueCurrency,
                      true,
                      false
                    );
                  const discountCalculatedValues =
                    handleCalculateNewItemDiscountValuesChange(
                      transactionItemObject.ItemDiscount,
                      data.ValueCurrency,
                      false
                    );
                  const itemTaxPercentage =
                    transactionItemObject.ItemInstore_ID !== null
                      ? getItemTaxPercentageValue(
                          transactionItemObject.ItemUnit_ID || 0,
                          itemList
                        )
                      : 0;
                  await updateTransactionItemObjectState(null, null, [
                    { key: "TaxPercentge", value: itemTaxPercentage },
                    {
                      key: "Unit_Price",
                      value: itemPriceCalculatedValues.UnitPrice,
                    }, //itemSalePrice
                    {
                      key: "UnitPriceWithTaxCalculated",
                      value: itemPriceCalculatedValues.UnitPriceWithTax,
                    },
                    {
                      key: "Unit_PriceInCurrencyCalculated",
                      value: itemPriceCalculatedValues.UnitPriceInCurrency,
                    },
                    {
                      key: "UnitPriceWithTaxInCurrency",
                      value:
                        itemPriceCalculatedValues.UnitPriceWithTaxInCurrency,
                    },
                    {
                      key: "ItemDiscount",
                      value: discountCalculatedValues.ItemDiscount,
                    },
                    {
                      key: "ItemDiscountInCurrency",
                      value: discountCalculatedValues.ItemDiscountInCurrency,
                    },
                  ]);
                }
                await updateStateDynamically(setData, data, "CalcType_ID", val);
              } else {
                await updateStateDynamically(setData, data, null, null, [
                  { key: "CalcType_ID", value: val },
                ]);
              }
            }}
            errorText={formik.errors.CalcType_ID}
          />
        )}
        {userTransactionSetting.ShowPaymentType && (
          <SelectBox
            id="paymentTypesSelectBox"
            labelName={getLabelName("Payment Type")}
            isSingleSelect={true}
            selectedValues={
              data.PaymentType_ID !== null && data.PaymentType_ID !== undefined
                ? [data.PaymentType_ID.toString()]
                : []
            }
            source={paymentTypeList}
            //multiselectRef={paymentTypeSelectBoxMultiselectRef}
            //@ts-ignore
            multiselectRef={(node: MutableRefObject<any>) =>
              node ? (paymentTypeSelectBoxMultiselectRef.current = node) : null
            }
            onStatusChange={async (e: LookupItemModel) => {
              const val = getLookUpItemValue(e);
              await updateStateDynamically(setData, data, null, null, [
                { key: "PaymentType_ID", value: val },
              ]);
            }}
            errorText={formik.errors.PaymentType_ID}
          />
        )}
        {currencyList.length > 1 && (
          <SelectBox
            id="currencySelectBox"
            labelName={getLabelName("Currency")}
            isSingleSelect={true}
            selectedValues={
              data.Currency_ID !== null && data.Currency_ID !== undefined
                ? [data.Currency_ID.toString()]
                : []
            }
            source={currencyList}
            //multiselectRef={currencySelectBoxMultiselectRef}
            //@ts-ignore
            multiselectRef={(node: MutableRefObject<any>) =>
              node ? (currencySelectBoxMultiselectRef.current = node) : null
            }
            onStatusChange={async (e: LookupItemModel) => {
              const val = getLookUpItemValue(e);
              if (val !== null) {
                const currencyValueEntity: number = getCurrencyValueEntity(
                  Number(val || 0),
                  currencyList
                );
                const balance: number = await getAccountBalance(
                  data.Customer_ID !== null && data.Customer_ID !== undefined
                    ? data.Customer_ID
                    : 0,
                  Number(val || 0),
                  beneficiaryList
                );
                const itemPriceCalculatedValues =
                  handleCalculateNewItemValuesChange(
                    itemList,
                    transactionItemObject.ItemInstore_ID || 0,
                    transactionItemObject.Unit_Price,
                    currencyValueEntity,
                    true,
                    false
                  );
                const discountCalculatedValues =
                  handleCalculateNewItemDiscountValuesChange(
                    transactionItemObject.ItemDiscount,
                    currencyValueEntity,
                    false
                  );
                await updateTransactionItemObjectState(null, null, [
                  {
                    key: "Unit_Price",
                    value: itemPriceCalculatedValues.UnitPrice,
                  }, //itemSalePrice
                  {
                    key: "UnitPriceWithTaxCalculated",
                    value: itemPriceCalculatedValues.UnitPriceWithTax,
                  },
                  {
                    key: "Unit_PriceInCurrencyCalculated",
                    value: itemPriceCalculatedValues.UnitPriceInCurrency,
                  },
                  {
                    key: "UnitPriceWithTaxInCurrency",
                    value: itemPriceCalculatedValues.UnitPriceWithTaxInCurrency,
                  },
                  {
                    key: "ItemDiscount",
                    value: discountCalculatedValues.ItemDiscount,
                  },
                  {
                    key: "ItemDiscountInCurrency",
                    value: discountCalculatedValues.ItemDiscountInCurrency,
                  },
                  { key: "ValueOfCurrency", value: currencyValueEntity },
                ]);

                const transactionItems =
                  updateTransactionItemsCurrencyValueAndReCalculateAllValues(
                    data.TransactionDetaillist,
                    currencyValueEntity
                  );

                const transactionValues: TransactionTotalValuesModel =
                  handleTransactionTotalValuesByTransactionItems(
                    transactionItems
                  );
                await updateStateDynamically(setData, data, null, null, [
                  { key: "Currency_ID", value: val },
                  { key: "ValueCurrency", value: currencyValueEntity },
                  { key: "balance", value: balance },
                  { key: "TotalMony", value: transactionValues.totalMoney },
                  { key: "TotalNet", value: transactionValues.totalNet },
                  { key: "TotalTax", value: transactionValues.totalTax },
                  { key: "Discount", value: transactionValues.totalDiscount },
                  {
                    key: "TotalMonyInCurrency",
                    value: transactionValues.totalMoneyInCurrency,
                  },
                  {
                    key: "TotalNetInCurrency",
                    value: transactionValues.totalNetInCurrency,
                  },
                  {
                    key: "TotalTaxInCurrency",
                    value: transactionValues.totalTaxInCurrency,
                  },
                  {
                    key: "DiscountInCurrency",
                    value: transactionValues.totalDiscountInCurrency,
                  },
                  {
                    key: "discountRatio",
                    value: transactionValues.totalDiscountPercentage,
                  },
                  { key: "TransactionDetaillist", value: transactionItems },
                ]);
              } else {
                await updateStateDynamically(setData, data, null, null, [
                  { key: "Currency_ID", value: val },
                ]);
              }
            }}
            errorText={formik.errors.Currency_ID}
          />
        )}
        {storeEmployeeList.length > 1 && (
          <SelectBox
            id="storeEmployeesSelectBox"
            labelName={getLabelName("Employee")}
            isSingleSelect={true}
            selectedValues={
              data.Emp_ID !== null && data.Emp_ID !== undefined
                ? [data.Emp_ID.toString()]
                : []
            }
            source={storeEmployeeList}
            //multiselectRef={storeEmployeeSelectBoxMultiselectRef}
            //@ts-ignore
            multiselectRef={(node: MutableRefObject<any>) =>
              node
                ? (storeEmployeeSelectBoxMultiselectRef.current = node)
                : null
            }
            onStatusChange={async (e: LookupItemModel) => {
              const val = getLookUpItemValue(e);
              await updateStateDynamically(setData, data, null, null, [
                { key: "Emp_ID", value: val },
              ]);
            }}
            errorText={formik.errors.Emp_ID}
          />
        )}
        {userTransactionSetting.ShowCostCenter && (
          <SelectBox
            id="costCenterSelectBox"
            labelName={getLabelName("CostCenter")}
            isSingleSelect={true}
            selectedValues={
              data.CostCenter_ID !== null && data.CostCenter_ID !== undefined
                ? [data.CostCenter_ID.toString()]
                : []
            }
            source={costCenterList}
            //multiselectRef={costCenterSelectBoxMultiselectRef}
            //@ts-ignore
            multiselectRef={(node: MutableRefObject<any>) =>
              node ? (costCenterSelectBoxMultiselectRef.current = node) : null
            }
            onStatusChange={async (e: LookupItemModel) => {
              const val = getLookUpItemValue(e);
              await updateStateDynamically(setData, data, null, null, [
                { key: "CostCenter_ID", value: val },
              ]);
            }}
            errorText={formik.errors.CostCenter_ID}
          />
        )}
        <TextBox
          labelName={getLabelName("Discount")}
          isReadOnly={!userTransactionSetting.EnableDiscount}
          type="number"
          inputName={"DiscountInCurrency"}
          inputValue={data.DiscountInCurrency}
          onChange={async (e: any) => {
            const valueInCurrency =
              e.target.value != null ? Number(e.target.value) : 0;
            const value =
              valueInCurrency === 0
                ? 0
                : getOriginalValueInCurrency(
                    valueInCurrency,
                    data.ValueCurrency
                  );
            await updateStateDynamically(setData, data, null, null, [
              { key: "DiscountInCurrency", value: valueInCurrency },
              {
                key: "Discount",
                value: value,
              },
            ]);
          }}
          onBlur={async () => {
            await handleChangeTransactionDiscount(setData, data);
          }}
        />
        <TextBox
          labelName={getLabelName("Discount %")}
          isReadOnly={!userTransactionSetting.EnableDiscount}
          type="number"
          inputName={"discountRatio"}
          inputValue={data.discountRatio}
          onChange={async (e: any) => {
            const value = e.target.value != null ? Number(e.target.value) : 0;
            if (value <= discountPercentageMaxValue) {
              await updateStateDynamically(setData, data, null, null, [
                { key: "discountRatio", value: value },
              ]);
            }
          }}
          onBlur={async () => {
            await handleChangeTransactionDiscountRatio(setData, data);
          }}
        />
        <InputDatePicker
          selectedDate={new Date(data.Date.toString())}
          timeMode={InputDatePickerTimeDisplayModeEnum.None}
          timeIntervals={15}
          className="form-control"
          InputLabel={"Date"}
          // selectsStart
          onChange={(date: Date) => {
            setData({
              ...data,
              Date: date,
            });
          }}
          maxDate={new Date()}
        />
        <TextBox
          inputName="totalMoneyInCurrency"
          labelName={getLabelName("Total")}
          inputValue={data.TotalMonyInCurrency}
          formatType={"decimal"}
          isReadOnly={true} isVisible={false}
        />
        {companySettings.ApplyTax && (
          <TextBox
            labelName={getLabelName("Tax_Value")}
            inputValue={data.TotalTaxInCurrency}
            inputName="taxValueInCurrency"
            isReadOnly={true} isVisible={false}
          />
        )}
        <TextBox
          inputName="netBillInCurrency"
          labelName={getLabelName("Net Bill")}
          inputValue={data.TotalNetInCurrency}
          isReadOnly={true} isVisible={false}
        />
      </div>
      <div className="row mt-4 row-cols-1 row-cols-xxl-12 row-cols-xl-12 row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-md-12 g-sm-12">
        <TextBox
          labelName={getLabelName("Notes")}
          type="textarea"
          inputName={"Note"}
          inputValue={data.Note}
          onChange={async (e: any) => {
            await updateStateDynamically(
              setData,
              data,
              "Notes",
              e.target.value
            );
          }}
          //placeHolder="Type Here"
        />
      </div>
    </>
  );
  //#endregion
};
