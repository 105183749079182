import {FC} from "react";
import {ControlSizeType, FormatValueType, LabelSizeType} from "../../../models";
import {formatValue} from "../../../utils";

export const LabelBox: FC<{
    labelName: string;
    inputValue:
        | string
        | ReadonlyArray<string>
        | number
        | boolean
        | undefined
        | null;
    controlSize?: ControlSizeType;
    labelSize?: LabelSizeType;
    id?: string | undefined;
    formatType?: FormatValueType;
}> = ({
          labelName,
          inputValue,
          controlSize = "col flex-form-control",
          labelSize = "form-label", id,formatType = "none"
      }) => {
    const uniqueId = id || `label_title_${Math.random()}`;
    return (
        <>
            <div
                className={controlSize}
            >
                <label id={`${uniqueId}_lt`} className={labelSize}>{labelName}</label>
                <label id={`${uniqueId}_lv` }
                       className="form-control read-only">{formatValue(inputValue,formatType)}</label>
            </div>
        </>
    );
};
