import React, {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  GridTable,
  ConfirmModelDialogBox,
  ErrorValidationBox,
  LoadingBox,
  PrivilegesChecker,
  SearchBox,
} from "..";
import { useSearchedAccounts } from "../../hooks";
import setAccountsTable from "./uiHelper/columns";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import {
  ActionButtonsModel,
  ActionTypeEnum, PageEnum,
  RequestActionModel,
  ToastModel,
  ValidationErrorModel,
} from "../../models";
import { deleteAccount } from "../../serviceBroker/accountApiServiceBroker";

interface AccountListProps {
  parentID: number | null;
  setToastModel: Dispatch<SetStateAction<ToastModel>>;
  setIsAccountLoading: (isLoading: boolean) => void;
  handleDeleteAccount: (id: number) => void;
  fetchAccountInfoByID: (accountID: number | null) => Promise<void>;
  reFetchAccounts: boolean;
}

const AccountsList: React.FC<AccountListProps> = ({
  parentID,
  setToastModel,
  setIsAccountLoading,
  handleDeleteAccount,
  fetchAccountInfoByID,
  reFetchAccounts,
}) => {
  //#region state
  const {
    setPageNumber,
    setPageSize,
    currentPage,
    currentPageSize,
    totalRows,
    searchParams,
    resetSearchParams,
    searchedAccounts,
    setSearchParams,
    areAccountsLoading,
    fetchAccounts,
  } = useSearchedAccounts();
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [deletedAccountID, setDeletedAccountID] = useState<number | null>(null);
  const [searchedWord, setSearchedWord] = useState("");
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);

  //#endregion
  //#region useEffect
  useEffect(() => {
    resetSearchParams();
    setSearchedWord("");
    setSearchParams({ "searchItem.parentId": parentID });
    setValidationErrors([]);
  }, [parentID]);
  useEffect(() => {
    fetchAccounts().then(() => {});
  }, [reFetchAccounts]);
  //#endregion
  //#region variables
  const deleteActions: (deletedId: number) => ActionButtonsModel[] = (
    deletedID
  ) => {
    return [
      {
        text: getLabelName("yes"),
        onClick: async () => {
          await handleVoucherDeletion(deletedID);
        },
      },
      {
        text: getLabelName("No"),
        onClick: () => {
          setShowDeleteModel(false);
          setDeletedAccountID(null);
        },
      },
    ];
  };
  //#endregion
  //#region functions
  const handleEdit = async (id: number | null) => {
    await fetchAccountInfoByID(id);
  };
  const handleSearch = (name: string) => {
    return setSearchParams({ ...searchParams, "searchItem.accountName": name });
  };
  const handleVoucherDeletion = async (_id: number) => {
    try {
      setIsAccountLoading(true);
      await deleteAccount(deletedAccountID as number);
      handleDeleteAccount(deletedAccountID as number);
      await fetchAccounts();
      setToastModel({ show: true });
    } catch (err) {
      setValidationErrors(err as ValidationErrorModel[]);
    } finally {
      setShowDeleteModel(false);
      setDeletedAccountID(null);
      setIsAccountLoading(false);
    }
  };
  const onActionEvent = async (o: RequestActionModel) => {
    switch (o.action) {
      case ActionTypeEnum.Update:
        await handleEdit(o.id || o.request.id);
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {areAccountsLoading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      <PrivilegesChecker formId={PageEnum.AccountsTree} action="EnableSearch">
        <SearchBox
          onChangeHandler={(e: FormEvent<HTMLInputElement>) =>
            setSearchedWord(e.currentTarget.value)
          }
          onSearchHandler={handleSearch.bind(null, searchedWord)}
          searchedWord={searchedWord}
          name="account"
        />
      </PrivilegesChecker>

      <div className="row row-cols-1">
        <ConfirmModelDialogBox
          isModelVisible={showDeleteModel}
          onCloseEvent={() => {
            setShowDeleteModel(false);
            setDeletedAccountID(null);
          }}
          actions={deleteActions(deletedAccountID!)}
        >
          <>{getLabelName("Are You Sure You Want Delete ")}</>
        </ConfirmModelDialogBox>
        <GridTable
          columns={setAccountsTable({
            handleDeletion: (id) => {
              setShowDeleteModel(true);
              setDeletedAccountID(id);
            },
            handleEdit,
          })}
          data={searchedAccounts}
          columnsProps={{
            actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
            isArabic: isArabicCurrentLanguage(),
            onActionEvent,
          }}
          totalRows={totalRows}
          currentPage={currentPage as number}
          pageSize={currentPageSize as number}
          onCurrentPageChange={setPageNumber}
          onPageSizeChange={setPageSize}
          paginationType="server"
          formId={PageEnum.AccountsTree}
        />
      </div>
    </>
  );
  //#endregion
};
export default AccountsList;
