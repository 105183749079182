import { FC, useEffect } from "react";
import {
  ActionTypeEnum,
  HasFormIdModel,
  RequestActionModel,
  TableActionColumnsStyleEnum,
} from "../../../models";
import { isArabicCurrentLanguage } from "../../../utils";
import { useStoreTransferState } from "./helper/state/storeTransferState";
import { searchStoreTransferTransactions } from "../../../serviceBroker/moveItemsTransactionApiServiceBroker";
import { GridTable } from "../..";

interface StoreTransferTransactionListProps extends HasFormIdModel {
  onActionEvent: (o: RequestActionModel) => void;
  isRefresh: boolean;
}
export const StoreTransferRegisterTransactionList: FC<
  StoreTransferTransactionListProps
> = ({ onActionEvent = () => {}, isRefresh = false, formId }) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const defaultPageSize: number = 50;
  //#endregion
  //#region state
  const {
    transactionList,
    setTransactionList,
    totalRows,
    setTotalRows,
    searchRequest,
    setSearchRequest,
  } = useStoreTransferState();
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getTransactions();
    };
    fillData().then(() => {});
  }, [
    searchRequest.pageNumber,
    searchRequest.pageSize,
    searchRequest.searchGuid,
  ]);
  useEffect(() => {
    if (isRefresh) {
      const fillData = async () => {
        await getTransactions();
      };
      fillData().then(() => {});
      onActionEvent({ action: ActionTypeEnum.Refresh, request: false });
    }
  }, [isRefresh]);
  //#endregion
  //#region functions
  const getTransactions = async () => {
    onActionEvent({ action: ActionTypeEnum.EnableLoader });
    const result = await searchStoreTransferTransactions(searchRequest);
    setTransactionList(result?.Result || []);
    setTotalRows(result?.TotalRecoredCount || 0);
    onActionEvent({ action: ActionTypeEnum.DisableLoader });
  };
  const onCurrentPageChange = async (pageNumber: number) => {
    if (pageNumber !== searchRequest.pageNumber) {
      const obj = { ...searchRequest };
      obj.pageNumber = pageNumber;
      setSearchRequest(obj);
    }
  };
  const onPageSizeChange = async (pageSize: number) => {
    if (pageSize !== searchRequest.pageSize) {
      const obj = { ...searchRequest };
      obj.pageSize = pageSize;
      setSearchRequest(obj);
    }
  };
  //#endregion
  //#region html
  return (
    <>
      <GridTable
        data={transactionList}
        totalRows={totalRows}
        currentPage={searchRequest.pageNumber || 1}
        pageSize={searchRequest.pageSize || defaultPageSize}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationType="server"
        columnsProps={{
          actions: [
            ActionTypeEnum.Update,
            ActionTypeEnum.DeleteStart,
            ActionTypeEnum.Print,
          ],
          onActionEvent,
          isArabic,
        }}
        formId={formId}
        style={TableActionColumnsStyleEnum.Dropdown}
        // subHeaderComponent={getPagePrivileges().search && subHeaderComponent}
      />
    </>
  );
  //#endregion
};
