import React, { useRef } from "react";
import { getLabelName } from "../../utils";
import { SelectBox, InputDatePicker, LoadingBox, ButtonBox } from "..";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  useAccountsByType,
  useMainAccountTypes,
  useStoredUsers,
} from "../../hooks";
import {
  ActionTypeEnum,
  InputDatePickerTimeDisplayModeEnum,
  RequestActionModel,
  SelectItemModel,
  VoucherTransactionSearchFormModel,
} from "../../models";
interface VoucherSearchFormProps {
  onActionEvent: (o: RequestActionModel) => void;
}

const selectionOptionSchema = yup
  .object()
  .shape({ label: yup.string(), value: yup.string() });
const initialValues: VoucherTransactionSearchFormModel = {
  fromDate: null,
  toDate: null,
  user: null,
  toAccount: null,
  accountType: null,
};
const validationSchema = yup.object({
  fromDate: yup.date().nullable(),
  toDate: yup.date().nullable(),
  user: selectionOptionSchema.nullable(),
  accountType: selectionOptionSchema.required("required").nullable(),
  toAccount: selectionOptionSchema.required("required").nullable(),
});
export const AcComplexTransactionSearch: React.FC<VoucherSearchFormProps> = ({
  onActionEvent,
}) => {
  //#region states
  //#region states: custom hooks
  const { accountTypes, areAccountTypesLoading } = useMainAccountTypes();
  const { storedUsersList, usersLoading } = useStoredUsers();
  const { accounts, areAccountsLoading, setCurrentTypeId } =
    useAccountsByType();
  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: () => {},
  });
  //#endregion states: custom hooks
  //#region states: state-driven variables
  const isDataLoading =
    areAccountTypesLoading || areAccountsLoading || usersLoading;
  //#endregion states: state-driven variables
  //#endregion
  //#region Refs to clear value of select box
  const accountType = useRef<any>(null);
  const toAccount = useRef<any>(null);
  const user = useRef<any>(null);
  //#endregion Refs to clear value of select box
  //#region handlers
  const resetSearchParams = () => {
    setCurrentTypeId(null);
    formik.resetForm();
    formik.setErrors({});
    if (user.current) user.current.clearValue();
    if (toAccount.current) toAccount.current.clearValue();
    if (toAccount.current) accountType.current.clearValue();
    onActionEvent({ action: ActionTypeEnum.Clear });
  };
  //#endregion handlers
  //#region html
  return (
    <>
      {isDataLoading && <LoadingBox />}
      <form
        onSubmit={async (e: React.FormEvent) => {
          e.preventDefault();
          const errors = await formik.validateForm();
          formik.setErrors(errors);
          if (Object.keys(errors).length !== 0) return;
          onActionEvent({
            action: ActionTypeEnum.Search,
            request: formik.values,
          });
        }}
      >
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
          <SelectBox
            labelName={getLabelName("type")}
            inputName="type"
            multiselectRef={accountType}
            selectedValues={
              formik.values.accountType === null
                ? null
                : [formik.values.accountType.value]
            }
            source={accountTypes}
            onStatusChange={(selectedValue: SelectItemModel) => {
              if (selectedValue === null) return setCurrentTypeId(null);
              if (selectedValue.value !== formik.values.accountType?.value) {
                formik.setFieldValue("accountType", selectedValue);
                setCurrentTypeId(+selectedValue.value);
              }
            }}
            isSingleSelect={true}
            errorText={getLabelName(formik.errors.accountType as string)}
          />
          {formik.values.accountType && (
            <SelectBox
              labelName={getLabelName("toAccount")}
              inputName="toAccount"
              multiselectRef={toAccount}
              selectedValues={
                formik.values.toAccount === null
                  ? null
                  : ["" + formik.values.toAccount.value]
              }
              source={accounts}
              onStatusChange={(selectedValue: SelectItemModel) => {
                formik.setFieldValue("toAccount", selectedValue);
              }}
              isSingleSelect={true}
              errorText={getLabelName(formik.errors.toAccount as string)}
            />
          )}
          <SelectBox
            labelName={getLabelName("user")}
            inputName="user"
            multiselectRef={user}
            selectedValues={
              formik.values.user === null
                ? null
                : ["" + formik.values.user.value]
            }
            source={storedUsersList}
            onStatusChange={(selectedValue: SelectItemModel) => {
              formik.setFieldValue("user", selectedValue);
            }}
            isSingleSelect={true}
            errorText={getLabelName(formik.errors.user as string)}
          />
        </div>
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4 mb-3">
          <InputDatePicker
            id="datePicker"
            selectedDate={formik.values.fromDate || undefined}
            className="form-control"
            InputLabel="fromDate"
            timeMode={InputDatePickerTimeDisplayModeEnum.TimeSelect}
            name="from_date"
            onChange={(date: Date) => {
              formik.values.fromDate = date;
              formik.setFieldValue("fromDate", date);
            }}
            maxDate={new Date()}
          />
          <InputDatePicker
            id="datePicker"
            timeMode={InputDatePickerTimeDisplayModeEnum.TimeSelect}
            selectedDate={formik.values.toDate || undefined}
            className="form-control"
            InputLabel="toDate"
            name="to_date"
            onChange={(date: Date) => {
              formik.values.toDate = date;
              formik.setFieldValue("toDate", date);
            }}
            maxDate={formik.values.fromDate}
          />
        </div>

        <div className="row mt-3 ">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end ">
            <ButtonBox
              type="submit"
              className="btn-gradient-primary mx-3 btn-fw"
              iconType="magnify"
            >
              {getLabelName("search")}
            </ButtonBox>
            <ButtonBox
              iconType="receipt"
              onClick={resetSearchParams}
              type="button"
              variant="danger"
              className="btn-fw"
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
