import React, {FC, useState} from "react";
import {ButtonBox, ErrorValidationBox, ModelDialogBox, GridTable} from "../..";
import {TableColumn} from "react-data-table-component";
import {
    ActionTypeEnum,
    RequestActionModel, RowStateEnum,
    TransactionDetailResponseModel,
    TransactionItemResponseModel, ValidationErrorModel
} from "../../../models";
import {
    getRefundTransactionItemsColumns,
} from "../common/uiHelper/dataTableColumns";
import {getLabelName, isArabicCurrentLanguage} from "../../../utils";

export const RefundItemList: FC<{
    request: TransactionDetailResponseModel,
    onActionEvent: (o: RequestActionModel) => void;
}> = ({
          request, onActionEvent = () => {
    }
      }) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    //#endregion
    //#region state
    let [data, setData] = useState<TransactionDetailResponseModel>(
        request
    );
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>([]);
    const columns: TableColumn<TransactionItemResponseModel>[] =
        [...getRefundTransactionItemsColumns(isArabic, setData, data)];
    const [_, setShowModal] = useState(false);
    //#endregion
    //#region html
    return (
        <ModelDialogBox
            isXCloseButtonVisible={true}
            isModelVisible={true}
            isCloseButtonVisible={false}
            isEscapeCloseEnabled={true}
            title={""}
            size="xl"
            onCloseEvent={() => {
                setShowModal(false);
                onActionEvent({
                    action: ActionTypeEnum.Ignore
                });
            }}
        >
            {<ErrorValidationBox errors={validationErrors}/>}
            <GridTable
                columns={columns}
                data={data.TransactionDetaillist}
                totalRows={1000}
                currentPage={1}
                pageSize={10000}
                paginationType="none"
            />
            <div className="row py-2">
                <div className="col-12 d-flex justify-content-end">
                    <ButtonBox
                    iconType="dots-horizontal"
                        type="button"
                        variant=""
                        className="btn-gradient-primary mx-3 btn-fw"
                        onClick={() => {
                            const items = data.TransactionDetaillist.filter(p => p.rowState === Number(RowStateEnum.Add));
                            if (items !== null && items.length !== 0) {
                                onActionEvent({
                                    request: {...data, TransactionDetaillist: items},
                                    action: ActionTypeEnum.TransactionRefund
                                });
                            } else {
                                setValidationErrors([{
                                    MessageEn: getLabelName("refund.items"),
                                    MessageAr: getLabelName("refund.items")
                                }]);
                            }
                        }}
                    >
                        {getLabelName("Continue")}
                    </ButtonBox>
                    <ButtonBox
                    iconType="close-circle"
                        variant="danger"
                        type="button"
                        className="btn-fw"
                        onClick={() => {
                            onActionEvent({action: ActionTypeEnum.Ignore});
                        }}
                    >
                        {getLabelName("Cancel")}
                    </ButtonBox>
                </div>
            </div>
        </ModelDialogBox>
    )
    //#endregion
}