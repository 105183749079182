import React, { useState } from "react";
import { getLabelName, isArabicCurrentLanguage } from "../../../../../utils";
import {LoadingBox, ErrorValidationBox, ButtonBox} from "../../../..";
import DataTable from "react-data-table-component";
import { generateGridSettingsColumns } from "../utils/generateGridSettingsColumns";
import { saveGridColumns } from "../../../../../serviceBroker/commonApiServiceBroker";
import {
  GridColumnsResponseModel,
  RowStateEnum,
  ValidationErrorModel,
} from "../../../../../models";

interface GridColumnsSettingsProps {
  onComplete: () => void;
  gridId: number;
  fetchGridColumns: () => Promise<void>;
  isColumnsLoading: boolean;
  columnsSettings: GridColumnsResponseModel[];
}

const RenderGridColumnsSettings: React.FC<GridColumnsSettingsProps> = ({
  onComplete,
  fetchGridColumns,
  columnsSettings,
  isColumnsLoading,
}) => {
  //#region state
  const [columns, setColumns] =
    useState<GridColumnsResponseModel[]>(columnsSettings);
  const [errors, setErrors] = useState<ValidationErrorModel[]>([]);
  const [modifiedGridColumnsIDs, setModifiedGridColumnsIDs] = useState<
    number[]
  >([]);
  const isArabic = isArabicCurrentLanguage();
  const [isSaving, setIsSaving] = useState(false);
  const isLoading = isColumnsLoading || isSaving;
  //#endregion
  //#region functions
  const validatorGridColumns = (columns: GridColumnsResponseModel[]) => {
    return validateTotalWidthBelowHundred(columns);
  };
  const validateTotalWidthBelowHundred = (
      columns: GridColumnsResponseModel[]
  ) => {
    const totalWidth = columns
        .filter(({ Visable }) => Visable)
        .reduce((acc, cur) => acc + Number(cur.Width), 0);
    return totalWidth <= 100;

  };
  const handleFieldChange = (
      fieldName: string,
      value: string | boolean,
      ID: number
  ) => {
    // update columns state
    setColumns((prev) =>
        prev.map((field) => {
          if (field.ID === ID)
            return {
              ...field,
              [fieldName]: value,
            };
          else return field;
        })
    );
    // add column to modified columns only
    let isModifiedColumn = modifiedGridColumnsIDs.find(
        (settingID) => settingID === ID
    );
    if (!isModifiedColumn) {
      setModifiedGridColumnsIDs((prev) => [...prev, ID]);
    }
  };
  const handleSaveModifiedColumns = async () => {
    if (modifiedGridColumnsIDs.length === 0) return onComplete();
    try {
      const gridColumns = columns
          .filter(({ ID }) => modifiedGridColumnsIDs.includes(ID))
          .map((columns) => ({
            ...columns,
            rowState: RowStateEnum.Update,
          }));
      const areColumnsValid = validatorGridColumns(columns);
      if (!areColumnsValid) {
        setErrors([
          {
            MessageAr: "total width cannot be more than 100%",
            MessageEn: "total width cannot be more than 100%",
          },
        ]);
        return;
      }
      setIsSaving(true);
      await saveGridColumns(gridColumns);
      onComplete();
      await fetchGridColumns();
      setIsSaving(false);
    } catch (err) {
      console.log(err);
      onComplete();
    }
  };
//#endregion
  //#region html
  return (
    <LoadingBox isLoading={isLoading}>
      <div className="p-3 flex flex-col">
        <div style={{ maxHeight: "70vh", overflow: "auto" }}>
          <ErrorValidationBox errors={errors} />
          <DataTable
            columns={generateGridSettingsColumns(isArabic, handleFieldChange)}
            data={columns}
          />
        </div>
        <div className="d-flex justify-content-between mt-3">
          <ButtonBox
            size="sm"
            type="submit"
            variant="primary"
            className="btn btn-orange"
            onClick={handleSaveModifiedColumns}
          >
            {getLabelName("save")}
          </ButtonBox>
          <ButtonBox
            size="sm"
            type="button"
            // variant="outline-primary"
            variant="danger"
            className="btn btn-orange"
            onClick={onComplete}
          >
            {getLabelName("cancel")}
          </ButtonBox>
        </div>
      </div>
    </LoadingBox>
  );
  //#endregion
};
export default RenderGridColumnsSettings;
