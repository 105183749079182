import React, { FC, MouseEventHandler, useState } from "react";
import CardHeader from "react-bootstrap/CardHeader";
import { getLabelName } from "../../utils";
import { Card, Form, Row } from "react-bootstrap";
import {
  LookupItemModel,
  SearchRuleExpenseMoneyRequestModel,
  RuleExpRecipeAccountsEnum,
} from "../../models";
import { TextBox, ButtonBox } from "..";

export const RuleExpenseSearchPanel: FC<{
  acTransactionType: RuleExpRecipeAccountsEnum;
  header: string;
  searchParams: SearchRuleExpenseMoneyRequestModel;
  setSearchParams: Function;
  handelSearch: MouseEventHandler<HTMLButtonElement>;
  customerAccountLookups: LookupItemModel[];
  userLookUps: LookupItemModel[];
  allTreasuryInAccount: LookupItemModel[];
  allTreasuryOutAccount: LookupItemModel[];
  setShowAcTransactionModel: Function;
}> = (props) => {
  //#region variables
  const { handelSearch, setShowAcTransactionModel } = props;
  //#endregion
  //#region state
  const [searchValue, setSearchValue] = useState("");
  //#endregion
  //#region html
  return (
    <form className="d-flex flex-wrap gap-3 mb-4 align-items-center">
      <div className="col-12 col-md-4">
        <TextBox
          labelName={"Search"}
          inputName={"filterTextBox"}
          inputValue={searchValue}
          onChange={(e: any) => {
            setSearchValue(e.target.value);
          }}
          type={"text"}
          placeHolder={getLabelName("Search")}
        />
      </div>
      <div className="col-12 col-md-4">
        <ButtonBox
          className="btn btn-orange"
          iconType="magnify"
          variant="primary"
          onClick={handelSearch}
        >
          {getLabelName("Search")}
        </ButtonBox>
      </div>
    </form>
  );
  //#endregion
};
