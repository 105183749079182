import {
  ActionTypeEnum,
  RequestActionModel,
  TableActionColumnsStyleEnum,
} from "../../../../../models";
import { getLabelName } from "../../../../../utils";
import { ColumnActions } from "../utils/actions";
import { Dropdown } from "react-bootstrap";
import { ButtonBox, PrivilegesChecker, CustomDropdown } from "../../../..";
import { IconType } from "react-icons";
import { TableColumn } from "react-data-table-component";
import React from "react";

//#region 1. GetActionsColumns
export const getServerActionsColumns = (
  actionsPermitted: ActionTypeEnum[],
  formId: number,
  onActionEvent: (o: RequestActionModel) => void,
  style: TableActionColumnsStyleEnum = TableActionColumnsStyleEnum.DropDownText
) => {
  return {
    cell: (row: any) => {
      if (style === TableActionColumnsStyleEnum.Button) {
        return (
          <ServerButtonActions
            actionsPermitted={actionsPermitted}
            formId={formId}
            onActionEvent={onActionEvent}
            row={row}
          />
        );
      } else {
        const isIconRender: boolean =
          style === TableActionColumnsStyleEnum.Dropdown ||
          style === TableActionColumnsStyleEnum.DropdownIcon;
        const isTextRender: boolean =
          style === TableActionColumnsStyleEnum.Dropdown ||
          style === TableActionColumnsStyleEnum.DropDownText;
        return (
          <ServerDropdownActions
            actionsPermitted={actionsPermitted}
            formId={formId}
            onActionEvent={onActionEvent}
            row={row}
            isIconRender={isIconRender}
            isTextRender={isTextRender}
          />
        );
      }
    },
  };
};
export const getClientActionsColumns = (
  buttonColumns: TableColumn<any>[],
  style: TableActionColumnsStyleEnum = TableActionColumnsStyleEnum.Dropdown
): TableColumn<any> => {
  return {
    name: "",
    cell: (row: any, index: number, column: TableColumn<any>) => {
      if (style === TableActionColumnsStyleEnum.Button) {
        return buttonColumns.map((col) => {
          if (col.cell) {
            return col.cell(
              row,
              index,
              column,
              row.id || `client_button_id_${index || 0}`
            );
          }
          return null;
        });
      } else {
        return (
          <ClientDropdownActions buttonColumns={buttonColumns} row={row} />
        );
      }
    },
  };
};
//#endregion
//#region 2. ButtonActions
const ServerButtonActions = ({
  actionsPermitted,
  formId,
  onActionEvent,
  row,
}: {
  actionsPermitted: ActionTypeEnum[];
  formId: number;
  onActionEvent: (o: RequestActionModel) => void;
  row: any;
}) => (
  <div className="d-flex gap-3 align-items-center">
    {actionsPermitted.map((action, index) => (
      <PrivilegesChecker
        formId={formId}
        action={ColumnActions[action]?.privilege}
        key={`privilege_${action.toString()}_${index}`}
      >
        <ButtonBox
          className="btn btn-sm"
          variant={ColumnActions[action]?.variant}
          id={`button_id_b_${action.toString()}_${index}`}
          key={`button_${action.toString()}_${index}`}
          onClick={() => {
            if (action !== ActionTypeEnum.Delete) scrollToTop();
            onActionEvent({
              id: row.ID,
              request: row,
              action: action,
            });
          }}
        >
          {getLabelName(ColumnActions[action]?.btnText ?? "")}
        </ButtonBox>
      </PrivilegesChecker>
    ))}
  </div>
);
//#endregion
//#region 3. DropdownActions
const ServerDropdownActions = ({
  actionsPermitted,
  formId,
  onActionEvent,
  row,
  isIconRender,
  isTextRender,
}: {
  actionsPermitted: ActionTypeEnum[];
  formId: number;
  onActionEvent: (o: RequestActionModel) => void;
  row: any;
  isIconRender: boolean;
  isTextRender: boolean;
}) => {
  const dropdownItems = actionsPermitted
    ?.map((action, index) => {
      const IconComponent: IconType | null =
        (isIconRender && ColumnActions[action]?.icon) || null;

      return (
        <PrivilegesChecker
          key={index} // Ensure unique key for each item
          formId={formId}
          action={ColumnActions[action]!.privilege}
        >
          <Dropdown.Item
            style={{ width: `200px` }}
            variant={ColumnActions[action]!.variant}
            id={`button_id_d_${action.toString()}_${index}`}
            onClick={() => {
              if (action !== ActionTypeEnum.Delete) scrollToTop();

              onActionEvent({
                id: row.ID,
                request: row,
                action,
              });
            }}
          >
            {IconComponent && <IconComponent style={{ marginRight: 8 }} />}
            {isTextRender && (
              <span>{getLabelName(ColumnActions[action]!.btnText)}</span>
            )}
          </Dropdown.Item>
        </PrivilegesChecker>
      );
    })
    .filter(Boolean); // This will remove null values from the array
  return (
    <div className="d-flex gap-3 align-items-center">
      {dropdownItems.length > 0 && (
        <CustomDropdown
          style={{
            position: "absolute",
            left: "20px",
            top: "11px",
            // zIndex: 1,
          }}
          dropdownIcon={{ color: "black", size: 16 }}
        >
          {dropdownItems}
        </CustomDropdown>
      )}
    </div>
  );
};

const ClientDropdownActions = ({
  buttonColumns,
  row,
}: {
  buttonColumns: TableColumn<any>[];
  row: any;
}) => {
  const extractText = (component: React.ReactNode): string => {
    if (typeof component === "string") {
      return component;
    } else if (React.isValidElement(component)) {
      const { children, ...props } = component.props;

      if (typeof props.children === "string") {
        return props.children;
      }
      if (typeof children === "string") {
        return children;
      } else if (Array.isArray(children)) {
        const textChild = children.find((child) => typeof child === "string");
        if (textChild) {
          return textChild;
        }
        // Check if ButtonBox exists in children and extract its text
        const buttonBoxChild = children.find(
          (child) => React.isValidElement(child) && child.type === ButtonBox
        );
        if (
          buttonBoxChild &&
          typeof buttonBoxChild.props.children === "string"
        ) {
          return buttonBoxChild.props.children;
        }
      }
      const textProp = Object.values(props).find(
        (prop) => typeof prop === "string"
      );
      if (typeof textProp === "string") {
        return textProp ?? "Action";
      }
    }
    return "Action"; // Default button text
  };
  const dropdownItems = buttonColumns
    .map((column, index) => {
      if (typeof column.cell === "function") {
        const actionComponent = column.cell(
          row,
          index,
          column,
          row.id || `client_dropdown_id_${index || 0}`
        );
        let btnText = column.name ?? column.id;
        let variant = "primary"; // Default variant
        //@ts-ignore
        const isValidActionComponent = React.isValidElement(actionComponent);
        if (isValidActionComponent) {
          variant = actionComponent.props.variant
            ? actionComponent.props.variant
            : variant;
          btnText = actionComponent.props.children
            ? actionComponent.props.children
            : btnText;
        }
        return (
          <Dropdown.Item
            key={index} // Ensure unique key for each item
            variant={variant}
            id={`client_dropdown_id_${index}`}
            onClick={() => {
              if (
                isValidActionComponent &&
                typeof actionComponent.props.onClick === "function"
              ) {
                scrollToTop();
                actionComponent.props.onClick();
              }
            }}
          >
            {btnText}
          </Dropdown.Item>
        );
      }
      return null;
    })
    .filter(Boolean);

  return (
    <div className="d-flex gap-3 align-items-center">
      {dropdownItems.length > 0 && (
        <CustomDropdown
          style={{
            position: "absolute",
            left: "20px",
            top: "11px",
            // zIndex: 1,
          }}
          dropdownIcon={{ color: "black", size: 16 }}
        >
          {dropdownItems}
        </CustomDropdown>
      )}
    </div>
  );
};

const scrollToTop = () => {
  setTimeout(() => {
    window.document.querySelector("body")?.scrollTo(0, 0);
  }, 100);
};
//#endregion
