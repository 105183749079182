import { useEffect, useState } from "react";
import { TableColumn } from "react-data-table-component";
//import { generateGridColumns } from "../../utils/tables/gridColumns";
import {
  GridColumnsHookParamsModel, TableActionColumnsStyleEnum,
} from "../../models";
import { GridColumnsResponseModel } from "../../models";
import {generateGridColumns} from "../../components/common/dataTable/grid/utils/generateGridColumns";

interface useGridColumnsProps<T> extends GridColumnsHookParamsModel<T> {
  data: any[];
}
const useGridColumns = <T>({
  isArabic,
  onActionEvent,
  actions,
  actionsColumnGrow,
  data
}: useGridColumnsProps<T>,style:TableActionColumnsStyleEnum |null,   formId?: number|null) => {
  const [gridColumns, setGridColumns] = useState<TableColumn<T>[]>([]);
  const [columnsSettings, setColumnsSettings] = useState<
    GridColumnsResponseModel[]
  >([]);
  const [isGridColumnsLoading, setIsGridColumnsLoading] = useState(false);

  const fetchGridColumns = async () => {
    if (data.length === 0) return;
    setIsGridColumnsLoading(true);
    const { settingsColumns, gridCols } = await generateGridColumns<T>({
      actions,
      formId,
      isArabic,
      onActionEvent,
      actionsColumnGrow,
      data,style
    });
    setIsGridColumnsLoading(false);
    setColumnsSettings(settingsColumns);
    setGridColumns(gridCols);

  };

  useEffect(() => {
    fetchGridColumns().then();
  }, [data]);

  return {
    gridColumns,
    fetchGridColumns,
    isGridColumnsLoading,
    columnsSettings,
    onActionEvent
  };
};

export default useGridColumns;
