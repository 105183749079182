import React, {FC} from "react";
import {
    LookupItemModel,
    LookupTypeEnum,
    SearchPanelPropsModel,
    TransactionReportSearchParamsModel,
} from "../../../models";
import {Form} from "react-bootstrap";
import {getLabelName} from "../../../utils";
import {useLookups} from "../../../hooks";
import {ButtonBox, PrivilegesChecker, InputDatePicker, SelectBox} from "../..";

export const ReportProfitOfSaleSearchPanel: FC<
    SearchPanelPropsModel<TransactionReportSearchParamsModel>
> = ({
         setSearchParams,
         handelSearch,
         searchParams,
         setLoading, formId,
     }) => {
    //#region state
    const {getLookupItems} = useLookups(setLoading, [
        LookupTypeEnum.NotesTypes,
        LookupTypeEnum.Users,
        LookupTypeEnum.Employee,
        LookupTypeEnum.Cities,
    ]);
    //#endregion
    //#region html
    return (
        <>
            <Form>
                <div
                    className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                    <InputDatePicker
                        selectedDate={searchParams.fromDate}
                        isClearable
                        InputLabel={"From Date"}
                        className="form-control"
                        selectsStart
                        startDate={searchParams.fromDate}
                        endDate={searchParams.toDate}
                        onChange={(date) => {
                            const obj = {...searchParams};
                            obj.fromDate = date === null ? undefined : date;
                            setSearchParams(obj);
                        }}
                    />
                    <InputDatePicker
                        selectedDate={searchParams.toDate}
                        isClearable
                        InputLabel={"To Date"}
                        className="form-control"
                        selectsEnd
                        startDate={searchParams.fromDate}
                        endDate={searchParams.toDate}
                        minDate={searchParams.fromDate}
                        onChange={(date) => {
                            const obj = {...searchParams};
                            obj.toDate = date === null ? undefined : date;
                            setSearchParams(obj);
                        }}
                    />
                    <SelectBox
                        labelName="User"
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.userId ? [searchParams.userId.toString()] : [""]
                        }
                        source={getLookupItems(LookupTypeEnum.Users, true)}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.userId = undefined;
                            } else {
                                obj.userId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                    <SelectBox
                        labelName="Employee"
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.employeeId
                                ? [searchParams.employeeId.toString()]
                                : [""]
                        }
                        source={getLookupItems(LookupTypeEnum.Employee, true)}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.employeeId = undefined;
                            } else {
                                obj.employeeId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                    <SelectBox
                        labelName="City"
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.cityId ? [searchParams.cityId.toString()] : [""]
                        }
                        source={getLookupItems(LookupTypeEnum.Cities, true)}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.cityId = undefined;
                            } else {
                                obj.cityId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                    <SelectBox
                        labelName="Payment Type"
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.paymentTypeId
                                ? [searchParams.paymentTypeId.toString()]
                                : [""]
                        }
                        source={getLookupItems(LookupTypeEnum.NotesTypes, true)}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.paymentTypeId = undefined;
                            } else {
                                obj.paymentTypeId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                </div>
                <PrivilegesChecker formId={formId} action="EnableSearch">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                        <ButtonBox
                            iconType="magnify"
                            className="btn-gradient-primary mx-3 btn-fw"
                            variant="outline-primary"
                            onClick={handelSearch}
                        >
                            {getLabelName("Search")}
                        </ButtonBox>
                    </div>
                </PrivilegesChecker>
            </Form>
            {/* </Card.Body>
      </Card> */}
        </>
    );
    //#endregion
};
