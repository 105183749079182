import React, { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  EstehekakModel,
  ValidationErrorModel,
  ActionTypeEnum,
  RequestActionModel,
  LookupItemModel,
  LookupTypeEnum,
  HasFormIdModel,
} from "../../models";
import {
  ErrorValidationBox,
  TextBox,
  LoadingBox,
  InputDatePicker,
  ButtonBox,
  SelectBox,
  PrivilegesChecker,
} from "..";
import { saveEmployeeEstihkakSubtract } from "../../serviceBroker/employeesApiServiceBroker";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
import "react-datepicker/dist/react-datepicker.css";

interface AddEstektaa extends HasFormIdModel {
  request: EstehekakModel;
  onActionEvent: (o: RequestActionModel) => void;
}

export const AddEstektaa: FC<AddEstektaa> = ({
  request,
  onActionEvent = () => {},
  formId,
}) => {
  //#region variables
  const initialValues: EstehekakModel = request;
  initialValues.Date = new Date(initialValues.Date);
  //#endregion
  //#region state
  const isArabic = isArabicCurrentLanguage();
  const [loading, setLoading] = useState(false);
  const [empList, setEmpList] = useState<LookupItemModel[]>([]);
  const [_, setEmpId] = useState<number>(initialValues.Emp_ID);
  const [__, setRuleId] = useState<number>(initialValues.EsthkSubtRule_ID);
  const [date, setDate] = useState<Date>(initialValues.Date);
  const [ruleList, setRuleList] = useState<LookupItemModel[]>([]);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  //I need To change It Translation customerNameAr.missing
  const [validationSchema] = useState(
    Yup.object({
      Value: Yup.number()
        .positive(getLabelName("mustpositive"))
        .integer(getLabelName("mustintegar")),
    })
  );
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      const [estList, empList] = await Promise.all([
        getLookupByType(LookupTypeEnum.Estektaa),
        getLookupByType(LookupTypeEnum.Employee),
      ]);
      setRuleList(estList || []);
      setEmpList(empList || []);
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  //#endregion
  //#region func
  const handleSubmit = async (request: EstehekakModel) => {
    try {
      setLoading(true);
      initialValues.rowState =
        initialValues.rowState == 0 ? 2 : initialValues.rowState;
      //@ts-ignore
      const res: ResponseBase<EstehekakModel> =
        await saveEmployeeEstihkakSubtract(request);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        setValidationErrors(res.Errors);
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.Failed,
          request: isArabic
            ? res.Errors[0].MessageAr
            : res?.Errors[0].MessageEn,
          requestBeforeError: request,
        });
        setLoading(false);
      } else {
        setValidationErrors([]);
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.AddSuccess,
        });
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      setValidationErrors(errors);
    }
  };
  const validate = (values: EstehekakModel): any => {
    // @ts-ignore
    const errors: any = {};
    if (!values.Emp_ID) errors.Emp_ID = getLabelName("empid.missing");
    if (!values.EsthkSubtRule_ID)
      errors.EsthkSubtRule_ID = getLabelName("ruleId.missing");

    return errors;
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    validate,
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
  });
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      <form className="forms-sample" onSubmit={formik.handleSubmit}>
        <div className="row row-cols-2 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 g-sm-2 g-md-4 align-items-start">
          <SelectBox
            labelName={getLabelName("Employee")}
            errorText={formik.errors.Emp_ID}
            source={empList}
            isSingleSelect={true}
            onStatusChange={(e: any) => {
              formik.values.Emp_ID = e.value;
              request.Emp_ID = e.value;
              setEmpId(e.value);
            }}
            selectedValues={[request.Emp_ID.toString()]}
            multiselectRef={undefined}
          />
          <SelectBox
            labelName={getLabelName("Rule")}
            errorText={formik.errors.EsthkSubtRule_ID}
            source={ruleList}
            isSingleSelect={true}
            onStatusChange={(e: any) => {
              formik.values.EsthkSubtRule_ID = e.value;
              request.EsthkSubtRule_ID = e.value;
              setRuleId(e.value);
            }}
            selectedValues={[request.EsthkSubtRule_ID.toString()]}
            multiselectRef={undefined}
          />
          <TextBox
            labelName={getLabelName("Value")}
            inputName={"Value"}
            errorText={formik.errors.Value}
            inputValue={formik.values.Value}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="number"
          />
          <InputDatePicker
            selectedDate={date}
            className="form-control"
            InputLabel={getLabelName("Date")}
            onChange={(date: Date) => {
              setDate(date);
              formik.values.Date = date;
            }}
          />
          <TextBox
            labelName={getLabelName("Note")}
            inputName={"Notes"}
            errorText={formik.errors.Notes}
            inputValue={formik.values.Notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            type="textarea"
          />
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <PrivilegesChecker
              formId={formId}
              action={request.ID ? "EnableUpdate" : "EnableSave"}
            >
              <ButtonBox
                iconType="content-save"
                type="submit"
                className="btn-gradient-primary mx-3"
              >
                {getLabelName(request.ID ? "Update" : "Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              iconType="receipt"
              variant="danger"
              type="button"
              onClick={() => {
                onActionEvent({ id: 0, action: ActionTypeEnum.Clear });
              }}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
