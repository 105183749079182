import React, {FC, useEffect, useState} from "react";
import {
    ActionTypeEnum, LookupItemModel, LookupTypeEnum,
    PosTransactionsOptionsModifyTypeEnum,
    RequestActionModel,
    TransactionDetailResponseModel
} from "../../../models";
import {ButtonBox, LoadingBox, SelectBox, TextBox} from "../..";
import {getLabelName, getLookUpItemValue, getOriginalValueInCurrency, updateStateDynamically} from "../../../utils";
import {
    handleChangeTransactionDiscount,
    handleChangeTransactionDiscountRatio,
} from "../common/businessLogic/transactionBl";
import {getLookupByType} from "../../../serviceBroker/lookupApiServiceBroker";

export const PosTransactionsOptions: FC<{
    transactionData: TransactionDetailResponseModel,
    modifyType: PosTransactionsOptionsModifyTypeEnum,
    onActionEvent: (o: RequestActionModel) => void;
}> = ({
          transactionData, modifyType, onActionEvent = () => {
    }
      }) => {
    //#region state
    const [loading, setLoading] = useState(false);
    const [calcTypeList, setCalcTypeList] = useState<LookupItemModel[]>([]);
    const [data, setData] = useState<TransactionDetailResponseModel>(transactionData);
    //#endregion
    //#region variables
    const discountPercentageMaxValue: number = 50;
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            switch (modifyType) {
                case PosTransactionsOptionsModifyTypeEnum.CalculationType:
                    setLoading(true);
                    const types = await getLookupByType(LookupTypeEnum.CalcType, true, true);
                    setCalcTypeList(types);
                    setLoading(false);
                    break;
            }
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region html
    return (<>
        {loading && <LoadingBox/>}
        {modifyType === PosTransactionsOptionsModifyTypeEnum.Discount && <div
            className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
            <TextBox
                labelName={getLabelName("Discount")}
                //isReadOnly={!userTransactionSetting.EnableDiscount }
                type="number"
                inputName={"DiscountInCurrency"}
                inputValue={data.DiscountInCurrency}
                onChange={async (e: any) => {
                    const valueInCurrency = e.target.value != null ? Number(e.target.value) : 0;
                    const value = valueInCurrency === 0 ? 0 : getOriginalValueInCurrency(valueInCurrency, data.ValueCurrency);
                    await updateStateDynamically(
                        setData,
                        data, null, null, [{key: "DiscountInCurrency", value: valueInCurrency}, {
                            key: "Discount",
                            value: value
                        }]
                    );
                }}
                onBlur={async () => {
                    await handleChangeTransactionDiscount(setData, data);
                }}
            />
        </div>
        }
        {modifyType === PosTransactionsOptionsModifyTypeEnum.DiscountPercentage &&
            <div
                className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
                <TextBox
                    labelName={getLabelName("Discount %")}
                    //isReadOnly={!userTransactionSetting.EnableDiscount }
                    type="number"
                    inputName={"discountRatio"}
                    inputValue={data.discountRatio}
                    onChange={async (e: any) => {
                        const value = e.target.value != null ? Number(e.target.value) : 0;
                        if (value <= discountPercentageMaxValue) {
                            await updateStateDynamically(
                                setData,
                                data, null, null, [{key: "discountRatio", value: value}]
                            );
                        }
                    }}
                    onBlur={async () => {
                        await handleChangeTransactionDiscountRatio(setData, data);
                    }}
                />
            </div>
        }
        {modifyType === PosTransactionsOptionsModifyTypeEnum.Notes && <div
            className="row row-cols-1 row-cols-xxl-12 row-cols-xl-12 row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-md-12 g-sm-12">
            <TextBox
                labelName={getLabelName("Notes")}
                type="textarea"
                inputName={"Note"}
                inputValue={data.Note}
                onChange={async (e: any) => {
                    await updateStateDynamically(setData, data, "Note", e.target.value)
                }}
                //placeHolder="Type Here"
            />
        </div>
        }
        {modifyType === PosTransactionsOptionsModifyTypeEnum.CalculationType && <div
            className="row row-cols-1 row-cols-xxl-12 row-cols-xl-12 row-cols-lg-12 row-cols-md-12 row-cols-sm-12 g-md-12 g-sm-12">
            <SelectBox
                id="calcTypesSelectBox"
                labelName={getLabelName("Calc Type")}
                isSingleSelect={true}
                selectedValues={
                    data.CalcType_ID !== null && data.CalcType_ID !== undefined
                        ? [data.CalcType_ID.toString()]
                        : []
                }
                source={calcTypeList}
                onStatusChange={async (e: LookupItemModel) => {
                    const val = getLookUpItemValue(e);
                    if (val !== null && val !== undefined) {
                        await updateStateDynamically(
                            setData,
                            data,
                            "CalcType_ID",
                            val
                        );
                    }
                }}
            />
        </div>
        }
        <div className="row py-2">
            <div className="col-12 d-flex justify-content-end">
                <ButtonBox
                iconType="content-save"
                    type="button"
                    className="btn-gradient-primary mx-3 btn-fw"
                    onClick={() => {
                        onActionEvent({action: ActionTypeEnum.UpdatePosOptions, request: data});
                    }}
                >
                    {getLabelName("Save")}
                </ButtonBox>
                <ButtonBox
                iconType="close-circle"
                    variant="danger"
                    type="button"
                    className="btn-fw"
                    onClick={() => {
                        onActionEvent({action: ActionTypeEnum.Ignore});
                    }}
                >
                    {getLabelName("Cancel")}
                </ButtonBox>
            </div>
        </div>
    </>)
    //#endregion
}