import React, {FC, useEffect, useState} from "react";
import {Form} from "react-bootstrap";
import {getLabelName} from "../../../utils";
import {SelectBox, InputDatePicker, TextBox, ButtonBox, PrivilegesChecker} from "../..";
import {
    ItemReportSearchParamsModel,
    LookupItemModel,
    LookupTypeEnum, PageEnum,
    SearchPanelPropsModel,
} from "../../../models";
import {getItemsByCategoryId} from "../../../serviceBroker/itemApiServiceBroker";
import {useLookups} from "../../../hooks";
import {getLookupByType, getReportTypes} from "../../../serviceBroker/lookupApiServiceBroker";


export const ReportItemsSearchPanel: FC<
    SearchPanelPropsModel<ItemReportSearchParamsModel>
> = ({
         setLoading,
         withDateRange = false,
         handelSearch,
         searchParams,
         setSearchParams,
         reportType = false,
     }) => {
    //#region state
    const {getLookupItems} = useLookups(setLoading, [
        LookupTypeEnum.AllCategories,
    ]);
    const [itemsLookUps, setItemsLookUps] = useState<LookupItemModel[]>([]);
    const [stores, setStores] = useState<LookupItemModel[]>([]);
    const [reportTypes, setReportTypes] = useState<LookupItemModel[]>([]);
//#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            const results = await getItemsByCategoryId(searchParams.categoryId);
            setItemsLookUps(results);
            setLoading(false);
        };
        fillData().then(() => {
        });
    }, [searchParams.categoryId]);
    useEffect(() => {
        const fillData = async () => {
            let storeList = await getLookupByType(LookupTypeEnum.Stores);
            setStores(storeList);
            let reportType = await getReportTypes();
            let filteredReportType: LookupItemModel[] = [];
            reportType.forEach((rt) => {
                filteredReportType.push({
                    value: rt?.ID.toString(),
                    name: rt?.Name,
                    nameAr: getLabelName(rt?.Name),
                });
            });
            setReportTypes(filteredReportType);
        };

        fillData().then(() => {
        });
    }, []);
//#endregion
    //#region html
    return (
        <>
            <Form>
                <div
                    className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
                    {withDateRange && (
                        <>
                            <InputDatePicker
                                className="form-control"
                                selectedDate={searchParams.fromDate}
                                isClearable
                                InputLabel={"From Date"}
                                selectsStart
                                startDate={searchParams.fromDate}
                                endDate={searchParams.toDate}
                                onChange={(date) => {
                                    const obj = {...searchParams};
                                    obj.fromDate = date === null ? undefined : date;
                                    setSearchParams(obj);
                                }}
                            />
                            <InputDatePicker
                                className="form-control"
                                selectedDate={searchParams.toDate}
                                isClearable
                                InputLabel={"To Date"}
                                selectsEnd
                                startDate={searchParams.fromDate}
                                endDate={searchParams.toDate}
                                minDate={searchParams.fromDate}
                                onChange={(date) => {
                                    const obj = {...searchParams};
                                    obj.toDate = date === null ? undefined : date;
                                    setSearchParams(obj);
                                }}
                            />
                        </>
                    )}
                    {stores?.length > 1 && (
                        <SelectBox
                            labelName={getLabelName("Store")}
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.storeId ? [searchParams.storeId.toString()] : [""]
                            }
                            source={stores}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = {...searchParams};
                                if (e == null) {
                                    obj.storeId = undefined;
                                } else {
                                    obj.storeId =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                    )}
                    {reportType && (
                        <SelectBox
                            labelName={getLabelName("Report Type")}
                            isSingleSelect={true}
                            selectedValues={
                                searchParams.reportID
                                    ? [searchParams.reportID.toString()]
                                    : [""]
                            }
                            source={reportTypes}
                            onStatusChange={(e: LookupItemModel) => {
                                const obj = {...searchParams};
                                if (e == null) {
                                    obj.reportID = undefined;
                                } else {
                                    obj.reportID =
                                        e.value === "" || e.value === null
                                            ? undefined
                                            : parseInt(e.value);
                                }
                                setSearchParams(obj);
                            }}
                        />
                    )}
                    <SelectBox
                        labelName={getLabelName("Category")}
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.categoryId
                                ? [searchParams.categoryId.toString()]
                                : [""]
                        }
                        source={getLookupItems(LookupTypeEnum.AllCategories, true)}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.categoryId = undefined;
                            } else {
                                obj.categoryId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            obj.itemId = undefined;
                            setSearchParams(obj);
                        }}
                    />
                    <SelectBox
                        labelName={getLabelName("Items")}
                        isSingleSelect={true}
                        selectedValues={
                            searchParams.itemId ? [searchParams.itemId.toString()] : [""]
                        }
                        source={itemsLookUps}
                        onStatusChange={(e: LookupItemModel) => {
                            const obj = {...searchParams};
                            if (e == null) {
                                obj.itemId = undefined;
                            } else {
                                obj.itemId =
                                    e.value === "" || e.value === null
                                        ? undefined
                                        : parseInt(e.value);
                            }
                            setSearchParams(obj);
                        }}
                    />
                    <TextBox
                        inputName={"ItemCode"}
                        labelName="Item Code"
                        inputValue={searchParams.itemCode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const obj = {...searchParams};
                            if (!e.target.value) {
                                obj.itemCode = undefined;
                            } else {
                                obj.itemCode = e.target.value;
                            }
                            setSearchParams(obj);
                        }}
                    />

                    <TextBox
                        inputName={"Barcode"}
                        labelName="Barcode"
                        inputValue={searchParams.itemBarcode}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const obj = {...searchParams};
                            if (
                                e.target.value === "" ||
                                e.target.value === null ||
                                e.target.value === undefined
                            ) {
                                obj.itemBarcode = undefined;
                            } else {
                                obj.itemBarcode = e.target.value;
                            }
                            setSearchParams(obj);
                        }}
                    />
                    <TextBox
                        inputName={"balanceIsNotZero"}
                        labelName={getLabelName("Balnce Not Zero")}
                        type="checkbox"
                        inputValue={searchParams.balanceIsNotZero}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const obj = {...searchParams};
                            if (e.target.checked === null || e.target.checked === undefined) {
                                obj.balanceIsNotZero = undefined;
                            } else {
                                obj.balanceIsNotZero = e.target.checked;
                            }
                            setSearchParams(obj);
                        }}
                    />
                </div>

                <PrivilegesChecker formId={PageEnum.ReportFollowItemsBalance} action="EnableSearch">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
                        <ButtonBox
                        iconType="magnify"
                            className="btn-gradient-primary mx-3 btn-fw"
                            variant="outline-primary"
                            onClick={handelSearch}
                        >
                            {getLabelName("Search")}
                        </ButtonBox>
                    </div>
                </PrivilegesChecker>
            </Form>
        </>
    );
    //#endregion
};
