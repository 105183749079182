import React, { FC, useMemo } from "react";
import { ActionButton, GridTable } from "..";
import { format } from "date-fns";
import {
  ActionTypeEnum,
  PageEnum,
  RequestActionModel,
  ruleExpenseMoneySearchModel,
  SearchRuleExpenseMoneyRequestModel,
} from "../../models";

import { TableColumn } from "react-data-table-component";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import { Col } from "react-bootstrap";
import { selectAcTransaction } from "../../serviceBroker/accountApiServiceBroker";

export const RuleExpenseMoneyList: FC<{
  acTransactionSearch: SearchRuleExpenseMoneyRequestModel[];
  totalRows: number;
  searchParams: SearchRuleExpenseMoneyRequestModel;
  defaultPageSize: number;
  onCurrentPageChange: Function;
  onPageSizeChange: Function;
  setShowAcTransactionModel: Function;
  setAcTransaction: Function;
  setShowDeleteModel: Function;
  setShowPrintModel: Function;
  setLoading: Function;
  setIsFormLoading: React.Dispatch<React.SetStateAction<boolean>>;
  formID: PageEnum;
  onActionEvent: (request: RequestActionModel) => Promise<void>;
}> = (props) => {
  //#region variables
  const {
    acTransactionSearch,
    totalRows,
    searchParams,
    defaultPageSize,
    onCurrentPageChange,
    onPageSizeChange,
    setShowAcTransactionModel,
    setAcTransaction,
    setShowDeleteModel,
    onActionEvent,
    setIsFormLoading,
    formID,
  } = props;

  //#endregion
  //#region functions

  //#endregion
  //#region html
  return (
    <>
      <GridTable
        // columns={columns}
        data={acTransactionSearch}
        totalRows={totalRows}
        currentPage={searchParams.pageNumber || 1}
        pageSize={searchParams.pageSize || defaultPageSize}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationType="server"
        columnsProps={{
          actions: [ActionTypeEnum.Update, ActionTypeEnum.Delete],
          isArabic: isArabicCurrentLanguage(),
          onActionEvent,
        }}
        formId={formID}
      />
    </>
  );
  //#endregion
};
