import React from 'react';
import PropTypes from 'prop-types';
import { Button as BootstrapButton, ButtonProps as BootstrapButtonProps } from 'react-bootstrap';
import {generateGuid} from "../../../utils";

const ICON_TYPES = [
    'account-plus',
    'account-search',
    'brightness-5',
    'cash-multiple',
    'check-circle',
    'close-circle',
    'content-save',
    'credit-card',
    'dots-horizontal',
    'delete',
    'home-variant',
    'magnify',
    'pencil-box',
    'percent',
    'plus-circle',
    'plus',
    'printer',
    'receipt',
    'refresh',
    'replay',
    'send'
] as const;


type IconType = typeof ICON_TYPES[number];

interface CustomButtonProps extends BootstrapButtonProps {
    // Additional custom props can be added here
    className?: string;
    iconType?: IconType;
    extraIconClass?:string;
    ref?: React.Ref<HTMLButtonElement>;
    //iconClass: string;
}
export const ButtonBox: React.FC<CustomButtonProps> = React.forwardRef<HTMLButtonElement, CustomButtonProps>(({ children,  className = '',iconType,extraIconClass, id,  ...rest }, ref) => {
    const buttonId = id || `button_id_${generateGuid()}`;
    return (
        <BootstrapButton {...rest}
                         id={buttonId}
                         className={className}
                         ref={ref}
        >
            {iconType && <i className={`mdi mdi-${iconType} ${extraIconClass ? extraIconClass : ''}`} />}
            {children}
        </BootstrapButton>
    );
});

ButtonBox.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    iconType: PropTypes.oneOf(ICON_TYPES),
    extraIconClass: PropTypes.string,
    id: PropTypes.string,
    variant: PropTypes.string // Add PropTypes for variant
    // You can include PropTypes for other props if needed
};