import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  ActionTypeEnum,
  CloseDaySearchModel,
  LookupItemModel,
  LookupTypeEnum,
  PageEnum,
  PrintTypeEnum,
  RequestActionModel,
  SearchCloseDayRequestModel,
} from "../../models";
import { TableColumn } from "react-data-table-component";
import {
  formatDate,
  generateGuid,
  getLabelName,
  getLookUpItemValue,
  getPageNameByPageId,
  isArabicCurrentLanguage,
} from "../../utils";
import { Accordion } from "react-bootstrap";
import {
  ButtonBox,
  CloseDayDetails,
  GenericModelPopupPdfReportViewer,
  InputDatePicker,
  LoadingBox,
  SelectBox,
  GridTable,
  TextBox,
  PrivilegesChecker,
} from "..";
import { searchCloseDayData } from "../../serviceBroker/closeDayApiServiceBroker";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";

export const CloseDayList: FC<{ formId?: number | null }> = ({
  formId = PageEnum.POSStation,
}) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const defaultPageSize: number = 50;
  const initialValues: SearchCloseDayRequestModel = {
    pageNumber: 1,
    pageSize: defaultPageSize,
  };
  const getTransactionListColumns = (
    isArabic: boolean
  ): TableColumn<CloseDaySearchModel>[] => {
    return [
      {
        name: "#",
        selector: (_row, index) => Number(index || 0) + 1,
        sortable: true,
        width: "80px",
      },
      {
        name: getLabelName("Station"),
        selector: (row) => row.ID,
        sortable: true,
        width: "100px",
        wrap: true,
      },
      {
        name: getLabelName("User"),
        selector: (row) => (isArabic ? row.UserNameAr : row.UserNameEn),
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Begin Amount"),
        selector: (row) => row.Money_Beg,
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Open Date"),
        selector: (row) => formatDate(row.DateOpen),
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Date Close"),
        selector: (row) => formatDate(row.DateClose),
        sortable: true,
        allowOverflow: true,
      },

      // {
      //     name: getLabelName("Date"),
      //     selector: (row) => formatDate(row.ModificationDate),
      //     sortable: true,
      //     allowOverflow: true,
      // },

      {
        button:true,
        // eslint-disable-next-line react/button-has-type
        cell: (row: CloseDaySearchModel) => (
          <ButtonBox
            id="deleteItemPrintBtn"
            // variant="danger"
            className="btn-xs"
            onClick={() => {
              setShowPrintoutPdfModel(true);
              setPrintTransactionId(row.ID);
            }}
          >
            {getLabelName("Print")}
          </ButtonBox>
        ),
      },
      {
        button:true,
        // eslint-disable-next-line react/button-has-type
        cell: (row: CloseDaySearchModel) =>
          row.State ? null : (
            // <PrivilegesChecker FormId={75} action="EnableUpdate">
            <ButtonBox
              id="deleteItemPrintBtn"
              variant="danger"
              className="btn-xs"
              onClick={async () => {
                setStationId(row.ID);
                setUserId(row.User_ID);
                setLoading(true);
                setShowCloseDayModel(true);
                setLoading(false);
              }}
            >
              {getLabelName("Close")}
            </ButtonBox>
            // </PrivilegesChecker>
          ),
      },
    ];
  };
  //#endregion
  //#region state
  const [userLookUps, setUserLookUps] = useState<LookupItemModel[]>([]);
  const [printTransactionId, setPrintTransactionId] = useState(0);
  const [pageName, setPageName] = useState<string | null>(null);
  const [showPrintoutPdfModel, setShowPrintoutPdfModel] = useState(false);
  const [showCloseDayModel, setShowCloseDayModel] = useState(false);
  const [stationId, setStationId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [searchParams, setSearchParams] =
    useState<SearchCloseDayRequestModel>(initialValues);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<CloseDaySearchModel[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const usersSelectBoxMultiselectRef = useRef<any>();
  const columns: TableColumn<CloseDaySearchModel>[] = useMemo(
    () => [...getTransactionListColumns(isArabic)],
    []
  );
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      const users = await getLookupByType(LookupTypeEnum.Users, false, false);
      setUserLookUps(users);
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      await getTransactions(searchParams);
      setLoading(false);
    };
    fillData().then(() => {});
  }, [searchParams.pageNumber, searchParams.pageSize]);
  useEffect(() => {
    if (formId !== 0 && formId !== null && formId !== undefined) {
      setPageName(getPageNameByPageId(formId));
    }
  }, [isArabicCurrentLanguage()]);
  //#endregion
  //#region functions
  const handleAction = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.AddSuccess:
        setLoading(true);
        await getTransactions(searchParams);
        setShowCloseDayModel(false);
        setLoading(false);
        break;
      case ActionTypeEnum.CloseModelPopup:
        setShowCloseDayModel(false);
        break;
    }
  };
  const getTransactions = async (searchReq: SearchCloseDayRequestModel) => {
    const result = await searchCloseDayData(searchReq);
    setData(result?.Result || []);
    setTotalRows(result?.TotalRecoredCount || 0);
  };
  const onCurrentPageChange = async (pageNumber: number) => {
    if (pageNumber !== searchParams.pageNumber) {
      const obj = { ...searchParams };
      obj.pageNumber = pageNumber;
      setSearchParams(obj);
    }
  };
  const onPageSizeChange = async (pageSize: number) => {
    if (pageSize !== searchParams.pageSize) {
      const obj = { ...searchParams };
      obj.pageSize = pageSize;
      setSearchParams(obj);
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {showPrintoutPdfModel && (
        <GenericModelPopupPdfReportViewer
          keys={[{ key: "stationId", value: printTransactionId }]}
          type={PrintTypeEnum.CloseDay}
          onCloseEvent={() => {
            setShowPrintoutPdfModel(false);
          }}
        />
      )}
      {showCloseDayModel && (
        <CloseDayDetails
          userId={userId}
          stationId={stationId}
          onActionEvent={async (o: RequestActionModel) => {
            await handleAction(o);
          }}
        />
      )}
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>{pageName}</Accordion.Header>
          <Accordion.Body>
            <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
              <SelectBox
                labelName={getLabelName("User")}
                isSingleSelect={true}
                selectedValues={
                  searchParams.userId ? [searchParams.userId.toString()] : [""]
                }
                source={userLookUps}
                onStatusChange={(e: LookupItemModel) => {
                  const val = getLookUpItemValue(e);
                  setSearchParams({
                    ...searchParams,
                    userId: val === null ? null : Number(val),
                  });
                }}
                multiselectRef={usersSelectBoxMultiselectRef}
              />

              <InputDatePicker
                selectedDate={searchParams.fromDate}
                isClearable
                InputLabel={"From Date"}
                selectsEnd
                startDate={searchParams.fromDate}
                className="form-control"
                endDate={searchParams.toDate}
                minDate={searchParams.fromDate}
                onChange={(date) => {
                  setSearchParams({
                    ...searchParams,
                    fromDate: date === null ? undefined : date,
                  });
                }}
              />
              <InputDatePicker
                selectedDate={searchParams.toDate}
                isClearable
                InputLabel={"To Date"}
                selectsEnd
                startDate={searchParams.toDate}
                className="form-control"
                endDate={searchParams.toDate}
                minDate={searchParams.toDate}
                onChange={(date) => {
                  setSearchParams({
                    ...searchParams,
                    toDate: date === null ? undefined : date,
                  });
                }}
              />
              <TextBox
                labelName={getLabelName("Acctual Money")}
                type="checkbox"
                onChange={(e: any) => {
                  const isChecked = e.target.checked;

                  setSearchParams({
                    ...searchParams,
                    isStationStillOpen: isChecked,
                  });
                }}
                inputName={"isStationStillOpen"}
                inputValue={searchParams.isStationStillOpen}
              />
            </div>

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
              <PrivilegesChecker formId={PageEnum.POSStation} action="EnableSearch">
                <ButtonBox
                  className="btn-sm"
                  variant="primary"
                  onClick={async () => {
                    setLoading(true);
                    await getTransactions(searchParams);
                    setLoading(false);
                  }}
                >
                  {getLabelName("Search")}
                </ButtonBox>
              </PrivilegesChecker>
              <ButtonBox
                variant="danger"
                className="btn-sm"
                onClick={async () => {
                  setLoading(true);
                  usersSelectBoxMultiselectRef.current.clearValue();
                  setSearchParams({
                    ...initialValues,
                    searchGuid: generateGuid(),
                  });
                  await getTransactions(initialValues);
                  setLoading(false);
                }}
              >
                {getLabelName("Cancel")}
              </ButtonBox>
            </div>

            <GridTable
              columns={columns}
              data={data}
              totalRows={totalRows}
              currentPage={searchParams.pageNumber || 1}
              pageSize={searchParams.pageSize || defaultPageSize}
              onCurrentPageChange={onCurrentPageChange}
              onPageSizeChange={onPageSizeChange}
              paginationType="server"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
  //#endregion
};
