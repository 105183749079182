import { ColumnsType } from "antd/es/table";
import React, { Dispatch, MutableRefObject, SetStateAction } from "react";
import {
  ItemUnitModel,
  ItemModifierModel,
  LookupItemModel,
  SearchItemRequestModel,
  SearchItemsTypeEnum,
} from "../../../models";
import { generateGuid, getLabelName } from "../../../utils";
import {
  handleChangeItemUnitValues,
  handleChangeItemModifierValues,
  handleDeleteItemModifierRow,
  handleDeleteItemUnitRow,
} from "../businessLogic/itemBl";
import { TextBox, SelectBox, ButtonBox } from "../..";
import _ from "lodash";
//#region dataTable
export const handleGenerateAntdItemUnitColumns = (
  _isArabic: boolean,
  units: LookupItemModel[],
  setState: Dispatch<SetStateAction<ItemUnitModel[]>>,
  stateValue: ItemUnitModel[],
  tax: number
): ColumnsType<ItemUnitModel> => {
  return [
    {
      title: "#",
      key: "index_" + generateGuid(),
      render(value, record, index) {
        return index + 1;
      },
      width: "5%",
    },
    {
      title: getLabelName("unit"),
      key: "unit_", //+generateGuid(),
      render: (row: ItemUnitModel) => (
        <SelectBox
          selectedValues={
            row.Unit_ID !== null && row.Unit_ID !== undefined && row.Unit_ID > 0
              ? [row.Unit_ID.toString()]
              : null
          }
          isSingleSelect={true}
          labelName={""}
          source={units}
          onStatusChange={(e: LookupItemModel) => {
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "Unit_ID",
              e ? e.value : null
            );
          }}
        />
      ),
      width: "20%",
    },
    {
      title: getLabelName("PriceSale"),
      key: "PriceSale_", //+generateGuid(),
      render: (row: ItemUnitModel) => (
        <TextBox
          labelName={""}
          inputName={"PriceSale"}
          type="number"
          inputValue={row.PriceSale}
          onChange={(e: any) => {
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "PriceSale",
              +e.target.value
            );
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "PriceSaleWithTax",
              (+e.target.value + (tax / 100) * +e.target.value).toFixed(2)
            );
          }}
        />
      ),
      width: "10%",
    },
    {
      title: getLabelName("PriceSaleWithTax"),
      key: "PriceSaleWithTax", //+generateGuid(),
      render: (row: ItemUnitModel) => (
        <TextBox
          labelName={""}
          inputName={"PriceSaleWithTax"}
          type="number"
          inputValue={row.PriceSaleWithTax}
          onChange={(e: any) => {
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "PriceSaleWithTax",
              e.target.value
            );
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "PriceSale",
              (e.target.value / (1 + tax / 100)).toFixed(2)
            );
          }}
        />
      ),
      width: "10%",
    },
    {
      title: getLabelName("PriceQutyBegBal"),
      key: "PriceQutyBegBal_", //+generateGuid(),
      render: (row: ItemUnitModel) => (
        <TextBox
          labelName={""}
          inputName={"PriceQutyBegBal"}
          type="number"
          inputValue={row.PriceQutyBegBal}
          onChange={(e: any) => {
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "PriceQutyBegBal",
              e.target.value
            );
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "PriceQutyBegBalWithTax",
              (+e.target.value + (tax / 100) * +e.target.value).toFixed(2)
            );
          }}
        />
      ),
      width: "10%",
    },
    {
      title: getLabelName("PriceQutyBegBalWithTax"),
      key: "PriceQutyBegBalWithTax_", //+generateGuid(),
      render: (row: ItemUnitModel) => (
        <TextBox
          labelName={""}
          inputName={"PriceQutyBegBalWithTax"}
          type="number"
          inputValue={row.PriceQutyBegBalWithTax}
          onChange={(e: any) => {
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "PriceQutyBegBalWithTax",
              e.target.value
            );
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "PriceQutyBegBal",
              (e.target.value / (1 + tax / 100)).toFixed(2)
            );
          }}
        />
      ),
      width: "10%",
    },
    {
      title: getLabelName("QutyBegBal"),
      key: "QutyBegBal_", //+generateGuid(),
      render: (row: ItemUnitModel) => (
        <TextBox
          labelName={""} //{t("lookup.nameAr")}
          inputName={"QutyBegBal"}
          type="number"
          inputValue={row.QutyBegBal}
          onChange={(e: any) => {
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "QutyBegBal",
              e.target.value
            );
          }}
        />
      ),
      width: "10%",
    },
    {
      title: getLabelName("Factor"),
      key: "Factor_", //+generateGuid(),
      render: (row: ItemUnitModel) => (
        <TextBox
          labelName={""} //{t("lookup.nameAr")}
          inputName={"Factor"}
          type="number"
          inputValue={row.Factor}
          onChange={(e: any) => {
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "Factor",
              e.target.value
            );
          }}
        />
      ),
      width: "10%",
    },
    {
      title: getLabelName("Barcode"),
      key: "Barcode_", //+generateGuid(),
      render: (row: ItemUnitModel) => (
        <TextBox
          labelName={""} //{t("lookup.nameAr")}
          inputName={"Barcode"}
          type="number"
          inputValue={row.Barcode}
          onChange={(e: any) => {
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "Barcode",
              e.target.value
            );
          }}
        />
      ),
      width: "10%",
    },
    {
      title: getLabelName("IsSmallestUnit"),
      key: "IsSmallestUnit_", //+generateGuid(),
      render: (row: ItemUnitModel) => (
        <TextBox
          labelName={""} //{t("lookup.nameAr")}
          inputName={"IsSmallestUnit"}
          type="checkbox"
          inputValue={row.IsSmallestUnit}
          onChange={(e: any) => {
            handleChangeItemUnitValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "IsSmallestUnit",
              e.target.checked
            );
          }}
        />
      ),
      width: "10%",
    },
    {
      title: "",
      key: "action_", //+generateGuid(),
      render: (row: ItemUnitModel) => (
        <>
          <ButtonBox
            variant="outline-danger"
            onClick={() => {
              handleDeleteItemUnitRow(
                setState,
                stateValue,
                row.rowKey || `${row.ID}`,
                row.rowKey !== undefined
              );
            }}
          >
            {getLabelName("Delete")}
          </ButtonBox>
        </>
      ),
    },
  ];
};
export const handleGenerateAntdItemModifierColumns = (
  _isArabic: boolean,
  setState: Dispatch<SetStateAction<ItemModifierModel[]>>,
  stateValue: ItemModifierModel[]
): ColumnsType<ItemModifierModel> => {
  return [
    {
      title: "#",
      key: "index_", //+generateGuid(),
      render: (_row, index) => Number(index || 0) + 1,
      width: "5%",
    },

    {
      title: getLabelName("Arabic Name"),
      key: "NameArabic_", //+generateGuid(),
      render: (row: ItemModifierModel) => (
        <TextBox
          labelName={""} //{t("lookup.nameAr")}
          inputName={"NameArabic"}
          type="text"
          inputValue={row.NameArabic}
          onChange={(e: any) => {
            handleChangeItemModifierValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "NameArabic",
              e.target.value
            );
          }}
        />
      ),
      width: "20%",
    },
    {
      title: getLabelName("English Name"),
      key: "NameEnglish_", //+generateGuid(),
      render: (row: ItemModifierModel) => (
        <TextBox
          labelName={""} //{t("lookup.nameAr")}
          inputName={"NameEnglish"}
          type="text"
          inputValue={row.NameEnglish}
          onChange={(e: any) => {
            handleChangeItemModifierValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "NameEnglish",
              e.target.value
            );
          }}
        />
      ),
      width: "20%",
    },

    {
      title: getLabelName("Price"),
      key: "Price_", //+generateGuid(),
      render: (row: ItemModifierModel) => (
        <TextBox
          labelName={""}
          inputName={"Price"}
          type="number"
          inputValue={row.Price}
          onChange={(e: any) => {
            handleChangeItemModifierValues(
              setState,
              stateValue,
              row.rowKey || `${row.ID}`,
              "Price",
              e.target.value
            );
          }}
        />
      ),
      width: "10%",
    },
    {
      title: "",
      key: "action_", //+generateGuid(),
      render: (row: ItemModifierModel) => (
        <>
          <ButtonBox
            variant="outline-danger"
            onClick={() => {
              handleDeleteItemModifierRow(
                setState,
                stateValue,
                row.rowKey || `${row.ID}`,
                row.rowKey !== undefined
              );
            }}
          >
            {getLabelName("Delete")}
          </ButtonBox>
        </>
      ),
    },
  ];
};
//#endregion
//#region header Search
export const handleGenerateSubHeaderComponent = (
  categoryList: LookupItemModel[],
  stateValue: SearchItemRequestModel,
  setState: Dispatch<SetStateAction<SearchItemRequestModel>>,
  filterSelectBoxMultiselectRef: MutableRefObject<any>,
  filterGroupSelectBoxMultiselectRef: MutableRefObject<any>,
  t: any
): React.ReactNode => {
  const handleSearch = (
    searchType: SearchItemsTypeEnum,
    searchValue?: string | null,
    category?: number | null
  ) => {
    const obj = { ...stateValue };
    //@ts-ignore
    const searchValueLength: number =
      searchValue !== null ? searchValue?.length : 0;
    const searchValueMiniLength: number = 3;
    if (
      obj.searchType !== searchType &&
      searchValue !== null &&
      searchValueLength >= searchValueMiniLength
    ) {
      obj.searchGuid = _.uniqueId("searchType_");
      obj.pageNumber = 1;
    }
    if (
      obj.searchValue !== searchValue &&
      (searchValueLength >= searchValueMiniLength || obj.searchValue === null)
    ) {
      obj.searchGuid = _.uniqueId("searchValue_");
      obj.pageNumber = 1;
    }
    if (obj.categoryId !== category) {
      obj.searchGuid = _.uniqueId("searchCategory_");
      obj.pageNumber = 1;
    }
    obj.searchType = searchType;
    obj.categoryId = category;
    obj.searchValue = searchValue;
    setState(obj);
  };
  return (
    <>
      <div className="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 g-md-4 g-sm-4">
        <SelectBox
          labelName={t("Search Criteria")}
          isSingleSelect={true}
          selectedValues={[stateValue.searchType]}
          source={[
            {
              name: t("Search By Name"),
              nameAr: t("Search By Name"),
              value: SearchItemsTypeEnum.ItemName,
            },
            {
              name: t("Search By Code"),
              nameAr: t("Search By Code"),
              value: SearchItemsTypeEnum.ItemCode,
            },
            {
              name: t("Search By BarCode"),
              nameAr: t("Search By BarCode"),
              value: SearchItemsTypeEnum.ItemBarCode,
            },
          ]}
          onStatusChange={(e: LookupItemModel) => {
            if (e != null) {
              handleSearch(
                Object.values(SearchItemsTypeEnum).filter(
                  (p) => p === e.value
                )[0],
                stateValue.searchValue,
                stateValue.categoryId
              );
            }
          }}
          multiselectRef={filterSelectBoxMultiselectRef}
        />
        <TextBox
          labelName={"Filter By"}
          inputName={"filterTextBox"}
          inputValue={stateValue.searchValue}
          onChange={(e: any) => {
            handleSearch(
              stateValue.searchType,
              e.target.value,
              stateValue.categoryId
            );
          }}
        />
        <SelectBox
          labelName={t("Category")}
          isSingleSelect={true}
          selectedValues={
            stateValue.categoryId !== null &&
            stateValue.categoryId !== undefined
              ? //@ts-ignore
                [searchItemRequest.categoryId.toString()]
              : null
          } //categoryfilter.map(String)
          source={categoryList}
          onStatusChange={(e: LookupItemModel) => {
            if (e != null) {
              handleSearch(
                stateValue.searchType,
                stateValue.searchValue,
                Number(e.value)
              );
            }
          }}
          multiselectRef={filterGroupSelectBoxMultiselectRef}
        />
      </div>
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end my-3">
        <ButtonBox
          variant="danger"
          type="button"
          className="btn btn-sm"
          onClick={() => {
            filterSelectBoxMultiselectRef.current.clearValue();
            filterGroupSelectBoxMultiselectRef.current.clearValue();
            handleSearch(stateValue.searchType, "", null);
          }}
        >
          {t("reset")}
        </ButtonBox>
      </div>
    </>
  );
};
//#endregion
